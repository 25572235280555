import React from "react";
import "./style.css";
import Image1 from "./Image1.png";
import Image2 from "./Image2.png";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";

export const MoreInfo = () => {
    return (
        <div className="more-info">
            <div className="container">
                <img className="element" alt="Element" src={Image1} />

                <img className="macbook-pro" src={Image2} />
                <div className="text-content">
                    <div className="section-title">
                        <p className="heading">
                            Know Your Representatives & Compare Their Stances.
                        </p>
                        <p className="text">
                            VoteSee’s Representative comparison feature allows you to see, at a glance, where different Representatives stand on key issues based on their voting records. Compare Representatives to one another and to your own views with ease. With VoteSee, understanding politics is easy.
                        </p>
                    </div>
                    <Link to="/compare-reps" style={{ float: 'right', margin: '5px'  }} >
                        <Button className="button-instance" property1="primary" text = "Try Now" />
                    </Link>
                </div>
            </div>
        </div>
    );
};
