// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-component .title-wrap {
  padding: 20px 0;
  width: 100%;
  box-sizing: border-box;
}

.chart-component .title-wrap .title {
  color: #000;
  font-family: Georgia, serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 10px;
}

.chart-component .title-wrap .text {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

@media (max-width: 768px) {
  .chart-component .title-wrap .title {
    font-size: 24px;
  }

  .chart-component .title-wrap .text {
    font-size: 14px;
  }
}

.chart-component .chart-description {
  padding: 20px 0;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box;
}

.chart-component .chart-description .text {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

@media (max-width: 768px) {
  .chart-component .chart-description .text {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/commonChartStyles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".chart-component .title-wrap {\n  padding: 20px 0;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.chart-component .title-wrap .title {\n  color: #000;\n  font-family: Georgia, serif;\n  font-size: 28px;\n  font-weight: 700;\n  line-height: 1.2;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.chart-component .title-wrap .text {\n  color: #000;\n  font-family: Inter, sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 1.5;\n  text-align: left;\n}\n\n@media (max-width: 768px) {\n  .chart-component .title-wrap .title {\n    font-size: 24px;\n  }\n\n  .chart-component .title-wrap .text {\n    font-size: 14px;\n  }\n}\n\n.chart-component .chart-description {\n  padding: 20px 0;\n  width: 100%;\n  max-width: 1250px;\n  margin: 0 auto;\n  box-sizing: border-box;\n}\n\n.chart-component .chart-description .text {\n  color: #000;\n  font-family: Inter, sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 1.5;\n  text-align: left;\n}\n\n@media (max-width: 768px) {\n  .chart-component .chart-description .text {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
