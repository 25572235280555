import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './style.css';
import { IssueGallery } from '../IssueGallery/IssueGallery';
import { fetchWikipediaImage } from '../../utils/utils';
import { dbLogEvent, localLogEvent } from "../../utils/logUtil";
import userScoreImage from './flag.webp';
import axios from 'axios';
import RepThumb from '../RepThumb/RepThumb'; // Import the RepThumb component

const TopComparisonItem = React.memo(({ detail, count, onComparisonClick }) => {
  const [repDetails, setRepDetails] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchDetails = async () => {
      const repIds = detail.split(',');
      const details = await Promise.all(repIds.map(async (id) => {
        const response = await fetch(`${window.apiUrl}/politicians?politician_id=${id}`, {
          method: 'GET',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        const repDetails = await response.json();
        const repData = repDetails.length > 0 ? repDetails[0] : {};
        const image = await fetchWikipediaImage(repData.id_wikipedia, 'small');
        return { ...repData, image };
      }));
      if (isMounted.current) {
        setRepDetails(details);
      }
    };
    fetchDetails();

    return () => {
      isMounted.current = false;
    };
  }, [detail]);

  return (
    <div className="top-comparison-item" onClick={() => onComparisonClick(detail)}>
      <div className="rep-images">
        {repDetails.map((rep, index) => (
          <div key={index} className="rep-image-container">
            <RepThumb
              position={50}
              party={rep.party}
              imageUrl={rep.image || userScoreImage}
              displayName={rep.display_name}
              isGrayedOut={false}
              isOutOfOffice={false}
              year=""
              isUserScore={false}
              politicianId={rep.politician_id}
              state={rep.state}
              district={rep.district}
              style={{ position: 'static', transform: 'none', width: '48px', height: '48px' }}
            />
            <span className="rep-name">{rep.display_name}</span>
          </div>
        ))}
      </div>
    </div>
  );
});

export function CompareRepsSelection({ initialScores, showScores: initialShowScores, setShowScores, onBack }) {

  const history = useHistory();
  const location = useLocation();
  const [politicians, setPoliticians] = useState([]);
  const [selectedReps, setSelectedReps] = useState([{ value: '', label: 'Rep 1' }, { value: '', label: 'Rep 2' }]);
  const [error, setError] = useState(null);
  const [includeMyViews, setIncludeMyViews] = useState(false);
  const [userEmail] = useState(localStorage.getItem('userEmail'));
  const [queries, setQueries] = useState(['', '']);
  const [suggestions, setSuggestions] = useState([[], []]);
  const [allScores, setAllScores] = useState(initialScores || []);
  const [showLocalScores, setLocalShowScores] = useState(initialShowScores);
  const [topComparisons, setTopComparisons] = useState([]);
  const [isLoadingTopComparisons, setIsLoadingTopComparisons] = useState(true);
  const [debouncedQueries, setDebouncedQueries] = useState(['', '']);
  const debounceTimerRef = useRef(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isAddingRep, setIsAddingRep] = useState(false);
  const [newRepQuery, setNewRepQuery] = useState('');
  const [newRepSuggestions, setNewRepSuggestions] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);

  // Log rendering for debugging
  useEffect(() => {

  });

  // Define fetchTopComparisons before using it in useEffect
  const fetchTopComparisons = useCallback(() => {

    setIsLoadingTopComparisons(true);
    fetch(`${window.apiUrl}/top-comparisons`, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY_1,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(`Network response was not ok: ${response.status} ${response.statusText}. Body: ${text}`);
          });
        }
        return response.json();
      })
      .then(data => {
        setTopComparisons(data);
        setIsLoadingTopComparisons(false);
      })
      .catch(error => {
        setError(`Failed to fetch top comparisons. ${error.message}`);
        setIsLoadingTopComparisons(false);
      });
  }, []);

  // Define fetchScoresForReps before using it in useEffect
  const fetchScoresForReps = useCallback(async (reps, includeMyViews) => {
    try {
      const newRepScores = await Promise.all(
        reps.map(async (repId) => {
          const response = await fetch(`${window.apiUrl}/politicianscore?politician_id=${repId}`, {
            method: 'GET',
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });
          const scores = await response.json();

          const repDetailsResponse = await fetch(`${window.apiUrl}/politicians?politician_id=${repId}`, {
            method: 'GET',
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });
          const repDetails = await repDetailsResponse.json();
          const repDetailsData = repDetails.length > 0 ? repDetails[0] : {};
          const party = repDetailsData.party || 'Unknown';

          const image = await fetchWikipediaImage(repDetailsData.id_wikipedia, 'small');

          // Find the most recent year with data
          const mostRecentYear = Math.max(...scores.map(score => score.year));

          return {
            rep: repDetailsData.id_wikipedia,
            display_name: repDetailsData.display_name,
            politician_id: repId,
            scores: scores.map(score => ({
              ...score,
              description: score.description,
              isGrayedOut: false // We'll handle this in the rendering part
            })),
            image,
            party,
            p1_def: scores[0]?.p1_def,
            p2_def: scores[0]?.p2_def,
            p1: scores[0]?.p1,
            p2: scores[0]?.p2,
            mostRecentYear
          };
        })
      );

      setAllScores(prevScores => {
        const updatedScores = [...prevScores];
        newRepScores.forEach(newScore => {
          const existingIndex = updatedScores.findIndex(score => score.politician_id === newScore.politician_id);
          if (existingIndex !== -1) {
            updatedScores[existingIndex] = newScore;
          } else {
            updatedScores.push(newScore);
          }
        });
        return updatedScores;
      });

      if (includeMyViews) {
        const email = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).email : null;
        if (email) {
          const userResponse = await fetch(`${window.apiUrl}/userscores?email=${email}`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });
          const userScores = await userResponse.json();

          // Sort the scores by rank to maintain consistent order
          const sortedScores = userScores.sort((a, b) => a.rank - b.rank);

          const userScoreData = {
            rep: 'You',
            display_name: 'You',
            politician_id: 'user',
            scores: sortedScores.map(score => ({
              topic_name: score.group_name,
              average_score: score.score,  // Use score directly from the API
              position: score.score,       // Add position for consistency
              description: score.description,
              p1: score.p1,
              p2: score.p2,
              p1_def: score.p1_def,
              p2_def: score.p2_def,
              isUserScore: true,
              year: new Date().getFullYear(),
              bill_count: 1  // Add a default bill count
            })),
            image: userScoreImage,
            party: 'Your Party',
            isUserScore: true,
            mostRecentYear: new Date().getFullYear()
          };

          setAllScores(prevScores => [...prevScores, userScoreData]);
        }
      }

      setLocalShowScores(true);
      setShowScores(true);

      // Log the reps being shown


    } catch (error) {
      setError('Failed to fetch Representative scores. Please try again later.');
    }
  }, [setShowScores]);

  // Fetch Top Comparisons Once on Mount
  useEffect(() => {
    fetchTopComparisons();
  }, [fetchTopComparisons]);

  // Fetch Scores Based on URL Parameters
  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const reps = params.getAll('rep');
    const includeViews = params.get('includeMyViews') === 'true';

    if (reps.length > 0) {
      fetchScoresForReps(reps, includeViews);
    } else {
      setLocalShowScores(false);
      setShowScores(false);
    }
  }, [location.search, fetchScoresForReps, setShowScores]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();


    if (selectedReps.length < 2 && !includeMyViews) {
      alert('Please select at least two representatives.');
      return;
    }

    try {
      const repScores = await Promise.all(
        selectedReps.map(async (rep) => {
          const response = await fetch(`${window.apiUrl}/politicianscore?politician_id=${rep.value}`, {
            method: 'GET',
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });
          const scores = await response.json();

          const repDetailsResponse = await fetch(`${window.apiUrl}/politicians?politician_id=${rep.value}`, {
            method: 'GET',
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });
          const repDetails = await repDetailsResponse.json();
          const repDetailsData = repDetails.length > 0 ? repDetails[0] : {};
          const party = repDetailsData.party || 'Unknown';

          const image = await fetchWikipediaImage(repDetailsData.id_wikipedia, 'small');

          return {
            rep: repDetailsData.id_wikipedia,
            display_name: rep.label,
            politician_id: rep.value,
            scores: scores.map(score => ({
              ...score,
              description: score.description
            })),
            image,
            party,
            p1_def: scores[0]?.p1_def,
            p2_def: scores[0]?.p2_def,
            p1: scores[0]?.p1,
            p2: scores[0]?.p2
          };
        })
      );

      if (includeMyViews && userEmail) {

        const userResponse = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
          method: 'GET',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        const userScores = await userResponse.json();

        const userScoreData = {
          rep: 'You',
          display_name: 'You',
          politician_id: 'user',
          scores: userScores.map(score => ({
            topic_name: score.group_name,
            average_score: score.score,
            p1: score.p1,
            p2: score.p2
          })),
          image: userScoreImage,
          party: 'Your Party'
        };
        repScores.push(userScoreData);
      }
      // Log the compare event
      const politicianIds = selectedReps.map(rep => rep.value).sort().join(',');
      await dbLogEvent('compare', politicianIds);
      localLogEvent('compare', politicianIds);

      setAllScores(repScores);
      setLocalShowScores(true);
      setShowScores(true);

      const params = new URLSearchParams();
      selectedReps.forEach(rep => params.append('rep', rep.value));
      if (includeMyViews) params.append('includeMyViews', 'true');

      history.push(`/compare-reps?${params.toString()}`, { fromSelection: true });
    } catch (error) {
      setError('Failed to fetch Representative scores. Please try again later.');
    }
  }, [selectedReps, includeMyViews, userEmail, history, setShowScores]);

  const handleAddRep = () => {
    setSelectedReps([...selectedReps, { value: '', label: `Rep ${selectedReps.length + 1}` }]);
    setQueries([...queries, '']);
    setSuggestions([...suggestions, []]);
  };

  const handleRemoveRep = (index) => {
    const updatedReps = selectedReps.filter((_, i) => i !== index);
    setSelectedReps(updatedReps);
    const updatedQueries = queries.filter((_, i) => i !== index);
    setQueries(updatedQueries);
    const updatedSuggestions = suggestions.filter((_, i) => i !== index);
    setSuggestions(updatedSuggestions);
  };

  const formatSuggestion = (suggestion) => {
    let role = '';
    if (suggestion.type === 'sen') {
      role = 'Senator';
    } else if (suggestion.type === 'rep') {
      role = 'Representative';
    }

    const currentDate = new Date();
    const endDate = new Date(suggestion.end);
    const status = endDate < currentDate ? '(Former)' : '(Current)';

    const stateInfo = suggestion.state ? `, ${suggestion.state}` : '';
    const roleInfo = role ? `${role}${stateInfo}` : '';

    return `${suggestion.display_name}${roleInfo ? ' | ' + roleInfo : ''} ${status}`;
  };

  const handleInputChange = useCallback((e, index) => {
    const value = e.target.value;
    setQueries(prev => {
      const newQueries = [...prev];
      newQueries[index] = value;
      return newQueries;
    });

    setActiveDropdown(index);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      setDebouncedQueries(prev => {
        const newDebouncedQueries = [...prev];
        newDebouncedQueries[index] = value;
        return newDebouncedQueries;
      });
    }, 300);
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {

      const newSuggestions = [...suggestions];
      for (let i = 0; i < debouncedQueries.length; i++) {
        if (debouncedQueries[i].length > 2) {
          try {
            const response = await fetch(`${window.apiUrl}/politiciansearch?search=${debouncedQueries[i]}`, {
              method: 'GET',
              headers: {
                'x-api-key': process.env.REACT_APP_API_KEY_1,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
              throw new Error('An error occurred while fetching data. Please try again later.');
            }
            const data = await response.json();
            newSuggestions[i] = data;
          } catch (error) {
            setError(error.message);
          }
        } else {
          newSuggestions[i] = [];
        }
      }
      setSuggestions(newSuggestions);
    };

    fetchSuggestions();
  }, [debouncedQueries]);

  const handleSuggestionClick = useCallback((suggestion, index) => {
    const newSelectedReps = [...selectedReps];
    newSelectedReps[index] = { value: suggestion.politician_id, label: formatSuggestion(suggestion) };
    setSelectedReps(newSelectedReps);

    const newQueries = [...queries];
    newQueries[index] = formatSuggestion(suggestion);
    setQueries(newQueries);

    const newSuggestions = [...suggestions];
    newSuggestions[index] = [];
    setSuggestions(newSuggestions);

    setActiveDropdown(null);

    // Add politician hit
    fetch(`${window.apiUrl}/politicianhit`, {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY_1,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ politician_id: suggestion.politician_id })
    }).catch(error => {
      // Remove console.error and handle error silently or add appropriate error handling
    });
  }, [selectedReps, queries, suggestions]);

  const handleInputFocus = useCallback((index) => {
    setActiveDropdown(index);
  }, []);

  const handleInputBlur = useCallback(() => {
    // Use a setTimeout to delay closing the dropdown
    // This allows time for a click on a suggestion to register
    setTimeout(() => {
      setActiveDropdown(null);
    }, 200);
  }, []);

  const handlePositionChange = useCallback((topic, index, newScore) => {
    // Remove console.log and implement actual functionality if needed
  }, []);

  const handleRefreshGallery = useCallback(() => {
    // Remove console.log and implement actual functionality if needed
  }, []);

  const toggleIssue = async (issue) => {
    const email = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).email : null;
    if (!email) return;

    const updatedSelectedIssues = selectedIssues.includes(issue)
      ? selectedIssues.filter(item => item !== issue)
      : [...selectedIssues, issue];

    setSelectedIssues(updatedSelectedIssues);

    try {
      const response = await fetch(`${window.apiUrl}/userscores`, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          group_name: issue,
          favorite: updatedSelectedIssues.includes(issue) ? 1 : 0,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update favorite status');
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  const memoizedIssueGallery = useMemo(() => {
    return <IssueGallery 
      issuesData={allScores} 
      isCompareView={true}
      onPositionChange={handlePositionChange}
      onRefreshGallery={handleRefreshGallery}
      isUserScore={false}
      showFilter={true}
      selectedIssues={selectedIssues}
      toggleIssue={toggleIssue}
      allIssues={allIssues}
    />;
  }, [allScores, handlePositionChange, handleRefreshGallery, selectedIssues, toggleIssue, allIssues]);

  const handleBack = useCallback(() => {
    history.push('/compare-reps');
  }, [history]);

  const handleTopComparisonClick = useCallback(async (comparisonDetail) => {
    const repIds = comparisonDetail.split(',');
    await fetchScoresForReps(repIds, false);
    const params = new URLSearchParams();
    repIds.forEach(id => params.append('rep', id));
    history.push(`/compare-reps?${params.toString()}`, { fromSelection: true });
  }, [fetchScoresForReps, history]);

  const memoizedTopComparisons = useMemo(() => (
    topComparisons.map((comparison, index) => (
      <TopComparisonItem
        key={comparison.detail}
        detail={comparison.detail}
        count={comparison.count}
        onComparisonClick={handleTopComparisonClick}
      />
    ))
  ), [topComparisons, handleTopComparisonClick]);

  const isRepGrayedOut = (rep, selectedYearPair) => {
    if (!selectedYearPair) return false;
    return rep.mostRecentYear < selectedYearPair[1];
  };

  const handleYearPairChange = (event, newValue) => {
    if (!isUserScore) {
      const newYearPair = yearPairs[newValue];
      setSelectedYearPair(newYearPair);
      if (getCurrentIssues(newYearPair).length > 0) {
        setLastAvailableYearPair(newYearPair);
      }

      // Log the current year requested


      // Log whether each rep should be shown
      allScores.forEach(rep => {
        const shouldBeShown = rep.scores.some(score => score.year >= newYearPair[1] && score.year <= newYearPair[0]);

      });
    }
  };

  const handleAddNewRep = () => {
    setIsAddingRep(true);
    // Ensure the search input is focused after it appears
    setTimeout(() => {
      const searchInput = document.querySelector('.add-rep-search input');
      if (searchInput) {
        searchInput.focus();
      }
    }, 0);
  };

  const handleNewRepInputChange = async (e) => {
    const value = e.target.value;
    setNewRepQuery(value);

    if (value.length > 2) {
      try {
        const response = await fetch(`${window.apiUrl}/politiciansearch?search=${value}`, {
          method: 'GET',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('An error occurred while fetching data.');
        }
        const data = await response.json();
        setNewRepSuggestions(data);
      } catch (error) {
        setError(error.message);
      }
    } else {
      setNewRepSuggestions([]);
    }
  };

  const handleNewRepSelect = async (selectedRep) => {
    try {
      await fetchScoresForReps([selectedRep.politician_id], false);
      
      // Update URL to include the new rep
      const params = new URLSearchParams(location.search);
      params.append('rep', selectedRep.politician_id);
      history.push(`${location.pathname}?${params.toString()}`);

      setIsAddingRep(false);
      setNewRepQuery('');
      setNewRepSuggestions([]);
    } catch (error) {
      setError("Failed to add new representative. Please try again.");
    }
  };

  useEffect(() => {
    const fetchAllIssues = async () => {
      try {
        // Change this to the correct endpoint for fetching all issues
        const response = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Assuming the API returns an array of issues
        setAllIssues(data);
      } catch (error) {
      }
    };

    if (userEmail) {
      fetchAllIssues();
    }
  }, [userEmail]);

  useEffect(() => {
    const fetchUserIssues = async () => {
      const email = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).email : null;
      if (!email) return;

      try {
        const response = await fetch(`${window.apiUrl}/userscores?email=${email}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        
        // Sort the issues by rank
        const sortedIssues = data.sort((a, b) => a.rank - b.rank);
        setAllIssues(sortedIssues);

        // Filter favorite issues
        const favoriteIssues = sortedIssues
          .filter(issue => issue.favorite && issue.favorite.data && issue.favorite.data[0] === 1)
          .map(issue => issue.group_name);
        setSelectedIssues(favoriteIssues);
      } catch (error) {
        // Handle error
      }
    };

    fetchUserIssues();
  }, []); // Run once on mount

  useEffect(() => {
    const fetchUserIssues = async () => {
      if (!userEmail) return;

      try {
        const response = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        const favoriteIssues = data
          .filter(issue => issue.favorite && issue.favorite.data && issue.favorite.data[0] === 1)
          .map(issue => issue.group_name);

        setSelectedIssues(favoriteIssues);
      } catch (error) {
      }
    };

    fetchUserIssues();
  }, [userEmail]);

  return (
    <div className="compare-reps-container">
      {error && <div className="error">{error}</div>}
      {!showLocalScores ? (
        <>
          <div className="compare-reps-content">
            <p className="instructions">
              See how Congressional Representatives have voted on the issues that matter most to you. This tool offers a comprehensive view of where your Representatives stand, helping you become a more informed voter.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="dropdown-container">
                {selectedReps.map((rep, index) => (
                  <div key={index} className="dropdown">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="search-input"
                        placeholder={`Rep ${index + 1} | Search by name, city, state, etc.`}
                        value={queries[index]}
                        onChange={(e) => handleInputChange(e, index)}
                        onFocus={() => handleInputFocus(index)}
                        onBlur={handleInputBlur}
                      />
                      {activeDropdown === index && queries[index].length > 2 && suggestions[index].length > 0 && (
                        <ul className="suggestions-list">
                          {suggestions[index].map((suggestion, sugIndex) => (
                            <li
                              key={sugIndex}
                              onMouseDown={() => handleSuggestionClick(suggestion, index)}
                            >
                              {formatSuggestion(suggestion)}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    {index >= 2 && (
                      <button
                        type="button"
                        className="remove-rep-button"
                        onClick={() => handleRemoveRep(index)}
                      >
                        ×
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <button type="button" className="add-rep-button" onClick={handleAddRep}>
                Add another rep
              </button>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="include-my-views"
                  name="include-my-views"
                  checked={includeMyViews}
                  onChange={(e) => setIncludeMyViews(e.target.checked)}
                />
                <label htmlFor="include-my-views">Include My Views</label>
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
          <div className="top-comparisons">
            <h3>Top Comparisons</h3>
            {isLoadingTopComparisons ? (
              <p>Loading top comparisons...</p>
            ) : topComparisons.length > 0 ? (
              memoizedTopComparisons
            ) : (
              <p>No top comparisons available at the moment.</p>
            )}
          </div>
        </>
      ) : (
        <div className="scores-container">
          <div className="legend-container">
            {allScores.map((rep, index) => {
              const isGrayedOut = isRepGrayedOut(rep, /* selectedYearPair */);
              return (
                <div key={`${rep.politician_id}-${index}`} className="legend-item">
                  <img 
                    src={rep.image || userScoreImage} 
                    alt={rep.display_name} 
                    className={`legend-icon ${rep.party.toLowerCase()} ${isGrayedOut ? 'grayed-out' : ''}`} 
                  />
                  <p>{rep.display_name}</p>
                </div>
              );
            })}
            <div className="legend-item add-rep-item">
              <div className="add-rep-circle" onClick={handleAddNewRep}>
                <span>+</span>
              </div>
              <p>Add Representative</p>
              {isAddingRep && (
                <div className="new-rep-search-container">
                  <input
                    type="text"
                    className="new-rep-search-input"
                    value={newRepQuery}
                    onChange={handleNewRepInputChange}
                    placeholder="Search for a representative"
                  />
                  {newRepSuggestions.length > 0 && (
                    <ul className="new-rep-suggestions-list">
                      {newRepSuggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleNewRepSelect(suggestion)}>
                          {suggestion.display_name} ({suggestion.state})
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
          <hr className="separator" />
          {memoizedIssueGallery}
          <button onClick={handleBack} className="back-button">
            Back
          </button>
        </div>
      )}
    </div>
  );
}

// Add this comment to acknowledge the UNSAFE_componentWillMount warning
// TODO: Investigate and update any third-party libraries causing UNSAFE_componentWillMount warnings

export default React.memo(CompareRepsSelection);
