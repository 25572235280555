import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import './style.css'; // Ensure styles are imported

const endLineLabelPlugin = {
  id: 'endLineLabels',
  afterDatasetsDraw(chart) {
    const { ctx, scales: { x, y } } = chart;
    const rightEdge = chart.chartArea.right;
    const activeIndex = chart._hoverState?.activeIndex;
    const isHovering = activeIndex !== undefined;
    const labelPositions = chart.data.datasets.map((dataset, i) => {
      const lastValue = dataset.data[dataset.data.length - 1];
      if (lastValue !== undefined) {
        return {
          index: i,
          yPos: y.getPixelForValue(lastValue),
          value: lastValue,
          absValue: Math.abs(lastValue),
          dataset
        };
      }
      return null;
    }).filter(Boolean).sort((a, b) => b.absValue - a.absValue);

    const minSpacing = 30;
    let visibleLabels = [];
    labelPositions.forEach((label) => {
      const isActive = label.index === activeIndex;
      if (isHovering && isActive) {
        visibleLabels.push(label);
        return;
      }
      const hasOverlap = visibleLabels.some(visibleLabel => 
        Math.abs(visibleLabel.yPos - label.yPos) < minSpacing
      );
      if (!hasOverlap) {
        visibleLabels.push(label);
      }
    });

    visibleLabels.forEach(label => {
      const isActive = isHovering ? label.index === activeIndex : true;
      ctx.save();
      ctx.globalAlpha = isActive ? 1 : (isHovering ? 0.05 : 1);
      ctx.strokeStyle = label.dataset.originalColor;
      ctx.fillStyle = label.dataset.originalColor;
      ctx.lineWidth = isActive ? 2 : 1;
      ctx.beginPath();
      ctx.moveTo(rightEdge, label.yPos);
      ctx.lineTo(rightEdge + 12, label.yPos);
      ctx.stroke();
      const labelText = `${label.dataset.label}`;
      const value = typeof label.value === 'object' ? label.value.y : label.value;
      const isBottomChart = chart.canvas.closest('.relative-chart-wrapper');
      const valueText = isBottomChart 
        ? `(${value > 0 ? '+' : ''}${Number(value).toFixed(0)}%)` // Add '+' for bottom chart
        : `(${Number(value).toFixed(0)}%)`; // No '+' for top chart
      const fontSize = window.innerWidth < 768 ? 11 : 14;
      ctx.font = `${isActive ? '800' : '500'} ${fontSize}px Inter`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'left';
      const lineHeight = fontSize * 1.4;
      
      // Adjust wrapping logic based on screen size
      const maxWidth = window.innerWidth < 768 ? 60 : 400; // Larger max width for non-mobile
      const words = labelText.split(' ');
      let line = '';
      let yOffset = label.yPos - lineHeight / 2;
      words.forEach((word, index) => {
        const testLine = line + word + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && index > 0) {
          ctx.fillText(line, rightEdge + 16, yOffset);
          line = word + ' ';
          yOffset += lineHeight;
        } else {
          line = testLine;
        }
      });
      ctx.fillText(line, rightEdge + 16, yOffset);
      ctx.fillText(valueText, rightEdge + 16, yOffset + lineHeight);
      ctx.restore();
    });
  }
};

const getColorFromGradient = (value, min, max) => {
  const normalized = Math.max(0, Math.min(1, (value - min) / (max - min)));
  const r = Math.round(237 - (normalized * (237 - 0)));
  const g = Math.round(41 + (normalized * (114 - 41)));
  const b = Math.round(57 + (normalized * (237 - 57)));
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

const gradientLegendPlugin = {
  id: 'congressBillSplitGradientLegend',
  afterDatasetsDraw(chart) {
    if (!chart.canvas.closest('.congress-bill-split')) return;
    const { ctx } = chart;
    
    // Position in top left with updated dimensions
    const leftEdge = chart.chartArea.left + 5;
    const topEdge = chart.chartArea.top + 5;
    const gradientWidth = 5;
    const gradientHeight = 80;  // Doubled from 40
    
    // Create gradient
    const gradient = ctx.createLinearGradient(0, topEdge, 0, topEdge + gradientHeight);
    gradient.addColorStop(0, colors.maxColor);
    gradient.addColorStop(1, colors.minColor);
    
    // Draw gradient bar
    ctx.beginPath();
    ctx.roundRect(leftEdge, topEdge, gradientWidth, gradientHeight, 1);
    ctx.fillStyle = gradient;
    ctx.fill();
    
    ctx.strokeStyle = 'rgba(0,0,0,0.05)';
    ctx.stroke();
    
    // Position labels next to gradient with updated text
    ctx.fillStyle = '#666';
    ctx.font = 'bold 10px Inter';
    ctx.textAlign = 'left';
    ctx.fillText('High Bill Count', leftEdge + gradientWidth + 4, topEdge + 8);
    ctx.fillText('Low Bill Count', leftEdge + gradientWidth + 4, topEdge + gradientHeight - 4);
  }
};

const hoverPlugin = {
  id: 'hover',
  beforeDraw(chart) {
    const activeElements = chart.getActiveElements();
    const activeIndex = activeElements.length > 0 ? activeElements[0].datasetIndex : undefined;
    chart._hoverState = { activeIndex };
    let needsUpdate = false;
    chart.data.datasets.forEach((dataset, i) => {
      if (!dataset._originalColor) {
        dataset._originalColor = dataset.originalColor || dataset.borderColor;
      }
      const currentColor = dataset.borderColor;
      let newColor;
      if (activeIndex !== undefined) {
        const opacity = i === activeIndex ? 1 : 0.1;
        if (typeof dataset._originalColor === 'string') {
          if (dataset._originalColor.startsWith('rgba')) {
            newColor = dataset._originalColor.replace(/[\d.]+\)$/, `${opacity})`);
          } else {
            newColor = dataset._originalColor.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
          }
        } else {
          newColor = `rgba(0, 0, 0, ${opacity})`;
        }
      } else {
        newColor = dataset._originalColor;
      }
      if (currentColor !== newColor) {
        dataset.borderColor = newColor;
        dataset.backgroundColor = newColor;
        needsUpdate = true;
      }
    });
    if (needsUpdate) {
      chart.update('none');
    }
  }
};

const registerChartComponents = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    endLineLabelPlugin,
    hoverPlugin,
    gradientLegendPlugin
  );
};

const colors = {
  maxColor: 'rgb(0, 114, 237)',
  minColor: 'rgb(237, 41, 57)',
  gray: 'hsla(0, 0%, 75%, 1)'
};

const congressYears = {
  111: '2009-2011',
  112: '2011-2013',
  113: '2013-2015',
  114: '2015-2017',
  115: '2017-2019',
  116: '2019-2021',
  117: '2021-2023'
};

export const CongressBillSplit = () => {
  const isMobile = window.innerWidth < 768;
  const [data, setData] = useState({ relative: null, proportion: null });
  const [loading, setLoading] = useState(true);
  const [baselineCongress, setBaselineCongress] = useState(111);
  const [volumeThreshold, setVolumeThreshold] = useState(isMobile ? 7 : 25); // Default to 7 on mobile
  const [availableCongresses, setAvailableCongresses] = useState([]);
  const [billStatus, setBillStatus] = useState('Passed');
  const [rawData, setRawData] = useState(null);
  const proportionChartRef = useRef(null);
  const relativeChartRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [baselineCongress, volumeThreshold, billStatus]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/congressbillsplit`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      setRawData(response.data);
      const congresses = [...new Set(response.data.map(item => parseInt(item.congress)))]
        .filter(congress => congress >= 111 && congress < 118)
        .sort();
      setAvailableCongresses(congresses);
      processData(response.data);
    } catch (error) {
      console.error('Error fetching congress bill split data:', error);
      setLoading(false);
    }
  };

  const processData = (rawData) => {
    const filteredData = billStatus === 'All' 
      ? rawData 
      : rawData.filter(item => item.bill_status === billStatus);

    const congressNumbers = [...new Set(filteredData.map(item => parseInt(item.congress)))]
      .filter(congress => congress >= 111 && congress < 118)
      .sort();

    const groupStats = filteredData.reduce((acc, item) => {
      if (!acc[item.group_name]) {
        acc[item.group_name] = {
          data: {},
          absoluteChange: 0
        };
      }
      const congress = parseInt(item.congress);
      const percentage = parseFloat(item.percentage_of_congress || 0);
      const billCount = parseInt(item.bill_count || 0);
      
      acc[item.group_name].data[congress] = {
        percentage: percentage,
        billCount: billCount
      };
      return acc;
    }, {});

    const validGroups = Object.entries(groupStats).filter(([_, stats]) => {
      const baselineBillCount = stats.data[baselineCongress]?.billCount || 0;
      return baselineBillCount >= 5;
    });

    validGroups.forEach(([_, stats]) => {
      const baselinePercentage = stats.data[baselineCongress]?.percentage || 0;
      const currentPercentage = stats.data[Math.max(...congressNumbers)]?.percentage || 0;
      stats.absoluteChange = Math.abs(currentPercentage - baselinePercentage);
    });

    const numGroups = Math.round(volumeThreshold);
    const sortedGroups = validGroups
      .sort((a, b) => b[1].absoluteChange - a[1].absoluteChange)
      .slice(0, numGroups);

    const processedData = sortedGroups.map(([name, stats]) => {
      const changes = {};
      const baselinePercentage = stats.data[baselineCongress]?.percentage || 0;
      const totalBillCount = Object.values(stats.data).reduce((sum, d) => sum + (d.billCount || 0), 0);
      const avgBillCount = totalBillCount / Object.keys(stats.data).length;
      
      congressNumbers.forEach(congress => {
        const currentPercentage = stats.data[congress]?.percentage || 0;
        changes[congress] = currentPercentage;
      });

      return {
        name,
        changes,
        avgBillCount,
        avgChange: Math.abs(Object.values(changes).reduce((a, b) => a + b, 0) / congressNumbers.length)
      };
    });

    const minBillCount = Math.min(...processedData.map(d => d.avgBillCount));
    const maxBillCount = Math.max(...processedData.map(d => d.avgBillCount));

    const relativeData = {
      labels: congressNumbers.map(num => num),
      datasets: processedData
        .sort((a, b) => {
          const latestA = a.changes[Math.max(...congressNumbers)] || 0;
          const latestB = b.changes[Math.max(...congressNumbers)] || 0;
          return latestB - latestA;
        })
        .map(({ name, changes, avgBillCount }) => {
          const color = getColorFromGradient(avgBillCount, minBillCount, maxBillCount);
          return {
            label: name,
            data: congressNumbers.map(congress => {
              const currentPercentage = changes[congress];
              const baselinePercentage = changes[baselineCongress];
              return baselinePercentage === 0 ? 0 :
                ((currentPercentage - baselinePercentage) / baselinePercentage) * 100;
            }),
            borderColor: color,
            backgroundColor: color,
            originalColor: color,
            fill: false,
            tension: 0.3,
            borderWidth: window.innerWidth < 768 ? 2 : 3,
            pointRadius: 0
          };
        })
    };

    const proportionData = {
      labels: congressNumbers.map(num => num),
      datasets: processedData
        .sort((a, b) => {
          const latestA = a.changes[Math.max(...congressNumbers)] || 0;
          const latestB = b.changes[Math.max(...congressNumbers)] || 0;
          return latestB - latestA;
        })
        .map(({ name, changes, avgBillCount }) => {
          const color = getColorFromGradient(avgBillCount, minBillCount, maxBillCount);
          return {
            label: name,
            data: congressNumbers.map(congress => changes[congress]),
            borderColor: color,
            backgroundColor: color,
            originalColor: color,
            fill: false,
            tension: 0.3,
            borderWidth: window.innerWidth < 768 ? 2 : 3,
            pointRadius: 0
          };
        })
    };

    setData({ relative: relativeData, proportion: proportionData });
    setLoading(false);
  };

  useEffect(() => {
    let timeoutId;
    const resizeObserver = new ResizeObserver((entries) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        entries.forEach(entry => {
          const container = entry.target;
          const canvas = container.querySelector('canvas');
          if (canvas) {
            const isMobile = window.innerWidth < 768;
            const isSmallMobile = window.innerWidth < 480;
            const baseHeight = isSmallMobile ? 600 : isMobile ? 600 : 1000;
            container.style.height = `${baseHeight}px`;
            requestAnimationFrame(() => {
              canvas.style.height = `${baseHeight}px`;
            });
          }
        });
      }, 100);
    });

    const containers = document.querySelectorAll('.chart-container');
    containers.forEach(container => resizeObserver.observe(container));

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    registerChartComponents();
    return () => {
      ChartJS.unregister(gradientLegendPlugin);
    };
  }, []);

  useEffect(() => {
    if (proportionChartRef.current) {
      console.log('Proportion Chart Dimensions:', {
        width: proportionChartRef.current.offsetWidth,
        height: proportionChartRef.current.offsetHeight,
      });
    }
    if (relativeChartRef.current) {
      console.log('Relative Chart Dimensions:', {
        width: relativeChartRef.current.offsetWidth,
        height: relativeChartRef.current.offsetHeight,
      });
    }
  }, [loading, data]);

  const getOptions = (isRelative) => ({
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay: 0,
    devicePixelRatio: window.devicePixelRatio * 2,
    aspectRatio: 2, // Add this to control aspect ratio
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { 
            family: 'Inter', 
            size: window.innerWidth < 768 ? 10 : 14,
            weight: '500' 
          },
          maxRotation: window.innerWidth < 768 ? 45 : 0,
          minRotation: window.innerWidth < 768 ? 45 : 0
        },
        title: {
          display: true,
          text: 'Congress',
          font: {
            family: 'Inter',
            size: window.innerWidth < 768 ? 12 : 16,
            weight: '600'
          },
          padding: { top: 20 }
        }
      },
      y: {
        type: 'linear',
        grid: {
          color: 'rgba(0, 0, 0, 0.04)',
          drawZero: true,
          zeroLineColor: 'rgba(0, 0, 0, 0.1)',
          zeroLineWidth: 1
        },
        ticks: {
          font: { 
            family: 'Inter', 
            size: window.innerWidth < 768 ? 10 : 14,
            weight: '500' 
          },
          callback: function(value) {
            return `${value}%`;
          },
          maxTicksLimit: window.innerWidth < 768 ? 5 : 8
        },
        // Remove grace and update min/max handling
        min: isRelative ? function(context) {
          const minValue = Math.min(...context.chart.data.datasets.flatMap(d => d.data));
          return Math.floor(minValue * 1.05); // Add 5% padding below
        } : 0,
        max: function(context) {
          const maxValue = Math.max(...context.chart.data.datasets.flatMap(d => d.data));
          return Math.ceil(maxValue * 1.02); // Add just 2% padding above
        },
      }
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#333',
        bodyColor: '#555',
        borderColor: 'rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        caretSize: 8,
        caretPadding: 10,
        callbacks: {
          label: (context) => {
            const value = context.parsed.y;
            const congress = parseInt(context.chart.data.labels[context.dataIndex]);
            const groupStats = rawData?.find(item => 
              item.group_name === context.dataset.label && 
              parseInt(item.congress) === congress &&
              (billStatus === 'All' || item.bill_status === billStatus)
            );
            const billCount = groupStats?.bill_count || 0;
            const totalBills = groupStats?.total_bills || 0;
            
            return isRelative
              ? `${context.dataset.label}: ${value.toFixed(1)}% change from ${baselineCongress}th Congress (${billCount} / ${totalBills} bills)`
              : `${context.dataset.label}: ${value.toFixed(1)}% of total bills (${billCount} / ${totalBills} bills)`;
          }
        }
      },
      legend: { display: false },
      endLineLabels: { enabled: true }
    },
    layout: {
      padding: {
        right: window.innerWidth < 768 ? 90 : 250, // Increased right padding for non-mobile
        left: window.innerWidth < 768 ? 5 : 10,
        top: window.innerWidth < 768 ? 20 : 30,
        bottom: window.innerWidth < 768 ? 5 : 20
      }
    },
    elements: {
      line: {
        borderWidth: window.innerWidth < 768 ? 2 : 3,
        tension: 0.3,
        capBezierPoints: true
      },
      point: {
        hitRadius: 30,
        hoverRadius: 12
      }
    },
    hover: {
      mode: 'nearest',
      intersect: false,
      axis: 'xy'
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'xy',
      includeInvisible: true
    },
    animation: {
      duration: 300
    },
    transitions: {
      active: {
        animation: {
          duration: 400
        }
      }
    },
    animations: {
      colors: {
        type: 'color',
        duration: 750,
        easing: 'easeInOutQuart',
      },
      numbers: {
        type: 'number',
        duration: 750,
        easing: 'easeInOutQuart',
      }
    },
    onResize: (chart, size) => {
      chart.resize();
    }
  });

  const styles = `
    .congress-bill-split {
      position: relative;
    }
    .congress-bill-split .chart-container {
      position: relative;
    }
    .congress-bill-split [class^="dataset-"] {
      transition: opacity 0.2s ease;
    }
    .congress-bill-split.has-active [class^="dataset-"] {
      opacity: 0.1;
    }
    .congress-bill-split.has-active [class^="dataset-"].active {
      opacity: 1;
    }
  `;

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => styleSheet.remove();
  }, []);

  return (
    <div className="congress-bill-split">
      <div className="chart-description">
        <div className="text">
          Explore how the focus of congress has shifted over time by looking at the change in distribution/volume of bills passed by topic.
          <br /><br />
          <em>Hover over the charts to explore the data in detail. Increase the topic count to see more data, default shows topics with greatest proportion change over time.</em>
        </div>
      </div>
      <div className="chart-controls">
        <div className="controls-row">
          <div className="bill-status-selector">
            <label htmlFor="bill-status">Bill Status:</label>
            <select
              id="bill-status"
              value={billStatus}
              onChange={(e) => setBillStatus(e.target.value)}
              className="bill-status-select"
            >
              <option value="All">All Bills</option>
              <option value="Passed">Passed Bills</option>
            </select>
          </div>
          <div className="volume-selector">
            <label htmlFor="volume-threshold">Topic Count: {Math.round(volumeThreshold)}</label>
            <input
              type="range"
              id="volume-threshold"
              min="1"
              max="50"
              step="1"
              value={volumeThreshold}
              onChange={(e) => setVolumeThreshold(parseFloat(e.target.value))}
              className="volume-slider"
            />
          </div>
        </div>
      </div>
      <div className="charts-container">
        <div className="chart-wrapper proportion-chart-wrapper" ref={proportionChartRef}>
          <h3>Relative Proportion - Percent of Total Bills</h3>
          <div className="chart-container">
            {loading ? (
              <div className="loading">Loading...</div>
            ) : data.proportion ? (
              <Line 
                data={data.proportion} 
                options={getOptions(false)} // Pass false for the top chart
                height={600}
              />
            ) : (
              <div className="no-data">No data available</div>
            )}
          </div>
        </div>
        <div className="chart-wrapper relative-chart-wrapper" ref={relativeChartRef}>
          <h3>Relative Change - Percent Change from Reference Congress</h3>
          <div className="chart-container">
            {loading ? (
              <div className="loading">Loading...</div>
            ) : data.relative ? (
              <Line 
                data={data.relative} 
                options={getOptions(true)} // Pass true for the bottom chart
                height={600}
              />
            ) : (
              <div className="no-data">No data available</div>
            )}
          </div>
        </div>
        <div className="baseline-selector">
          <label htmlFor="baseline-congress">Reference Congress: {baselineCongress}th</label>
          <input
            type="range"
            id="baseline-congress"
            min={Math.min(...availableCongresses)}
            max={Math.max(...availableCongresses)}
            step="1"
            value={baselineCongress}
            onChange={(e) => setBaselineCongress(parseInt(e.target.value))}
            className="baseline-slider"
          />
        </div>
      </div>
    </div>
  );
};
