import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CompareRepsSelection } from '../../components/CompareRepsSelection/CompareRepsSelection';
import { Banner } from '../../components/Banner/Banner';
import { RepGallery } from '../../components/RepGallery/RepGallery';
import './style.css';

export const CompareReps = () => {
    const [showScores, setShowScores] = useState(false);
    const [selectedReps, setSelectedReps] = useState([]);
    const history = useHistory();
    const location = useLocation();

    const checkShowScores = useCallback(() => {
        const hasParams = new URLSearchParams(location.search).toString() !== '';
        setShowScores(hasParams);
    }, [location.search]);

    useEffect(() => {
        checkShowScores();

        const handlePopState = () => {
            checkShowScores();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [checkShowScores]);

    const handleSetShowScores = useCallback((value) => {
        setShowScores(value);
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const repIds = params.getAll('rep');
        if (repIds.length > 0) {
            setSelectedReps(repIds);
        } else {
            setSelectedReps([]);
        }
    }, [location.search]);

    return (
        <div className="compare-reps-page">
            <Helmet>
                <title>Compare Congressional Representatives | VoteSee</title>
                <meta name="description" content="Compare how Congressional Representatives have voted on key issues. Make informed decisions about your political representatives with VoteSee." />
                <meta name="keywords" content="compare representatives, congressional voting records, political comparison, VoteSee" />
            </Helmet>
            <Banner
                title="Compare Representatives"
                description="See how Congressional Representatives have voted on the issues that matter most to you."
            />
            <CompareRepsSelection
                showScores={showScores}
                setShowScores={handleSetShowScores}
                onBack={() => {
                    history.push('/compare-reps');
                }}
            />
            {showScores && selectedReps.length > 0 && (
                <RepGallery 
                    title="Selected Representatives" 
                    description="These are the representatives you're comparing."
                    dataType="custom"
                    item={selectedReps.join(',')}
                    showHeader={true}
                />
            )}
        </div>
    );
};

export default CompareReps;
