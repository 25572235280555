import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCn6b-dNZ76Te_lt40Lxyxdoe594XbcTEI",
  authDomain: "votesee-760d9.firebaseapp.com",
  projectId: "votesee-760d9",
  storageBucket: "votesee-760d9.appspot.com",
  messagingSenderId: "236687281284",
  appId: "1:236687281284:web:e8726a1aa2b6e84b5886d1",
  measurementId: "G-5JGN84ZCXV"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
