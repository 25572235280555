import React, { memo } from 'react';
import "./style.css"; // Ensure this is importing the relevant CSS for styling

export const MyIssues = memo(({ issues, selectedIssues, toggleIssue }) => {


  return (
    <div className="my-issues">
      <div className="issues-container">
        {issues.map(issue => (
          <div
            key={issue.group_name}
            className={`issue-bubble ${selectedIssues.includes(issue.group_name) ? 'selected' : ''}`}
            onClick={() => toggleIssue(issue.group_name)}
          >
            {issue.group_name}
          </div>
        ))}
      </div>
    </div>
  );
});
