import React, { useState, useEffect } from "react";
import { Button } from "../Button/Button"; // Adjust the path as necessary
import { MobileHeader } from "../MobileHeader/MobileHeader"; // Import the MobileHeader component
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./style.css";
import { signOut, updatePassword } from "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const MenuOverlay = ({ isOpen, toggleMenu }) => {
    useEffect(() => {
        if (isOpen) {
            document.querySelector('.menu-overlay').scrollTop = 0;
        }
    }, [isOpen]);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsLoggedIn(!!user);
        });

        return () => unsubscribe();
    }, [auth]);


    const handleItemClick = () => {
        toggleMenu();
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            
            toggleMenu(); // Close the menu
            // Clear any local storage or session storage
            localStorage.clear();
            sessionStorage.clear();
            // Reset any global variables (if any)
            // For example: window.globalVar = null;
            // Force a full page reload to reset all React state
            window.location.reload(true);
        }).catch((error) => {
            console.error("Error signing out:", error);
        });
    };

    return (
        <div className={`menu-overlay ${isOpen ? 'open' : ''}`}>
            <MobileHeader toggleMenu={toggleMenu} /> {/* Include MobileHeader */}
            <div className="overlay-content">
                <div className="menu-items">
                    <Link to="/about" className="text-wrapper-2" onClick={toggleMenu}>About</Link>
                    <Link to="/find-a-rep" className="text-wrapper-4" onClick={toggleMenu}>Find a Rep</Link>
                    <Link to="/pick-your-views" className="text-wrapper-5" onClick={toggleMenu}>Pick Your Views</Link>
                    <Link to="/compare-reps" className="text-wrapper-6" onClick={toggleMenu}>Compare Reps</Link>
                    <Link to="/quiz" className="text-wrapper-6" onClick={toggleMenu}>Quiz</Link>
                    <Link to="/analytics" className="text-wrapper-6" onClick={toggleMenu}>Analytics</Link>

                    {!isLoggedIn ? (
    <div className="button-container">
        <Link to="/login" onClick={handleItemClick}>
            <Button className="button-login" property1="primary" text="Log in" />
        </Link>
        <Link to="/signup" onClick={handleItemClick}>
            <Button className="button-signup" property1="secondary" text="Sign up" />
        </Link>
    </div>
) : (
    <div className="button-container">
        <Link to="/profile" onClick={handleItemClick}>
            <Button className="button-profile" property1="primary" text="My profile" />
        </Link>
        <Link to="/" onClick={handleLogout}>
            <Button className="button-out" property1="secondary" text="Sign out" />
        </Link>
    </div>
)}


                    
                
                </div>

            </div>
        </div>
    );
};
