import React from 'react';
import './style.css';
import { SignIn } from '../../components/Signin/Signin';

export const Login = () => {
  return (
    <div className="signin-container">
      <SignIn />
    </div>
  );
};
