// Convert SVG strings to base64-encoded data URLs
const svgToDataUrl = (svgString) => {
  // First, encode any special characters in the SVG
  const encodedSvg = encodeURIComponent(svgString)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  return `data:image/svg+xml;charset=utf-8,${encodedSvg}`;
};

const findRepSvg = `
<svg width="800" height="600" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
  <rect width="800" height="600" fill="#FFFFFF"/>
  <rect y="0" width="800" height="200" fill="#458BEA" fill-opacity="0.1"/>
  <text x="50" y="80" font-size="32" font-family="Georgia" fill="#000000">Find Your Representative</text>
  <text x="50" y="120" font-size="16" fill="#666666">Search for your local representatives and see how they vote</text>
  <rect x="50" y="150" width="700" height="60" rx="8" fill="#FFFFFF" stroke="#458BEA"/>
  <text x="80" y="185" font-size="16" fill="#666666">Search by name or state...</text>
  <g transform="translate(50, 250)">
    <rect width="220" height="280" rx="8" fill="#FFFFFF" stroke="#E0E0E0"/>
    <circle cx="110" cy="80" r="50" fill="#458BEA" fill-opacity="0.1"/>
    <text x="70" y="180" font-size="16" font-weight="bold" fill="#000000">John Smith</text>
    <text x="70" y="200" font-size="14" fill="#666666">Senator, CA</text>
  </g>
  <g transform="translate(290, 250)">
    <rect width="220" height="280" rx="8" fill="#FFFFFF" stroke="#E0E0E0"/>
    <circle cx="110" cy="80" r="50" fill="#458BEA" fill-opacity="0.1"/>
    <text x="70" y="180" font-size="16" font-weight="bold" fill="#000000">Jane Doe</text>
    <text x="70" y="200" font-size="14" fill="#666666">Representative, NY</text>
  </g>
</svg>
`;

const compareSvg = `
<svg width="800" height="600" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
  <rect width="800" height="600" fill="#FFFFFF"/>
  <rect y="0" width="800" height="120" fill="#458BEA" fill-opacity="0.1"/>
  <text x="50" y="70" font-size="32" font-family="Georgia" fill="#000000">Compare Representatives</text>
  <g transform="translate(50, 150)">
    <rect width="300" height="400" rx="8" fill="#FFFFFF" stroke="#E0E0E0"/>
    <circle cx="150" cy="80" r="50" fill="#458BEA" fill-opacity="0.1"/>
    <text x="100" y="160" font-size="18" font-weight="bold" fill="#000000">John Smith</text>
    <text x="100" y="180" font-size="14" fill="#666666">Senator, CA</text>
    <rect x="50" y="220" width="200" height="20" rx="4" fill="#458BEA" fill-opacity="0.2"/>
    <rect x="50" y="250" width="150" height="20" rx="4" fill="#458BEA" fill-opacity="0.2"/>
    <rect x="50" y="280" width="180" height="20" rx="4" fill="#458BEA" fill-opacity="0.2"/>
  </g>
  <circle cx="400" cy="350" r="30" fill="#458BEA" fill-opacity="0.1"/>
  <text x="385" y="358" font-size="20" font-weight="bold" fill="#458BEA">VS</text>
  <g transform="translate(450, 150)">
    <rect width="300" height="400" rx="8" fill="#FFFFFF" stroke="#E0E0E0"/>
    <circle cx="150" cy="80" r="50" fill="#D21F43" fill-opacity="0.1"/>
    <text x="100" y="160" font-size="18" font-weight="bold" fill="#000000">Jane Doe</text>
    <text x="100" y="180" font-size="14" fill="#666666">Senator, NY</text>
    <rect x="50" y="220" width="120" height="20" rx="4" fill="#D21F43" fill-opacity="0.2"/>
    <rect x="50" y="250" width="190" height="20" rx="4" fill="#D21F43" fill-opacity="0.2"/>
    <rect x="50" y="280" width="140" height="20" rx="4" fill="#D21F43" fill-opacity="0.2"/>
  </g>
</svg>
`;

const quizSvg = `
<svg width="800" height="600" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
  <rect width="800" height="600" fill="#FFFFFF"/>
  <rect y="0" width="800" height="120" fill="#458BEA" fill-opacity="0.1"/>
  <text x="50" y="70" font-size="32" font-family="Georgia" fill="#000000">Test Your Knowledge</text>
  <g transform="translate(100, 150)">
    <rect width="600" height="350" rx="8" fill="#FFFFFF" stroke="#E0E0E0"/>
    <text x="40" y="50" font-size="20" font-weight="bold" fill="#000000">Which issue did Representative Smith vote on in 2023?</text>
    <g transform="translate(40, 100)">
      <rect width="520" height="50" rx="4" fill="#458BEA" fill-opacity="0.1"/>
      <text x="20" y="32" font-size="16" fill="#000000">A. Healthcare Reform</text>
    </g>
    <g transform="translate(40, 160)">
      <rect width="520" height="50" rx="4" fill="#FFFFFF" stroke="#E0E0E0"/>
      <text x="20" y="32" font-size="16" fill="#000000">B. Climate Change</text>
    </g>
    <g transform="translate(40, 220)">
      <rect width="520" height="50" rx="4" fill="#FFFFFF" stroke="#E0E0E0"/>
      <text x="20" y="32" font-size="16" fill="#000000">C. Immigration Policy</text>
    </g>
    <rect x="40" y="300" width="520" height="8" rx="4" fill="#E0E0E0"/>
    <rect x="40" y="300" width="260" height="8" rx="4" fill="#458BEA"/>
    <text x="40" y="330" font-size="14" fill="#666666">Question 5 of 10</text>
  </g>
</svg>
`;

const pickViewsSvg = `
<svg width="800" height="600" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
  <rect width="800" height="600" fill="#FFFFFF"/>
  <rect y="0" width="800" height="120" fill="#458BEA" fill-opacity="0.1"/>
  <text x="50" y="70" font-size="32" font-family="Georgia" fill="#000000">Pick Your Views</text>
  <g transform="translate(50, 150)">
    <rect width="340" height="120" rx="8" fill="#FFFFFF" stroke="#458BEA"/>
    <text x="20" y="35" font-size="18" font-weight="bold" fill="#000000">Healthcare</text>
    <rect x="20" y="60" width="300" height="6" rx="3" fill="#E0E0E0"/>
    <circle cx="200" cy="63" r="12" fill="#458BEA"/>
    <text x="20" y="100" font-size="14" fill="#666666">Conservative</text>
    <text x="260" y="100" font-size="14" fill="#666666">Liberal</text>
  </g>
  <g transform="translate(410, 150)">
    <rect width="340" height="120" rx="8" fill="#FFFFFF" stroke="#D21F43"/>
    <text x="20" y="35" font-size="18" font-weight="bold" fill="#000000">Economy</text>
    <rect x="20" y="60" width="300" height="6" rx="3" fill="#E0E0E0"/>
    <circle cx="100" cy="63" r="12" fill="#D21F43"/>
    <text x="20" y="100" font-size="14" fill="#666666">Conservative</text>
    <text x="260" y="100" font-size="14" fill="#666666">Liberal</text>
  </g>
  <rect x="250" y="450" width="300" height="50" rx="8" fill="#458BEA"/>
  <text x="310" y="482" font-size="18" fill="#FFFFFF">Find Similar Representatives</text>
</svg>
`;

export const previewImages = {
  findRep: svgToDataUrl(findRepSvg),
  compare: svgToDataUrl(compareSvg),
  quiz: svgToDataUrl(quizSvg),
  pickViews: svgToDataUrl(pickViewsSvg)
}; 