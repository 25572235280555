// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HomePage.css */
.privacy-policy-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

  }
  .privacy-policy-page-main{
    position: relative;

    left: 100px;
    padding-bottom: 50px;
    max-width: 1512px;


 

  }


  


  .privacy-policy-page-main  .title-wrap{
    display: inline-flex;
    padding: 45.3px 131px 5px 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15.7px;

}

.privacy-policy-page-main .title{

    color: var(--Text-primary, #000);
    width: 1252px;

font-size: 50.4px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 60.48px */
}

.privacy-policy-page-main .text{
    width: 1252px;
    color: var(--Text-primary, #000);

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}
`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/style.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;;EAEb;EACA;IACE,kBAAkB;;IAElB,WAAW;IACX,oBAAoB;IACpB,iBAAiB;;;;;EAKnB;;;;;;EAMA;IACE,oBAAoB;IACpB,8BAA8B;IAC9B,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;;AAEf;;AAEA;;IAEI,gCAAgC;IAChC,aAAa;;AAEjB,iBAAiB;AACjB,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,YAAY;AAC/B;;AAEA;IACI,aAAa;IACb,gCAAgC;;IAEhC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;AAChC","sourcesContent":["/* HomePage.css */\n.privacy-policy-page-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n\n  }\n  .privacy-policy-page-main{\n    position: relative;\n\n    left: 100px;\n    padding-bottom: 50px;\n    max-width: 1512px;\n\n\n \n\n  }\n\n\n  \n\n\n  .privacy-policy-page-main  .title-wrap{\n    display: inline-flex;\n    padding: 45.3px 131px 5px 50px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 15.7px;\n\n}\n\n.privacy-policy-page-main .title{\n\n    color: var(--Text-primary, #000);\n    width: 1252px;\n\nfont-size: 50.4px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 120%; /* 60.48px */\n}\n\n.privacy-policy-page-main .text{\n    width: 1252px;\n    color: var(--Text-primary, #000);\n\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%; /* 27px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
