import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/Header/Header';
import { HowItWorks } from '../../components/HowItWorks/HowItWorks';
import { WhyVotesee } from '../../components/WhyVotesee/WhyVotesee';
import { MoreInfo } from '../../components/MoreInfo/MoreInfo';
import { TopReps } from '../../components/TopReps/TopReps';
import './style.css'; // Create this CSS file to style the HomePage

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>VoteSee - Understand Your Representatives' Voting Records | Transparent Democracy</title>
        <meta name="description" content="VoteSee helps you understand and analyze your representatives' voting records. Compare politicians, view analytics, and make informed decisions for a transparent democracy." />
        <meta name="keywords" content="VoteSee, voting records, representatives, political transparency, democracy, compare politicians, political analytics" />
        <link rel="canonical" href="https://www.votesee.com/" />
        <meta property="og:title" content="VoteSee - Understand Your Representatives' Voting Records" />
        <meta property="og:description" content="Analyze and compare politicians' voting records for a more transparent democracy with VoteSee." />
        <meta property="og:url" content="https://www.votesee.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="VoteSee - Transparent Political Voting Records" />
        <meta name="twitter:description" content="Understand your representatives' voting patterns and make informed decisions with VoteSee." />
      </Helmet>
      <div className="home-page">
        <h1 className="visually-hidden">VoteSee - Understand Your Representatives' Voting Records</h1>
        <Header />
        <HowItWorks />
        <WhyVotesee />
        <MoreInfo />
        <TopReps />
        <nav className="visually-hidden" aria-label="Footer Navigation">
          <Link to="/compare-reps">Compare Representatives</Link>
          <Link to="/analytics">View Analytics</Link>
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
        </nav>
      </div>
    </>
  );
};
