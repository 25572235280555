import React from 'react';
import { Helmet } from 'react-helmet';
import { FindARepBase } from '../../components/FindARepBase/FindARepBase';
import { RepGallery } from '../../components/RepGallery/RepGallery';
import './style.css';

export const FindARep = ({ email }) => {
  const localRepsTitle = "My Representatives";
  const localRepsDescription = "Quickly see the Congress people currently representing your area.";

  return (
    <div className="home-page1">
      <Helmet>
        <title>Find Your Representatives | VoteSee</title>
        <meta name="description" content="Easily find and learn about your local Congressional Representatives. Discover the most searched representatives and their voting records with VoteSee." />
        <meta name="keywords" content="find representatives, local congress members, popular representatives, VoteSee" />
      </Helmet>
      <FindARepBase />
      <RepGallery 
        title={localRepsTitle} 
        description={localRepsDescription} 
        dataType="local" 
        item={email} 
      />
      <RepGallery 
        title="Most Searched Reps" 
        description="See which Congressional Representatives are searched most often by the VoteSee community." 
        dataType="top" 
      />
    </div>
  );
};
