import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../Button/Button";
import "./style.css";
import Mail from "./mail.svg";
import Lock from "./lock.svg";
import Image from "./image.png";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { dbLogEvent, localLogEvent } from "../../utils/logUtil"; // Import our custom logEvent utilities

export const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const auth = getAuth();
    const analytics = getAnalytics();
    const history = useHistory();

    const handleSignIn = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            
            logEvent(analytics, 'sign-in');
            localStorage.setItem('userEmail', email);

            // Log the sign-in event
            await dbLogEvent('sign-in', email);
            localLogEvent('sign-in', email);

            // Trigger a full page refresh
            window.location.href = '/';
        } catch (error) {
            console.error("Error signing in:", error.message);
            setErrorMessage(error.message);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setErrorMessage("Please enter your email address.");
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            setErrorMessage("Password reset email sent. Please check your inbox.");
        } catch (error) {
            console.error("Error sending password reset email:", error.message);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="base-signin">
            <div className="left-section">
                <div className="box">
                    <h1 className="title">Welcome back</h1>
                    <p className="desc">Sign In to Continue</p>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="input-container">
                    <img src={Mail} className="icon" alt="Email Icon" />
                    <input 
                        type="email" 
                        className="input" 
                        placeholder="Email Address" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                </div>
                <div className="input-container">
                    <img src={Lock} className="icon" alt="Lock Icon" />
                    <input 
                        type="password" 
                        className="input" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                </div>
                <Button className="button" property1={"primary"} text="Sign in" onClick={handleSignIn} />
                <Button className="button forgot-password" property1={"secondary"} text="Forgot Password" onClick={handleForgotPassword} />
            </div>
            <div className="right-section">
                <img src={Image} className="image" alt="Decorative" />
            </div>
        </div>
    );
};
