import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import "./style.css";
import Image1 from "./8846-1.webp";
import Image2 from "./image-43.webp";
import Image3 from "./Rectangle-6.webp";
import Image5 from "./Rectangle-5.webp";
import Image4 from "./Rectangle-7.webp";
import { Link } from "react-router-dom";

export const Header = () => {
    const [showGetStarted, setShowGetStarted] = useState(true);

    useEffect(() => {
        const userEmail = localStorage.getItem('userEmail');
        const isTempAccount = userEmail && userEmail.includes('@votesee.temp.org');
        setShowGetStarted(!userEmail || isTempAccount);
    }, []);

    return (
        <div className="header-container">
            <div className="header-left-section">
                <div className="box">
                    <h1>Informed <span className="highlight">Voters</span> Drive Change.</h1>
                    <p className="text">At VoteSee, we believe that informed voters are the key to driving meaningful change in our democracy. Our platform helps you discover how your Congressional Representatives have voted on the issues that matter most to you.</p>
                    <img src={Image1} className="image" alt="Informed Voters" />
                    
                    {showGetStarted && (
                        <div className="button-container">
                            <Link to="/signup">
                                <Button className="button-instance" property1="primary" text="Get Started" />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <div className="mobile-image">
                <img src={Image2} className="image-content" alt="Block 1" />
            </div>
 
            <div className="right-section">
                <div className="image-block image-block1">
                    <img src={Image2} className="image-content" alt="Block 1" />
                </div>
                <div className="image-block image-block2">
                    <img src={Image5} className="image-content" alt="Block 2" />
                </div>
                <div className="image-block image-block3">
                    <img src={Image3} className="image-content" alt="Block 3" />
                </div>
                <div className="image-block image-block4">
                    <img src={Image4} className="image-content" alt="Block 4" />
                </div>
            </div>
        </div>
    );
};
