import React from "react";
import "./style.css"; // Ensure this is importing the relevant CSS for styling
import Image from './image.png';

export const Banner = ({ title, description }) => {
  return (
    <div className="banner-wrapper">
      <div className="full-width-background"></div>
      <header className="page-header">
        <h1>{title}</h1>
        <p>{description}</p>
        <img src={Image} alt="Profile" className="profile-image" />
      </header>
    </div>
  );
};
