import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import './style.css';
import image from './image.png';
import { dbLogEvent, localLogEvent } from "../../utils/logUtil"; // Import our custom logEvent utilities

export const FindARepBase = () => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [error, setError] = useState(null);
    const history = useHistory();

    // Debounce the API call
    const debouncedFetchSuggestions = useCallback(
        debounce(async (value) => {
            if (value.length > 2) {
                try {
                    const response = await fetch(`${window.apiUrl}/politiciansearch?search=${value}`, {
                        method: 'GET',
                        headers: {
                            'x-api-key': process.env.REACT_APP_API_KEY_1,
                            'Content-Type': 'application/json'
                        }
                    });
                    if (!response.ok) {
                        if (response.status === 401) {
                            throw new Error('Please login or sign up to see information about your representatives.');
                        } else {
                            throw new Error('An error occurred while fetching data. Please try again later.');
                        }
                    }
                    const data = await response.json();
                    setSuggestions(data);
                } catch (error) {
                    console.error("Error fetching suggestions:", error);
                    setError(error.message);
                    setSuggestions([]);
                }
            } else {
                setSuggestions([]);
            }
        }, 300),
        []
    );

    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        setSelectedIndex(-1);
        setError(null);
        debouncedFetchSuggestions(value);
    };

    const handleSuggestionClick = useCallback(async (suggestion) => {
        setQuery(suggestion.name);
        setSuggestions([]);

        await dbLogEvent('search', suggestion.politician_id);
        localLogEvent('search', suggestion.politician_id);

        history.push(`/rep/${suggestion.politician_id}`);
    }, [history]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowDown' && suggestions.length > 0) {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        } else if (e.key === 'ArrowUp' && suggestions.length > 0) {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length);
        } else if (e.key === 'Enter' && selectedIndex >= 0) {
            handleSuggestionClick(suggestions[selectedIndex]);
        }
    }, [suggestions, selectedIndex, handleSuggestionClick]);

    const formatSuggestion = useCallback((suggestion) => {
        let role = '';
        if (suggestion.type === 'sen') {
            role = 'Senator';
        } else if (suggestion.type === 'rep') {
            role = 'Representative';
        }

        const currentDate = new Date();
        const endDate = new Date(suggestion.end);
        const status = endDate < currentDate ? '(Former)' : '(Current)';

        const stateInfo = suggestion.state ? `, ${suggestion.state}` : '';
        const roleInfo = role ? `${role}${stateInfo}` : '';

        return `${suggestion.display_name}${roleInfo ? ' | ' + roleInfo : ''} ${status}`;
    }, []);

    return (
        <div className="findarepbase">
            <div className="banner">
                <div className="banner-overlay">
                    <img className="banner-image" src={image} alt="Description of image" />
                </div>
                <div className="content">
                    <h1 className="banner-title">Find your Reps.</h1>
                    <p className="banner-text">
                        VoteSee makes it easy to identify who represents you in Congress. You can search for Representatives by name or enter the address where you're registered to vote to find a list of the Congress people who represent you today.
                    </p>
                </div>
            </div>
            <div className="searchbar">
                <div className="searchicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M32.565 30.4349L27 24.9149C29.1601 22.2215 30.2062 18.8028 29.9232 15.3618C29.6402 11.9208 28.0495 8.71903 25.4783 6.41482C22.907 4.11062 19.5507 2.87912 16.0994 2.97356C12.648 3.068 9.36406 4.4812 6.92269 6.92257C4.48132 9.36394 3.06812 12.6479 2.97368 16.0992C2.87924 19.5506 4.11074 22.9069 6.41494 25.4781C8.71915 28.0494 11.9209 29.64 15.3619 29.9231C18.8029 30.2061 22.2216 29.16 24.915 26.9999L30.435 32.5199C30.5744 32.6604 30.7403 32.772 30.9231 32.8482C31.1059 32.9243 31.302 32.9636 31.5 32.9636C31.698 32.9636 31.8941 32.9243 32.0768 32.8482C32.2596 32.772 32.4255 32.6604 32.565 32.5199C32.8353 32.2402 32.9865 31.8664 32.9865 31.4774C32.9865 31.0883 32.8353 30.7146 32.565 30.4349ZM16.5 26.9999C14.4233 26.9999 12.3932 26.384 10.6665 25.2303C8.93977 24.0765 7.59396 22.4367 6.79924 20.518C6.00452 18.5994 5.79659 16.4882 6.20173 14.4514C6.60688 12.4146 7.60691 10.5437 9.07536 9.07523C10.5438 7.60678 12.4147 6.60676 14.4515 6.20161C16.4883 5.79647 18.5995 6.0044 20.5182 6.79912C22.4368 7.59384 24.0767 8.93965 25.2304 10.6664C26.3842 12.3931 27 14.4232 27 16.4999C27 19.2846 25.8937 21.9553 23.9246 23.9245C21.9555 25.8936 19.2847 26.9999 16.5 26.9999Z" fill="#878787" />
                    </svg>
                </div>

                <input
                    type="text"
                    className="search-input"
                    placeholder="Start typing state or name to find representatives..."
                    value={query}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                {error && <div className="error-message">{error}</div>}
                {query.length > 2 && !error && (
                    <ul className="suggestions-list">
                        {suggestions.length > 0 ? (
                            suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                    className={index === selectedIndex ? 'selected' : ''}
                                >
                                    {formatSuggestion(suggestion)}
                                </li>
                            ))
                        ) : (
                            <li className="no-results">No results found</li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};
