import React, { useMemo, useCallback, useState } from 'react';
import { IssueBar } from '../IssueBar/IssueBar';
import { MyIssues } from '../MyIssues/MyIssues';
import './style.css';

export const IssueGallery = ({ 
  issuesData = [], 
  party, 
  repImageUrl, 
  onPositionChange, 
  onRefreshGallery, 
  isUserScore = false,
  isCompareView = false,
  showFilter = false,
  selectedIssues = [],
  toggleIssue,
  allIssues = []
}) => {
  const [firstItemExpanded, setFirstItemExpanded] = useState(true);

  const groupedIssues = useMemo(() => {
    if (!issuesData || issuesData.length === 0) return [];
    
    return Object.values(issuesData.reduce((acc, scoreData) => {
      (scoreData.scores || []).forEach(score => {
        const topicName = score.topic_name || score.group_name;
        if (!acc[topicName]) {
          acc[topicName] = {
            topic_name: topicName,
            p1: score.p1,
            p2: score.p2,
            p1_def: score.p1_def,
            p2_def: score.p2_def,
            description: score.description,
            scores: []
          };
        }
        acc[topicName].scores.push({
          ...score,
          rep: scoreData.rep,
          politician_id: scoreData.politician_id,
          display_name: scoreData.display_name,
          party: scoreData.party,
          imageUrl: scoreData.image || scoreData.imageUrl,
          average_score: score.average_score || score.score,
          bill_count: score.bill_count,
          year: score.year
        });
      });
      return acc;
    }, {}));
  }, [issuesData]);

  const filteredIssues = useMemo(() => {
    if (!showFilter || selectedIssues.length === 0) return groupedIssues;
    return groupedIssues.filter(group => selectedIssues.includes(group.topic_name));
  }, [groupedIssues, selectedIssues, showFilter]);

  return (
    <div className="issue-gallery">
      {showFilter && (
        <div className="issues-filter">
          <MyIssues 
            issues={allIssues}
            selectedIssues={selectedIssues}
            toggleIssue={toggleIssue}
          />
        </div>
      )}
      <div className="issue-bars">
        {filteredIssues.map((group, index) => (
          <IssueBar
            key={`${group.topic_name}-${index}`}
            head={group.topic_name}
            issues={group.scores}
            onPositionChange={onPositionChange}
            onRefreshGallery={onRefreshGallery}
            isUserScore={isUserScore}
            defaultExpanded={index === 0 && firstItemExpanded}
          />
        ))}
      </div>
    </div>
  );
};
