// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px;
}

.chart-bubble {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  padding: 5px 20px;
  border-radius: 51px;
  background: var(--greyscale-greyscale-5, #F8F8F8);
  color: #565656;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chart-bubble:hover {
  background: #e4eefc;
  border: 2px solid #448aea;
}

.chart-bubble.selected {
  border-radius: 75px;
  background: #E4EEFC;
  border: 2px solid #448aea;
}`, "",{"version":3,"sources":["webpack://./src/components/ChartSelector/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,iDAAiD;EACjD,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,6BAA6B;EAC7B,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".chart-selector {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  margin: 5px;\n}\n\n.chart-bubble {\n  box-sizing: content-box;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 62px;\n  padding: 5px 20px;\n  border-radius: 51px;\n  background: var(--greyscale-greyscale-5, #F8F8F8);\n  color: #565656;\n  text-align: center;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  text-transform: uppercase;\n  border: 2px solid transparent;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.chart-bubble:hover {\n  background: #e4eefc;\n  border: 2px solid #448aea;\n}\n\n.chart-bubble.selected {\n  border-radius: 75px;\n  background: #E4EEFC;\n  border: 2px solid #448aea;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
