// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HomePage.css */
.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1511px;
  }
  
  .header,
  .how-it-works,
  .why-votesee,
  .more-info,
  .top-reps {
    width: 100%; /* Adjust width as needed */

  }
  
  .main-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden; /* Hide horizontal overflow */
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/style.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,iBAAiB;EACnB;;EAEA;;;;;IAKE,WAAW,EAAE,2BAA2B;;EAE1C;;EAEA;IACE,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB,EAAE,6BAA6B;EACnD;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb","sourcesContent":["/* HomePage.css */\n.home-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    max-width: 1511px;\n  }\n  \n  .header,\n  .how-it-works,\n  .why-votesee,\n  .more-info,\n  .top-reps {\n    width: 100%; /* Adjust width as needed */\n\n  }\n  \n  .main-content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow-x: hidden; /* Hide horizontal overflow */\n  }\n  \n  .visually-hidden {\n    position: absolute;\n    width: 1px;\n    height: 1px;\n    padding: 0;\n    margin: -1px;\n    overflow: hidden;\n    clip: rect(0, 0, 0, 0);\n    white-space: nowrap;\n    border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
