import React from 'react';
import { Banner } from '../../components/Banner/Banner';

import './style.css'; // Create this CSS file to style the HomePage

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page-wrapper">
      <Banner title="VoteSee Privacy Policy" />
      <div className="privacy-policy-page-main">
        <div className="title-wrap">
          <div className="title">Introduction</div>
          <div className="text">
            Last Updated 08/05/2024
          </div>
          <div className="text">
            Welcome to VoteSee. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share information about you when you use our services.
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Information We Collect</div>
          <div className="text">
            <ul>
              <li>We collect information about you in a variety of ways depending on how you interact with us and our website, including:</li>
              <li>Directly from you when you provide it to us, such as when you register for an account, sign up to receive communications from us, or contact us.</li>
              <li>Automatically through the use of cookies and similar technologies when you interact with our websites and emails.</li>
              <li>The following provides examples of the type of information that we collect in a variety of contexts and how we use the information:</li>
            </ul>
            <div className="text">
              <strong>Context</strong> - Types of Personal Information - Primary Purpose for Collection and Use of Personal Information
            </div>
            <ul>
              <li><strong>Account Registration:</strong> We collect your name and contact information when you create an account. We also collect information relating to the actions that you perform while logged into your account. We have a legitimate interest in providing account-related functionalities to our users. Accounts can be used to save your preferences and to share personalized content.</li>
              <li><strong>Client Information:</strong> We collect the name and contact information of our clients and their employees with whom we may interact. We have a legitimate interest in contacting our clients and communicating with them concerning normal business administration such as projects and services.</li>
              <li><strong>Your Use of Our Website:</strong> We use technology to monitor how you interact with our website. This may include which links you click on, or information that you type into our online forms. Some of these technologies may use “cookies.” Cookies are small pieces of information that a website sends to a computer’s hard drive while a website is viewed. Other types of data we track include your IP address, the website that referred you to our website, and data about the device you use to visit our website. We have a legitimate interest in making our website operate efficiently. We also use it to understand how you interact with our website to improve them and understand your preferences. Finally, we use this information to help detect and prevent fraud.</li>
              <li><strong>Email Interconnectivity:</strong> If you receive email from us, we use certain tools to capture data related to when you open our message, click on any links or banners it contains. We have a legitimate interest in understanding how you interact with our communications to you.</li>
              <li><strong>Feedback/Support:</strong> If you provide us feedback or contact us for support we will collect your name and email address, as well as any other content that you send to us, in order to reply. We have a legitimate interest in receiving, and acting upon, your feedback or issues.</li>
              <li><strong>Mailing List:</strong> When you sign up for one of our mailing lists we collect your email address or postal address. We share information about our services with individuals that consent to receive such information. We also have a legitimate interest in sharing information about our services.</li>
              <li><strong>Website Interactions:</strong> We use technology to monitor how you interact with our website. This may include which links you click on, or information that you type into our online forms. This may also include information about your device or browser. We have a legitimate interest in understanding how you interact with our website to better improve it, and to understand your preferences and interests in order to select offerings that you might find most useful. We also have a legitimate interest in detecting and preventing fraud.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">How We Use Your Information</div>
          <div className="text">
            <ul>
              <li><strong>To Provide and Improve Our Services:</strong> We use the information we collect to operate and maintain our services, understand how users interact with our platform, and develop new features and improvements.</li>
              <li><strong>Detection & Protection:</strong> To detect and protect against malicious, deceptive, fraudulent, or illegal activity, including violation of our policies and terms and conditions, security incidents, and harm to the rights, property, or safety of our company and our users, employees, or others.</li>
              <li><strong>Analytics:</strong> We use analytics tools to measure traffic and usage trends. This information helps us understand user behavior and preferences, which we use to improve our services and inform future features and partnerships.</li>
              <li><strong>Communication:</strong> We may use your personal information to communicate with you about updates, promotions, and other information related to VoteSee. You can opt out of these communications at any time.</li>
              <li><strong>Compliance and Protection:</strong> To comply with our legal or regulatory obligations, to establish or exercise our rights, and to defend against a legal claim. Although the sections above describe our primary purpose in collecting your information, in many situations we have more than one purpose. As a result, our collection and processing of your information is based on different contexts upon your consent, our need to perform a contract, our obligations under law, and/or our legitimate interest in conducting our business.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">How We Share Your Information</div>
          <div className="text">
            <ul>
              <li><strong>Service Providers:</strong> We may share your information with third-party service providers who help us operate and improve our services. These providers are contractually obligated to protect your information and use it only for the purposes for which it was shared.</li>
              <li><strong>Business Transfers:</strong> If VoteSee is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your personal information.</li>
              <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
              <li><strong>Other Disclosures With Your Consent:</strong> We may disclose your information to other third parties when we have your consent or direction to do so.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Your Choices</div>
          <div className="text">
            <ul>
              <li><strong>Access and Correction:</strong> You have the right to access and update your personal information at any time. You can do this by logging into your account or contacting us directly.</li>
              <li><strong>Opt-Out:</strong> You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications or by contacting us.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">How We Retain Information</div>
          <div className="text">
            <ul>
              <li><strong>Retention Period:</strong> We retain your personal information for only as long as necessary to fulfill the purposes outlined in this Privacy Policy, including for the purposes of satisfying any legal, accounting, or reporting requirements, unless a longer retention period is required or permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure of the information, the purposes for which we obtained the information and whether we can achieve those purposes through other means, as well as applicable legal requirements.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Data Security</div>
          <div className="text">
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free. Please take special care in deciding what information you send to us. In the event we are required by law to inform you of a breach to your personal information we may notify you electronically, in writing, or by telephone, if permitted to do so by law. Our website may permit you to create an account. When you do you will be prompted to create a password. You are responsible for maintaining the confidentiality of your password, and you are responsible for any access to or use of your account by someone else that has obtained your password, whether or not such access or use has been authorized by you. You should notify us of any unauthorized use of your password or account.
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Changes to This Privacy Policy</div>
          <div className="text">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Contact Us</div>
          <div className="text">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            <br />
            <strong>VoteSee Privacy Team</strong>
            <br />
            votesee.team@gmail.com
          </div>
        </div>
        <div className="title-wrap">
          <div className="text">
            By using VoteSee, you agree to the collection and use of information in accordance with this Privacy Policy.
            <br />
            Thank you for trusting VoteSee with your privacy.
          </div>
        </div>
      </div>
    </div>
  );
};
