// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.logo .vote-see {
    position: relative;
    top: -20px;
    height: 70px;
    width: 70px;
}

@media screen and (max-width: 1200px) {
    .logo .vote-see {
        position: relative;
        top: -15px;
        height: 50px;
        width: 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Logo/style.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,kBAAkB;QAClB,UAAU;QACV,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":["\n.logo .vote-see {\n    position: relative;\n    top: -20px;\n    height: 70px;\n    width: 70px;\n}\n\n@media screen and (max-width: 1200px) {\n    .logo .vote-see {\n        position: relative;\n        top: -15px;\n        height: 50px;\n        width: 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
