// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compare-reps-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* Add styles for the RepGallery in CompareReps */
.compare-reps-page .gallery-holder {
    margin-top: 30px;
    width: 90%;
    max-width: 1336px;
}

.compare-reps-page .gallery-container {
    width: 100%;
    max-width: 100%;
}

.compare-reps-page .gallery {
    gap: 20px;
    padding: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CompareReps/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA,iDAAiD;AACjD;IACI,gBAAgB;IAChB,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,eAAe;AACnB","sourcesContent":[".compare-reps-page{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n\n/* Add styles for the RepGallery in CompareReps */\n.compare-reps-page .gallery-holder {\n    margin-top: 30px;\n    width: 90%;\n    max-width: 1336px;\n}\n\n.compare-reps-page .gallery-container {\n    width: 100%;\n    max-width: 100%;\n}\n\n.compare-reps-page .gallery {\n    gap: 20px;\n    padding: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
