// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issue-gallery {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.issues-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.issue-bubble {
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 5px 15px;
    border-radius: 20px;
    background: var(--greyscale-greyscale-5, #F8F8F8);
    color: #565656;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
}

.issue-bubble:hover {
    background: #e4eefc;
    border: 2px solid #448aea;
}

.issue-bubble.selected {
    background: #E4EEFC;
    border: 2px solid #448aea;
}

.issue-bars {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/IssueGallery/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,iDAAiD;IACjD,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,6BAA6B;IAC7B,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf","sourcesContent":[".issue-gallery {\n    width: 100%;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 10px;\n}\n\n.issues-filter {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    margin-bottom: 20px;\n}\n\n.issue-bubble {\n    box-sizing: content-box;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 40px;\n    padding: 5px 15px;\n    border-radius: 20px;\n    background: var(--greyscale-greyscale-5, #F8F8F8);\n    color: #565656;\n    text-align: center;\n    font-size: 12px;\n    font-weight: 500;\n    text-transform: uppercase;\n    border: 2px solid transparent;\n    cursor: pointer;\n    transition: all 0.3s ease;\n}\n\n.issue-bubble:hover {\n    background: #e4eefc;\n    border: 2px solid #448aea;\n}\n\n.issue-bubble.selected {\n    background: #E4EEFC;\n    border: 2px solid #448aea;\n}\n\n.issue-bars {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
