import React, { useState, useEffect } from "react";
import { Button } from "../Button/Button";
import { MyIssues } from "../MyIssues/MyIssues"; // Ensure the correct path based on your file structure
import "./style.css";
import Back from "./back.svg";
import Image from "./image.png";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useHistory } from 'react-router-dom';
import { dbLogEvent, localLogEvent } from "../../utils/logUtil"; // Import our custom logEvent utilities

// Remove this line as we'll get analytics from the app instance
// const analytics = getAnalytics();

export const Setup3 = ({ userDetails, nextStep }) => {
    const [issues, setIssues] = useState([]);
    const [selectedIssues, setSelectedIssues] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const fetchIssues = async () => {
            const userEmail = userDetails.email;
            try {
                const response = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
                    method: 'GET',
                    headers: {
                      'x-api-key': process.env.REACT_APP_API_KEY_1,
                      'Content-Type': 'application/json'
                    }
                  });
                const data = await response.json();
                setIssues(data);
                setSelectedIssues(data.filter(issue => issue.favorite && issue.favorite.data && issue.favorite.data[0] === 1).map(issue => issue.group_name));
            } catch (error) {
                console.error("Error fetching issues:", error);
            }
        };

        fetchIssues();
    }, [userDetails.email]);

    const toggleIssue = async (issue) => {
        const isSelected = selectedIssues.includes(issue);
        const updatedSelectedIssues = isSelected
            ? selectedIssues.filter(item => item !== issue)
            : [...selectedIssues, issue];

        setSelectedIssues(updatedSelectedIssues);

        try {
            const response = await fetch(`${window.apiUrl}/userscores`, {
                method: 'PUT',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userDetails.email,
                    group_name: issue,
                    favorite: isSelected ? 0 : 1,
                    score: 50,
                    rank: updatedSelectedIssues.length // Set rank based on the current number of selected issues
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update user score");
            }
        } catch (error) {
            console.error("Error updating user score:", error);
            // Optionally, revert the UI state if the API call fails
            setSelectedIssues(selectedIssues);
        }
    };

    const handleFinish = async () => {
        try {
            // Update all selected issues with their final ranks
            const updatePromises = selectedIssues.map((issue, index) => 
                fetch(`${window.apiUrl}/userscores`, {
                    method: 'PUT',
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY_1,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: userDetails.email,
                        group_name: issue,
                        favorite: 1,
                        score: 50,
                        rank: index + 1
                    }),
                })
            );

            await Promise.all(updatePromises);

            // Log the sign-up event (assuming this is the final step of sign-up)
            await dbLogEvent('sign-up', userDetails.email);
            localLogEvent('sign-up', userDetails.email);

            // Try to get analytics instance and log event, but handle potential errors
            try {
                const analytics = getAnalytics();
                logEvent(analytics, 'sign_up_complete');
            } catch (analyticsError) {
                console.error("Error logging analytics event:", analyticsError);
                // Continue with the rest of the function even if analytics fails
            }

            nextStep(); // Proceed to the next step or redirect
        } catch (error) {
            console.error("Error finalizing user issues:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <div className="base-signup3">
            <div className="left-section">
                <div className="box">
                    <h1 className="title">Base Setup</h1>
                    <p className="desc">Let's get started by learning a little bit about you. Select the issues that matter most to you. </p>
                    <div className="intro">I care about...</div>
                    <div className="issues">
                        <MyIssues issues={issues} selectedIssues={selectedIssues} toggleIssue={toggleIssue} />
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: '20px'
                    }}>
                        <Button text="Finish" property1="primary" onClick={handleFinish} />
                    </div>
                    <div className="skip"></div>
                </div>
            </div>
        </div>
    );
};
