import React from 'react';
import { Helmet } from 'react-helmet'; // You'll need to install react-helmet
import './style.css';
import flag from './flag2.png';  // Import the flag image
import instagramIcon from './instagram.svg';
import facebookIcon from './facebook.svg';
import youtubeIcon from './youtube.svg';
import twitterIcon from './twitter.svg';  // Import the Twitter icon

export const RepresentativeBanner = ({ rep, imageUrl, details }) => {
    // Debugging: Log the district value to the console
    const districtValue = parseInt(details?.district, 10);
    
    

    const getPartyClass = (party) => {
        if (!party) return 'other';
        switch(party.toLowerCase()) {
            case 'democrat':
                return 'democrat';
            case 'republican':
                return 'republican';
            default:
                return 'other';
        }
    };

    const partyClass = getPartyClass(details?.party);

    const socialMediaLinks = [
        { icon: facebookIcon, url: details?.facebook, alt: 'Facebook', prefix: 'https://www.facebook.com/' },
        { icon: twitterIcon, url: details?.twitter, alt: 'Twitter', prefix: 'https://twitter.com/' },
        { icon: youtubeIcon, url: details?.youtube, alt: 'YouTube', prefix: 'https://www.youtube.com/' },
        { icon: instagramIcon, url: details?.instagram, alt: 'Instagram', prefix: 'https://www.instagram.com/' },
    ];

    const currentYear = new Date().getFullYear();
    const endYear = details?.end ? parseInt(details.end.split('-')[0], 10) : null;
    const electionInfo = endYear ? (endYear < currentYear ? `Last year in office: ${endYear}` : `Next Election: ${endYear - 1}`) : null;

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": rep?.display_name,
        "image": imageUrl,
        "jobTitle": details?.district && !isNaN(districtValue) && districtValue > 0 ? "Representative" : "Senator",
        "affiliation": details?.party,
        "memberOf": {
            "@type": "Organization",
            "name": "United States Congress"
        },
        "representativeOf": {
            "@type": "State",
            "name": rep?.state
        }
    };

    const metaDescription = `${rep?.display_name} is a ${details?.party} ${details?.district && !isNaN(districtValue) && districtValue > 0 ? `Representative for ${rep?.state} District ${details.district}` : `Senator for ${rep?.state}`}. Learn more about their work and contact information.`;

    return (
        <>
            <Helmet>
                <title>{`${rep?.display_name} - ${details?.party} ${details?.district && !isNaN(districtValue) && districtValue > 0 ? `Representative` : `Senator`} for ${rep?.state}`}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={`${rep?.display_name} - ${details?.party} ${details?.district && !isNaN(districtValue) && districtValue > 0 ? `Representative` : `Senator`} for ${rep?.state}`} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={imageUrl} />
                <meta property="og:type" content="profile" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${rep?.display_name} - ${details?.party} ${details?.district && !isNaN(districtValue) && districtValue > 0 ? `Representative` : `Senator`} for ${rep?.state}`} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={imageUrl} />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <section className={`rep-banner-wrapper ${partyClass}`} aria-label={`Profile of ${rep?.display_name}`} itemScope itemType="https://schema.org/Person">
                <div className="rep-banner-main">
                    <div className="flag-container">
                        <img src={flag} alt="United States Flag" className="flag-image" width="1011" height="578" loading="lazy" />
                    </div>
                    <div className="RepresentativeBanner">
                        {imageUrl ? (
                            <img src={imageUrl} alt={`${rep?.display_name}, ${details?.party} ${details?.district && !isNaN(districtValue) && districtValue > 0 ? `Representative for ${rep?.state} District ${details.district}` : `Senator for ${rep?.state}`}`} className="main-image" width="426" height="502" itemProp="image" loading="eager" />
                        ) : (
                            <img src="https://picsum.photos/1920/609" alt="Placeholder image" className="main-image" width="426" height="502" loading="lazy" />
                        )}
                    </div>
                    <div className="text-container">
                        <h1 className="name" itemProp="name">{rep?.display_name}</h1>
                        <p className="party" itemProp="affiliation">{details?.party}</p>
                        <p className="info" itemProp="addressRegion">{rep?.state}</p>
                        
                        {details?.district && !isNaN(districtValue) && districtValue > 0 ? (
                            <p className="info" itemProp="jobTitle">Representative, District: {details.district}</p>
                        ) : (
                            <p className="info" itemProp="jobTitle">Senator</p>
                        )}
                        
                        {electionInfo && <p className="info">{electionInfo}</p>}
                        
                        <div className="social-media-icons">
                            {socialMediaLinks.map((link, index) => (
                                link.url && (
                                    <a 
                                        key={index} 
                                        href={`${link.prefix}${link.url}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="social-icon-link"
                                        aria-label={`${rep?.display_name}'s ${link.alt} profile`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`${link.prefix}${link.url}`, '_blank', 'noopener,noreferrer');
                                        }}
                                        itemProp="sameAs"
                                    >
                                        <img src={link.icon} alt="" className="social-icon" width="30" height="30" />
                                    </a>
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
