// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page1 {
    width: 100%;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    flex-direction: column; /* Arrange content vertically */
    overflow: hidden; /* Hide any overflowing content */
    overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FindARep/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB,EAAE,gCAAgC;IACzD,mBAAmB,EAAE,8BAA8B;IACnD,sBAAsB,EAAE,+BAA+B;IACvD,gBAAgB,EAAE,iCAAiC;IACnD,kBAAkB;AACtB","sourcesContent":[".home-page1 {\n    width: 100%;\n    display: flex;\n    justify-content: center; /* Center content horizontally */\n    align-items: center; /* Center content vertically */\n    flex-direction: column; /* Arrange content vertically */\n    overflow: hidden; /* Hide any overflowing content */\n    overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
