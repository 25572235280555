import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

/**
 * Creates or gets a user, handling both temporary and authenticated users uniformly
 * @returns {Promise<{email: string, isTemporary: boolean}>}
 */
export const createOrGetUser = async () => {
  const auth = getAuth();
  
  return new Promise(async (resolve) => {
    // First check if we have a stored user
    const storedUser = getUserFromStorage();
    if (storedUser) {
      resolve(storedUser);
      return;
    }

    // If no stored user, check Firebase auth
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // Authenticated user
        const user = {
          email: firebaseUser.email,
          isTemporary: false
        };
        storeUser(user);
        resolve(user);
      } else {
        // Create temporary user
        const tempUser = await createTemporaryUser();
        resolve(tempUser);
      }
    });
  });
};

/**
 * Creates a temporary user with location data
 * @returns {Promise<{email: string, isTemporary: boolean}>}
 */
const createTemporaryUser = async () => {
  const tempEmail = `${uuidv4()}@votesee.temp.org`;
  const location = await getApproximateLocation();
  
  const userData = {
    email: tempEmail,
    username: tempEmail.split('@')[0],
    first_name: 'Temporary',
    last_name: 'User',
    zip_code: location.zip || '00000',
    state: location.state || 'Unknown',
    city: location.city || 'Unknown',
    address: null,
    last_login: new Date().toISOString()
  };

  try {
    const response = await fetch(`${window.apiUrl}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY_1,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Failed to create user');
    }

    storeUser({
      email: tempEmail,
      isTemporary: true
    });

    return {
      email: tempEmail,
      isTemporary: true
    };
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

/**
 * Updates a temporary user to a permanent one
 * @param {string} oldEmail - Temporary email
 * @param {string} newEmail - New permanent email
 * @param {string} password - New password
 * @returns {Promise<boolean>}
 */
export const updateTemporaryUser = async (oldEmail, newEmail, password) => {
  try {
    // Update user in database
    const response = await fetch(`${window.apiUrl}/users`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY_1,
      },
      body: JSON.stringify({
        email: oldEmail,
        new_email: newEmail,
        username: newEmail.split('@')[0],
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update user in database');
    }

    // Create Firebase account
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, newEmail, password);
    await signInWithEmailAndPassword(auth, newEmail, password);

    // Update stored user
    const userData = {
      email: newEmail,
      isTemporary: false
    };
    storeUser(userData);

    return true;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

/**
 * Store user data in localStorage
 * @param {{email: string, isTemporary: boolean}} userData 
 */
const storeUser = (userData) => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

/**
 * Get user data from localStorage
 * @returns {{email: string, isTemporary: boolean} | null}
 */
const getUserFromStorage = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData) : null;
};

/**
 * Check if current user is temporary
 * @returns {boolean}
 */
export const isTemporaryUser = () => {
  const userData = getUserFromStorage();
  return userData ? userData.isTemporary : true;
};

/**
 * Get current user's email
 * @returns {string|null}
 */
export const getCurrentUserEmail = () => {
  const userData = getUserFromStorage();
  return userData ? userData.email : null;
};

// Helper function for location data
const getApproximateLocation = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return {
      country: data.country_name,
      state: getStateAbbreviation(data.region),
      city: data.city,
      zip: data.postal,
      latitude: data.latitude,
      longitude: data.longitude
    };
  } catch (error) {
    console.error('Error fetching location:', error);
    return {
      country: 'Unknown',
      state: 'Unknown',
      city: 'Unknown',
      zip: '00000',
      latitude: null,
      longitude: null
    };
  }
};

// State abbreviation helper
const getStateAbbreviation = (fullStateName) => {
  const stateAbbreviations = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
  };
  
  return stateAbbreviations[fullStateName] || fullStateName;
};
