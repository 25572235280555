import React from 'react';
import { Banner } from '../../components/Banner/Banner';
import Image1 from "./our-mission.png";
import Image2 from "./our-team.png";

import './style.css'; // Ensure this CSS file contains the updated styles

export const About = () => {
  return (
    <div className="about-page-wrapper">
      
      <div className='about-header'>
        <div className="boxed-content">
          <div className='about-header-content'>
            <h2>About Us</h2>
            <p>
              Welcome to VoteSee, a non-partisan civic information platform designed to empower you with the knowledge you need to actively participate in our democracy. At VoteSee, we believe that informed citizens are the backbone of a healthy and vibrant democratic society. Our mission is to provide you with clear, objective, non-partisan information on political issues, candidates, and voting records, so you can make informed decisions at the ballot box.
            </p>
          </div>
        </div>
      </div>

      <div className="page-content">
        <section className="about-page-section boxed-content">
          <div className="column-left">
            <img src={Image1} alt="Our Mission" />
          </div>
          <div className="column-right">
            <div className="mission-text">
              <h2>Our Mission</h2>
              <p>
                VoteSee was created to help voters understand how representatives are voting on the issues that matter most to them.
              </p>
              <p>
                VoteSee is dedicated to fostering an engaged and informed electorate. We strive to simplify complex political issues and present them in an accessible and engaging format. Whether you are a seasoned voter or new to the political process, our platform is here to help you navigate the political landscape with confidence.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section className="full-width-section light-blue">
        <div className="boxed-content">
          <h2 className='extra-padding'>Our Principles</h2>
          <ul className='custom-list'>
            <li>
              <div className='bullet'></div>
              <span className='text'>
                <strong>Objectivity:</strong> We are committed to presenting facts without bias, ensuring you have access to clear and accurate information to form your own opinions.
              </span>
            </li>
            <li>
              <div className='bullet'></div>
              <span className='text'>
                <strong>Non-Partisanship:</strong> VoteSee operates without a political agenda. Our aim is to provide information that helps you make decisions based on your values.
              </span>
            </li>
            <li>
              <div className='bullet'></div>
              <span className='text'>
                <strong>Accessibility:</strong> Everyone should have access to accurate and easy-to-understand political information. Our platform is designed to be user-friendly and inclusive for people of all backgrounds and levels of political knowledge.
              </span>
            </li>
            <li>
              <div className='bullet'></div>
              <span className='text'>
                <strong>Engagement:</strong> We encourage active participation in the democratic process. By providing interactive tools and resources, we aim to make it easier for you to get involved and make your voice heard.
              </span>
            </li>
            <li>
              <div className='bullet'></div>
              <span className='text'>
                <strong>Clarity:</strong> We believe in clarity and simplicity. Complex political issues are broken down into understandable segments, making it easier for you to grasp and engage with the information.
              </span>
            </li>
          </ul>
        </div>
      </section>

      <div className="page-content">
        <section className="about-page-section boxed-content">
          <div className="column-left">
            <div className="team-text">
              <h2>Our Team</h2>
              <p>
                We are a team of Harvard Graduate students passionate about civics and committed to making a difference in the democratic process. We created this platform to empower citizens with the knowledge and tools they need to make informed decisions. We have diverse backgrounds from fields including public policy, technology, and data science, working together to ensure that the information we provide is accurate, unbiased, and easy to understand.
              </p>
              <p>
                Get to know the people behind VoteSee who are dedicated to helping you navigate the political landscape with confidence.
              </p>
            </div>
          </div>
          <div className="column-right">
            <img src={Image2} alt="Our Team" />
          </div>
        </section>
      </div>

      <section className="full-width-section light-blue">
        <div className="boxed-content">
          <h2>Our Data</h2>
          <p>
            At VoteSee, we are committed to providing accurate and transparent information to help you make informed decisions. Our mission is to provide you with clear, objective, non-partisan information on political issues, candidates, and voting records, so you can make informed decisions at the ballot box. We use publicly available voting record data from Congress.gov as the foundation for our issue-stance analysis. This data includes detailed records of how Representatives have voted on various bills and measures over time.
          </p>
          <p>
            To make sense of this vast amount of information, we employ an AI-assisted algorithm. This advanced technology helps us interpret voting records and present the results in a clear and accessible manner. By leveraging AI, we ensure that our analysis is both comprehensive and unbiased, providing you with reliable insights into where Representatives stand on key issues.
          </p>
        </div>
      </section>

      <div className="page-content">
        <section className="full-section boxed-content">
          <h2>Our Process</h2>
          <ol>
            <li>
              <strong>Understanding the Issues:</strong> Our process begins with a thorough examination of each Congressional vote. We identify the political issues associated with each vote and categorize them into specific topics such as healthcare, taxation, immigration, and more. This step is crucial in ensuring that we accurately capture the nuances of each Representative's voting behavior.
            </li>
            <li>
              <strong>Analyzing Representatives' Stances:</strong> Once we have categorized the votes, we identify Representatives’ stances based on where their votes fall on the political spectrum of potential issue positions. This involves analyzing how each vote aligns with liberal, moderate, or conservative viewpoints. By aggregating these scores across multiple votes, we create a comprehensive profile of each Representative's stance on various political issues.
            </li>
            <li>
              <strong>Aggregating Data:</strong> To provide a more complete picture, we aggregate this analysis over multiple votes and, in many cases, over multiple decades of Congressional voting records. This long-term view allows us to identify consistent patterns and trends in a Representative's voting behavior. It also helps to highlight any significant shifts in their positions over time.
            </li>
            <li>
              <strong>Comparing Stances:</strong> With the aggregated data, we are able to determine the stance of each Representative on specific political issues. We then compare these stances to those of other Representatives, allowing you to see how closely aligned they are with your own views. This comparative analysis is a key feature of VoteSee, enabling you to make more informed choices at the ballot box.
            </li>
          </ol>
        </section>
      </div>

      <section className="full-width-section light-blue">
        <div className="boxed-content">
          <h2>The VoteSee Community</h2>
          <p>
            At VoteSee, we are more than just a platform; we are a community of engaged citizens who believe in the power of informed voting. Join us today and take the first step toward becoming a more informed and active participant in our democracy. Together, we can make a difference.
          </p>
          <p>
            Thank you for choosing VoteSee. 🗳️
          </p>
        </div>
      </section>

    </div>
  );
};
