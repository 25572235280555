import React from "react";
import "./style.css";
import image from './logo.webp'; // Import your image file

export const Logo = ({ className }) => {
    return (
        <div className={`logo ${className}`}>
            <div className="overlap-group">

                <img src={image} alt="VoteSee Logo" className="vote-see" />

            </div>
        </div>
    );
};

