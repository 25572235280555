import React from "react";
import { Button } from "../Button/Button";
import "./style.css";
import Image from "./info.svg";
import Image1 from "./Image2.png";
import { Link } from "react-router-dom";

export const WhyVotesee = () => {
    return (
        <div className="why-votesee">
            <div className="container">
                <div className="content">
                    <img className="mobile-image" src={Image1} />
                    <div className="div">
                        <div className="section-title">
                            <div className="content-2">
                                <p className="heading">
                                    <span className="span">Why Use</span>
                                    <span className="span">&nbsp;</span>
                                    <span className="text-wrapper-2">VoteSee</span>
                                    <span className="span">?</span>
                                </p>
                                <p className="text">
                                    Keeping track of everything happening in Congress can be overwhelming. VoteSee was created to help you understand how your Representatives are voting on the issues that matter most to you. With VoteSee, you can:
                                </p>
                            </div>
                        </div>
                        <div className="list">
                            <div className="list-item">
                                <div className="text-wrapper-3"><img src={Image} className="image-content" /></div>
                                <p className="lorem-ipsum-dolor">
                                    Make Informed Decisions: Gain access to the information you need to make informed choices at the ballot box.
                                </p>
                            </div>
                            <div className="list-item">
                                <div className="text-wrapper-3"><img src={Image} className="image-content" /></div>
                                <p className="lorem-ipsum-dolor">
                                    Access Non-Partisan Data: Utilize objective, non-partisan data on Congressional voting records.
                                </p>
                            </div>
                            <div className="list-item">
                                <div className="text-wrapper-3"><img src={Image} className="image-content" /></div>
                                <p className="lorem-ipsum-dolor">
                                    Stay Engaged: Take quizzes to discover how well your views are represented by today’s Congressional Representatives.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <Link to="/find-a-rep">
                            <Button className="button-instance" property1="primary" text="Try Now" />
                        </Link>
                    </div>
                </div>
                <img className="mask-group" alt="Mask group" src={Image1} />
            </div>
        </div>
    );
};
