import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { RepCard } from '../RepCard/RepCard';
import { signOut, onAuthStateChanged, getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { IssueGallery } from '../../components/IssueGallery/IssueGallery';

export const QuizComponent = ({ 
  quizData, 
  quizDetails, 
  resetQuizSelection,
  associatedTopic,
  userEmail
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const auth = getAuth();
  const history = useHistory();

  const totalQuestions = quizData.reduce((acc, question) => Math.max(acc, question.Question), 0);

  const updateUserScore = async () => {
    if (totalCount === 0) return;
    const finalScore = totalScore*100 / (totalCount*2);

    try {
      const response = await fetch(`${window.apiUrl}/userscores`, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: userEmail,
          group_name: associatedTopic,
          score: finalScore,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update user score');
      }
    } catch (error) {
      // Handle error silently
    }
  };

  const handleNext = useCallback(async () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      if (quizDetails.EndingType === 2) {
        await updateUserScore();
      }
      setQuizFinished(true);
    }
  }, [currentQuestionIndex, totalQuestions, quizDetails.EndingType, updateUserScore]);

  const handleAnswerSelect = (subQuestionIndex, selectionResult, countForScoring) => {
    setSelectedAnswers(prev => {
      const newSelections = [...prev];
      newSelections[currentQuestionIndex] = {
        ...newSelections[currentQuestionIndex],
        [subQuestionIndex]: selectionResult
      };
      return newSelections;
    });

    setTotalScore(prev => prev + selectionResult);
    setTotalCount(prev => prev + countForScoring);
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswers([]);
    setTotalScore(0);
    setTotalCount(0);
    setQuizFinished(false);
    resetQuizSelection();
    history.push('/quiz');
  };

  if (quizFinished) {
    return (
      <QuizEndScreen 
        resetQuiz={resetQuiz} 
        endingType={quizDetails.EndingType} 
        totalScore={totalScore} 
        totalCount={totalCount}
        userEmail={userEmail} 
        associatedTopic={associatedTopic} 
      />
    );
  }

  return (
    <div className="quiz-layout">
      <div className="quiz-left">
        <ProgressBar
          current={currentQuestionIndex + 1}
          total={totalQuestions}
        />
        <h1>{quizDetails.QuizName || 'Quiz'}</h1>
        <p>{quizDetails.QuizDescription || 'Take the quiz to find out more!'}</p>
      </div>
      <div className="quiz-right">
        <QuizContent
          questionGroup={quizData.filter(q => q.Question === currentQuestionIndex + 1)}
          onSelectAnswer={handleAnswerSelect}
          currentAnswers={selectedAnswers[currentQuestionIndex] || {}}
          onNext={handleNext}
          isLastQuestion={currentQuestionIndex === totalQuestions - 1}
          currentQuestionIndex={currentQuestionIndex}
        />
      </div>
    </div>
  );
};

const ProgressBar = ({ current, total }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress-filled"
        style={{ width: `${(current / total) * 100}%` }}
      />
    </div>
  );
};

const QuizContent = ({ questionGroup, onSelectAnswer, currentAnswers, onNext, isLastQuestion, currentQuestionIndex }) => {
  useEffect(() => {
    
  }, [currentQuestionIndex]);

  if (questionGroup.length === 0) return null;

  const mainQuestion = questionGroup[0];
  const isAnswerSelected = Object.keys(currentAnswers).length > 0;

  return (
    <div className="quiz-content">
      <h3>{`Question ${currentQuestionIndex + 1}`}</h3>
      <h4>{mainQuestion.QuestionText}</h4>
      {questionGroup.map((question, index) => (
        <div key={index} className="options">
          <div className="option">
            <input
              type="radio"
              id={`question-${mainQuestion.Question}-${question.SubQuestion}`}
              name={`question-${mainQuestion.Question}`}
              value={question.SelectionResult}
              count={question.CountForScoring}
              checked={currentAnswers[index] === question.SelectionResult}
              onChange={() => onSelectAnswer(index, question.SelectionResult, question.CountForScoring)}
            />
            <label htmlFor={`question-${mainQuestion.Question}-${question.SubQuestion}`}>
              <div className="label-text">{question.ResponseText}</div>
            </label>
          </div>
        </div>
      ))}
      <button 
        onClick={onNext} 
        disabled={!isAnswerSelected}
        className={isAnswerSelected ? "active" : "inactive"}
      >
        {isLastQuestion ? 'Finish' : 'Next >'}
      </button>
    </div>
  );
};

import { fetchWikipediaImage } from "../../utils/utils";



const QuizEndScreen = ({ resetQuiz, endingType, totalScore, totalCount, userEmail, associatedTopic }) => {
  const [selectedPolitician, setSelectedPolitician] = useState(null);
  const [topics, setTopics] = useState([]);
  const [image, setImage] = useState("");
  const [comparisonScores, setComparisonScores] = useState([]);

  const finalScore = (totalScore*100) / (totalCount*2);

  useEffect(() => {
    if (endingType === 1) {
      fetchSimilarPolitician();
    } else if (endingType === 2) {
      fetchComparisonScores();
    }
  }, [endingType, userEmail]);

  const fetchSimilarPolitician = async () => {
    try {
      const response = await fetch(`${window.apiUrl}/similarpoliticians?email=${userEmail}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch similar politicians');
      }

      const data = await response.json();

      if (data.length > 0) {
        const firstPolitician = data[0];
        fetchPoliticianDetails(firstPolitician.politician_id);
      }
    } catch (error) {
      console.error('Error fetching similar politicians:', error);
    }
  };

  const fetchPoliticianDetails = async (politician_id) => {
    try {
      const response = await fetch(`${window.apiUrl}/politicians?politician_id=${politician_id}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch politician details');
      }

      const details = await response.json();

      if (details.length > 0) {
        const politician = details[0];
        setSelectedPolitician(politician);
        fetchTopicsAndImage(politician.politician_id, politician.display_name, politician.id_wikipedia);
      }
    } catch (error) {
      console.error('Error fetching politician details:', error);
    }
  };

  const fetchTopicsAndImage = async (politician_id, display_name, id_wikipedia) => {
    try {
      const topicsResponse = await fetch(`${window.apiUrl}/topissues?politician_id=${politician_id}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
        },
      });

      if (!topicsResponse.ok) {
        throw new Error('Failed to fetch topics');
      }

      const topicsData = await topicsResponse.json();
      const topicsList = topicsData.map(topic => topic.topic_name);
      setTopics(topicsList);

      const imageUrl = await fetchWikipediaImage(id_wikipedia) || await fetchWikipediaImage(display_name);
      setImage(imageUrl);
    } catch (error) {
      console.error('Error fetching topics or Wikipedia image:', error);
    }
  };

  const fetchComparisonScores = async () => {
    try {
      const userResponse = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const userScores = await userResponse.json();

      // Format the data to match PickYourViews structure
      const userScoreData = {
        rep: 'You',
        politician_id: 'user',
        display_name: 'You',
        scores: [{
          topic_name: associatedTopic,
          average_score: finalScore,
          description: userScores.find(s => s.group_name === associatedTopic)?.description || '',
          p1: userScores.find(s => s.group_name === associatedTopic)?.p1 || '',
          p2: userScores.find(s => s.group_name === associatedTopic)?.p2 || '',
          p1_def: userScores.find(s => s.group_name === associatedTopic)?.p1_def || '',
          p2_def: userScores.find(s => s.group_name === associatedTopic)?.p2_def || '',
          rank: 1
        }],
        image: 'https://via.placeholder.com/150?text=User',
        party: 'Your Party'
      };

      setComparisonScores([userScoreData]);
    } catch (error) {
      console.error("Error fetching comparison scores:", error);
    }
  };

  if (endingType === 1) {
    if (!selectedPolitician) {
      return <div className="quiz-end-screen">Loading...</div>;
    }

    return (
      <div className="quiz-end-screen">
        <div className="quiz-end-title">Your Most Similar Rep</div>
        <div className="quiz-end-box">
          <Link to={`/rep/${selectedPolitician.politician_id}`} className="rep-card-link">
            <RepCard
              politician_id={selectedPolitician.politician_id}
              maskGroup={image}
              display_name={selectedPolitician.display_name || `${selectedPolitician.name_first} ${selectedPolitician.name_last}`}
              name={`${selectedPolitician.name_first} ${selectedPolitician.name_last}`}
              title={selectedPolitician.party || "Unknown"}
              state={selectedPolitician.state || "Unknown"}
              type={selectedPolitician.type || "rep"}
              nextElection={getPreviousYear(selectedPolitician.end) || "Unknown"}
              topics={topics}
              description={`Serving since ${selectedPolitician.start} until ${selectedPolitician.end}`}
            />
          </Link>
        </div>
        <button onClick={resetQuiz} className="home-button">
          Home
        </button>
      </div>
    );
  } else if (endingType === 2) {
    return (
      <div className="quiz-end-screen">
        <div className="quiz-end-title">Your Score for | <strong> {associatedTopic}</strong></div>
        <div className="quiz-end-box">
          <IssueGallery 
            issuesData={comparisonScores}
            isUserScore={true}
            isCompareView={true}
          />
        </div>
        <button onClick={resetQuiz} className="home-button">
          Home
        </button>
      </div>
    );
  }

  return null;
};

const getPreviousYear = (endDate) => {
  if (!endDate) return "Unknown";
  const year = new Date(endDate).getFullYear();
  return year - 1;
};






