import React, { useState, useEffect } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "./style.css"; // Ensure this is importing the relevant CSS for styling
import "../../styleguide.css"; // Global styles
import { Banner } from '../../components/Banner/Banner';
import { StateList } from './StateList';
import { signOut, updatePassword, getAuth, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

const apiUrl = 'https://votesee.org/api';
const genericUserIconUrl = 'https://via.placeholder.com/150?text=User'; // Replace with your actual URL if necessary

// State abbreviations map
const stateAbbreviations = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY"
};

export const Profile = () => {
  const [userInfo, setUserInfo] = useState({});
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const response = await fetch(`${window.apiUrl}/users?email=${user.email}`, {          
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });
          const data = await response.json();
          setUserInfo(data);
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        
        // Optionally redirect to login page or show a message
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(userEmail, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      alert("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Error updating password: " + error.message);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const formatDateToMySQL = (date) => {
      const d = new Date(date);
      const twoDigits = (num) => (num < 10 ? `0${num}` : num);
      return `${d.getFullYear()}-${twoDigits(d.getMonth() + 1)}-${twoDigits(d.getDate())} ${twoDigits(d.getHours())}:${twoDigits(d.getMinutes())}:${twoDigits(d.getSeconds())}`;
    };

    // Validate ZIP code format only if it's not empty
    if (userInfo.zip_code && !/^\d{5}(-\d{4})?$/.test(userInfo.zip_code)) {
      alert("Invalid ZIP code format. Please use 12345 or 12345-6789.");
      return;
    }

    // Validate state
    if (userInfo.state && !Object.values(stateAbbreviations).includes(userInfo.state)) {
      alert("Invalid state selection. Please choose from the dropdown.");
      return;
    }

    // Prepare payload
    const payload = {
      email: userInfo.email,
      zip_code: userInfo.zip_code || null,
      address: userInfo.address || null,
      city: userInfo.city || null,
      state: userInfo.state || null,
      district: null, // Set district to null
    };

    if (userInfo.last_login) {
      payload.last_login = formatDateToMySQL(userInfo.last_login);
    }

    // Log payload before sending
    

    try {
      const response = await fetch(`${window.apiUrl}/users`, {
        method: 'PATCH',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();

      if (response.ok) {
        alert("User information updated successfully");
        // Update local state to reflect the change
        setUserInfo(prevState => ({...prevState, district: null}));
      } else {
        console.error("Server responded with error:", responseData);
        alert("Error updating user information: " + (responseData.message || 'Unknown error'));
      }
    } catch (error) {
      console.error("Error updating user info:", error);
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      window.location.href = '/'; // Redirect to home page after logging out
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Banner classname="banner" title="Profile Settings" description="Edit your info, choose your location, pick your issues" />
      <div className="profile-page">
        <div className="basic-content">
          <h2>Basic Information</h2>
          <form className="form">
            <label htmlFor="email">EMAIL</label>
            <input type="email" id="email" name="email" value={userInfo.email || ''} onChange={handleChange} disabled />
          </form>
        </div>

        <div className="line"></div>

        <div className="mylocation">
          <h2>My Location</h2>
          <form className="form" onSubmit={handleSave}>
            <label htmlFor="street-address">Street Address</label>
            <input type="text" id="street-address" name="address" value={userInfo.address || ''} onChange={handleChange} />

            <label htmlFor="city">City</label>
            <input type="text" id="city" name="city" value={userInfo.city || ''} onChange={handleChange} />

            <label htmlFor="state">State</label>
            <select id="state" name="state" value={userInfo.state || ''} onChange={handleChange}>
              <option value="">Select a state</option>
              {Object.entries(stateAbbreviations).map(([fullName, abbr]) => (
                <option key={abbr} value={abbr}>{fullName} ({abbr})</option>
              ))}
            </select>

            <label htmlFor="zip-code">Zip Code</label>
            <input 
              type="text" 
              id="zip-code" 
              name="zip_code" 
              value={userInfo.zip_code || ''} 
              onChange={handleChange}
              placeholder="12345 or 12345-6789"
            />

            <div className="button-container">
              <button type="submit" className="button primary"><span className="text-wrapper">Save</span></button>
            </div>
          </form>
        </div>

        <div className="line"></div>

        <div className="password-change basic-content">
          <h2>Change Password</h2>
          <form className="form" onSubmit={handlePasswordChange}>
            <label htmlFor="current-password">CURRENT PASSWORD</label>
            <input type="password" id="current-password" name="current-password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />

            <label htmlFor="new-password">NEW PASSWORD</label>
            <input type="password" id="new-password" name="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />

            <label htmlFor="retype-password">RETYPE PASSWORD</label>
            <input type="password" id="retype-password" name="retype-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          </form>
        </div>

        <div className="button-container">
          <button type="submit" className="button primary"><span className="text-wrapper">Change Password</span></button>
        </div>

        <div className="line"></div>

        <div className="logout-container">
          <button className="button secondary" onClick={handleLogout}><span className="text-wrapper">Logout</span></button>
        </div>
      </div>
    </DndProvider>
  );
};