import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

export const RepCard = ({ politician_id, maskGroup, name, title, type, state, nextElection, topics, description, display_name, party }) => {
    const history = useHistory();

    const handleCardClick = () => {
        history.replace(`/rep/${politician_id}`);
    };

    const getPartyClass = (title) => {
        if (title === 'Republican') return 'republican';
        if (title === 'Democrat') return 'democrat';
        return 'other';
    };

    const cardClassName = `rep-card ${getPartyClass(title)}`;

    return (
        <div className={cardClassName} onClick={handleCardClick}>
            <div className={`image-wrapper ${getPartyClass(title)}`}>
                <img className="mask-group" alt={name} src={maskGroup} />
            </div>
            <div className="content">
                <div className="text-box">
                    <p className="text-wrapper">{display_name} ({title.charAt(0)}) - {type === "sen" ? "Senate" : type === "rep" ? "House" : type}</p>
                    <div className="div">Next Election: {nextElection}</div>
                </div>
                <div className="topics-container">
                    {topics.slice(0, 3).map((topic, index) => (
                        <div key={index} className="topic">
                            {topic}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
