import React from 'react';
import "./style.css";

export const SignupProgress = ({ currentStep }) => {
    const steps = [
        { text: "Base setup", step: 1 },
        { text: "Enter your address", step: 2 },
        { text: "Customize profile", step: 3 }
    ];

    return (
        <div className="signup-progress">
            <div className="group">
                {steps.map((step, index) => (
                    <div key={index} className="item">
                        <div className={`ellipse ${currentStep === step.step ? 'active' : ''}`}></div>
                        <div className={`text ${currentStep === step.step ? 'active' : ''}`}>
                            {step.text}
                        </div>
                        {currentStep === step.step && (
                            <div className="frame-wrapper">
                                <div className="div-wrapper">
                                    <div className="text-2">{step.step} out of 3</div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                <div className="rectangle"></div>
            </div>
        </div>
    );
};