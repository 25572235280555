// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    all: unset;
    align-items: center;
    border-radius: 6.3px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 8.4px;
    justify-content: center;
    overflow: hidden;
    padding: 12.6px 21px;
    position: relative;
    cursor: pointer; /* Add a pointer cursor for better UX */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition for background and transform */
}

.button .text-wrapper {
    all: unset;
    box-sizing: border-box;
    color: var(--neutral-0);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16.8px;
    font-weight: 700;
    letter-spacing: 0.52px;
    line-height: 25.2px;
    margin-top: -1.05px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.button.secondary {
    background-color: var(--primitive-red);
}

.button.secondary:hover {
    border-radius: 6px;
background: linear-gradient(111deg, #EC5372 -8.87%, #458BEA 91.98%);
}

.button.primary {
    background-color: var(--primitive-blue);
}

.button.primary:hover {
    border-radius: 6px;
background: linear-gradient(111deg, #458BEA -8.87%, #EC5372 91.98%);
}

/* Add styles for active and hover states */
.button:active {
    transform: scale(0.95); /* Slightly shrink the button on click */
}

`, "",{"version":3,"sources":["webpack://./src/components/Button/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,oBAAoB;IACpB,sBAAsB;IACtB,oBAAoB;IACpB,UAAU;IACV,uBAAuB;IACvB,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,eAAe,EAAE,uCAAuC;IACxD,iDAAiD,EAAE,mDAAmD;AAC1G;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,oCAAoC;IACpC,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;AACtB,mEAAmE;AACnE;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;AACtB,mEAAmE;AACnE;;AAEA,2CAA2C;AAC3C;IACI,sBAAsB,EAAE,wCAAwC;AACpE","sourcesContent":[".button {\n    all: unset;\n    align-items: center;\n    border-radius: 6.3px;\n    box-sizing: border-box;\n    display: inline-flex;\n    gap: 8.4px;\n    justify-content: center;\n    overflow: hidden;\n    padding: 12.6px 21px;\n    position: relative;\n    cursor: pointer; /* Add a pointer cursor for better UX */\n    transition: background-color 0.3s, transform 0.2s; /* Smooth transition for background and transform */\n}\n\n.button .text-wrapper {\n    all: unset;\n    box-sizing: border-box;\n    color: var(--neutral-0);\n    font-family: \"Inter-Bold\", Helvetica;\n    font-size: 16.8px;\n    font-weight: 700;\n    letter-spacing: 0.52px;\n    line-height: 25.2px;\n    margin-top: -1.05px;\n    position: relative;\n    white-space: nowrap;\n    width: fit-content;\n}\n\n.button.secondary {\n    background-color: var(--primitive-red);\n}\n\n.button.secondary:hover {\n    border-radius: 6px;\nbackground: linear-gradient(111deg, #EC5372 -8.87%, #458BEA 91.98%);\n}\n\n.button.primary {\n    background-color: var(--primitive-blue);\n}\n\n.button.primary:hover {\n    border-radius: 6px;\nbackground: linear-gradient(111deg, #458BEA -8.87%, #EC5372 91.98%);\n}\n\n/* Add styles for active and hover states */\n.button:active {\n    transform: scale(0.95); /* Slightly shrink the button on click */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
