export const fetchWikipediaImage = async (wikipediaId, size = 'small') => {
    try {
        // Check if wikipediaId is 'J.D. Vance' and change it to 'JD_Vance'
        if (wikipediaId === 'J.D. Vance') {
            wikipediaId = 'JD_Vance';
        }

        // Define image sizes
        const imageSizes = {
            small: 500,
            medium: 300,
            large: 1000
        };

        // Get the appropriate image size or default to small
        const imageSize = imageSizes[size] || imageSizes.small;

        const url = `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=pageimages&piprop=thumbnail&titles=${wikipediaId}&redirects=1&pithumbsize=${imageSize}&origin=*`;

        const response = await fetch(url);
        const data = await response.json();

        const pages = data.query.pages;
        const pageId = Object.keys(pages)[0];
        
        // Access the thumbnail image
        const imageUrl = pages[pageId].thumbnail?.source || null;
        
        return imageUrl;
    } catch (error) {
        console.error('Error fetching Wikipedia image:', error);
        return null;
    }
};


export const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1;
};

export const isFirefox = () => {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};
