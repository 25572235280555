import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { fetchWikipediaImage } from '../../utils/utils';
import '../../styles/commonChartStyles.css';
import './style.css';

export const RepresentativeEffectiveness = () => {
  const [data, setData] = useState([]);
  const [topTen, setTopTen] = useState([]);
  const [selectedParty, setSelectedParty] = useState('All');
  const [selectedType, setSelectedType] = useState('All');
  const [sortOrder, setSortOrder] = useState('mostEffective');
  const topTenChartRef = useRef(null);
  const histogramRef = useRef(null);

  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = Math.min(1250, containerRef.current.getBoundingClientRect().width);
        setDimensions({
          width: width,
          height: Math.max(400, width * 0.5)
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedParty, selectedType]);

  const fetchData = async () => {
    try {
      const params = new URLSearchParams();
      if (selectedParty !== 'All') params.append('party', selectedParty);
      if (selectedType !== 'All') params.append('type', selectedType);

      const response = await fetch(`${window.apiUrl}/representativeeffectiveness?${params}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch data');
      const fetchedData = await response.json();
      
      // Filter out independents
      const filteredData = fetchedData.filter(d => d.party !== 'Independent');
      
      setData(filteredData);
      updateTopTen(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateTopTen = (allData) => {
    if (!Array.isArray(allData) || allData.length === 0) {
      console.warn('Invalid or empty data received');
      return;
    }

    let sorted;
    switch (sortOrder) {
      case 'mostEffective':
        sorted = allData.sort((a, b) => (b.effectiveness || 0) - (a.effectiveness || 0));
        break;
      case 'leastEffective':
        sorted = allData.sort((a, b) => (a.effectiveness || 0) - (b.effectiveness || 0));
        break;
      default:
        sorted = allData.sort((a, b) => (b.effectiveness || 0) - (a.effectiveness || 0));
    }
    setTopTen(sorted.slice(0, 10));
  };

  useEffect(() => {
    updateTopTen(data);
  }, [sortOrder]);

  useEffect(() => {
    if (topTen.length > 0 && dimensions.width > 0) {
      drawTopTenChart();
    }
  }, [topTen, dimensions]);

  useEffect(() => {
    if (data.length > 0 && dimensions.width > 0) {
      drawHistogram();
    }
  }, [data, dimensions]);

  const drawTopTenChart = async () => {
    const { width, height } = dimensions;
    const margin = { top: 40, right: 100, bottom: 60, left: width < 600 ? 100 : 200 };

    d3.select(topTenChartRef.current).selectAll("*").remove();

    const svg = d3.select(topTenChartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const maxEffectiveness = d3.max(topTen, d => Number(d.effectiveness) || 0);
    const x = d3.scaleLinear()
      .domain([0, maxEffectiveness])
      .range([0, chartWidth]);

    const y = d3.scaleBand()
      .domain(topTen.map(d => d.display_name))
      .range([0, chartHeight])
      .padding(0.1);

    svg.selectAll("rect")
      .data(topTen)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("y", d => y(d.display_name))
      .attr("width", d => x(Number(d.effectiveness) || 0))
      .attr("height", y.bandwidth())
      .attr("fill", d => d.party === 'Republican' ? '#ff9999' : d.party === 'Democrat' ? '#99ccff' : '#cccccc')
      .attr("rx", 5)
      .attr("ry", 5);

    svg.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).ticks(5).tickFormat(d3.format(".0%")));

    // Add labels and representative images
    const imageSize = Math.min(36, y.bandwidth());
    topTen.forEach(async (d, i) => {
      const effectivenessValue = Number(d.effectiveness);
      const label = isNaN(effectivenessValue) ? 'N/A' : d3.format(".1%")(effectivenessValue);
      
      svg.append("text")
        .attr("class", "label")
        .attr("x", x(effectivenessValue) + 5)
        .attr("y", y(d.display_name) + y.bandwidth() / 2)
        .attr("dy", "0.35em")
        .text(label)
        .attr("fill", "black")
        .attr("text-anchor", "start");

      const imageUrl = await fetchWikipediaImage(d.id_wikipedia);
      if (imageUrl) {
        const imageX = x(effectivenessValue) - imageSize / 2;
        const imageGroup = svg.append("g")
          .attr("transform", `translate(${imageX}, ${y(d.display_name) + y.bandwidth() / 2})`)
          .on("click", () => window.open(`/rep/${d.politician_id}`, "_blank"));

        imageGroup.append("circle")
          .attr("r", imageSize / 2 + 3)
          .attr("fill", d.party === 'Republican' ? 'rgb(123, 6, 30)' : d.party === 'Democrat' ? 'rgb(69, 139, 234)' : '#808080');

        imageGroup.append("clipPath")
          .attr("id", `clip-${d.politician_id}`)
          .append("circle")
          .attr("r", imageSize / 2);

        imageGroup.append("image")
          .attr("xlink:href", imageUrl)
          .attr("x", -imageSize / 2 * 1.2)
          .attr("y", -imageSize / 2 * 1.2)
          .attr("width", imageSize * 1.2)
          .attr("height", imageSize * 1.2)
          .attr("clip-path", `url(#clip-${d.politician_id})`);
      }

      svg.append("text")
        .attr("class", "name-label")
        .attr("x", -5)
        .attr("y", y(d.display_name) + y.bandwidth() / 2)
        .attr("dy", "0.35em")
        .text(d.display_name)
        .attr("fill", "black")
        .attr("text-anchor", "end");
    });
  };

  const drawHistogram = () => {
    
    if (data.length === 0) {
      console.warn('No data available for histogram');
      return;
    }

    const { width, height } = dimensions;
    const margin = { top: 40, right: 120, bottom: 60, left: 80 };

    d3.select(histogramRef.current).selectAll("*").remove();

    const svg = d3.select(histogramRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const x = d3.scaleLinear()
      .range([0, chartWidth]);

    const histogram = d3.histogram()
      .value(d => Number(d.effectiveness) || 0)
      .domain(x.domain())
      .thresholds(60);

    const bins = histogram(data);

    // Calculate percentages and find max percentage
    const partyCounts = { 'Republican': 0, 'Democrat': 0 };
    data.forEach(d => partyCounts[d.party]++);

    let maxPercentage = 0;
    bins.forEach(bin => {
      bin.percentages = { 'Republican': 0, 'Democrat': 0 };
      ['Republican', 'Democrat'].forEach(party => {
        const count = bin.filter(d => d.party === party).length;
        const percentage = (count / partyCounts[party]) * 100;
        bin.percentages[party] = percentage;
        maxPercentage = Math.max(maxPercentage, percentage);
      });
    });

    // Adjust x domain based on data
    const maxEffectiveness = d3.max(data, d => Number(d.effectiveness) || 0);
    x.domain([0, maxEffectiveness]);

    const y = d3.scaleLinear()
      .domain([0, maxPercentage || 1]) // Use 1 as default if maxPercentage is 0
      .range([chartHeight, 0]);

    const colorScale = d3.scaleOrdinal()
      .domain(['Republican', 'Democrat'])
      .range(['#ff9999', '#99ccff']);

    svg.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).ticks(width < 600 ? 5 : 10).tickFormat(d3.format(".0%")));

    svg.append("g")
      .call(d3.axisLeft(y).ticks(5).tickFormat(d => d + "%"));

    const parties = ['Republican', 'Democrat'];
    const barWidth = (x(bins[0].x1) - x(bins[0].x0)) / parties.length;

    parties.forEach((party, i) => {
      svg.selectAll(`.bar-${party}`)
        .data(bins)
        .enter()
        .append("rect")
        .attr("class", `bar-${party}`)
        .attr("x", d => x(d.x0) + i * barWidth)
        .attr("width", barWidth - 1)
        .attr("y", d => {
          const yValue = y(d.percentages[party] || 0);
          return isNaN(yValue) ? chartHeight : yValue;
        })
        .attr("height", d => {
          const height = chartHeight - y(d.percentages[party] || 0);
          return isNaN(height) || height < 0 ? 0 : height;
        })
        .attr("fill", colorScale(party))
        .attr("rx", 3)
        .attr("ry", 3);
    });

    // Add labels
    svg.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 35)
      .attr("text-anchor", "middle")
      .text("Effectiveness Score");

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .attr("text-anchor", "middle")
      .text("Percentage within Party");

    // Add legend
    const legend = svg.append("g")
      .attr("transform", `translate(${chartWidth - 100}, 0)`);

    parties.forEach((party, i) => {
      const legendRow = legend.append("g")
        .attr("transform", `translate(0, ${i * 20})`);
      
      legendRow.append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", colorScale(party))
        .attr("rx", 2)
        .attr("ry", 2);
      
      legendRow.append("text")
        .attr("x", 20)
        .attr("y", 10)
        .attr("text-anchor", "start")
        .style("text-transform", "capitalize")
        .text(party);
    });
  };

  return (
    <div className="representative-effectiveness" ref={containerRef}>
      <div className="chart-description">
        <div className="text">Explore the effectiveness of representatives based on their ability to get things done. Effectiveness is based on the percent of their sponsored legislation that passes.</div>
      </div>
      <div className="filters">
        <select value={selectedParty} onChange={(e) => setSelectedParty(e.target.value)}>
          <option value="All">All Parties</option>
          <option value="Democrat">Democrat</option>
          <option value="Republican">Republican</option>
          {/* Removed Independent option */}
        </select>
        <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
          <option value="All">All Types</option>
          <option value="sen">Senator</option>
          <option value="rep">Representative</option>
        </select>
        <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
          <option value="mostEffective">Most Effective</option>
          <option value="leastEffective">Least Effective</option>
        </select>
      </div>
      <div className="top-ten-chart" ref={topTenChartRef}></div>
      {data.length > 0 ? (
        <div className="histogram" ref={histogramRef}></div>
      ) : (
        <p>No data available for histogram</p>
      )}
    </div>
  );
};