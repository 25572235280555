import React from 'react';
import { Banner } from '../../components/Banner/Banner';

import './style.css'; // Create this CSS file to style the HomePage

export const TermsOfService = () => {
  return (
    <div className="terms-of-service-page-wrapper">
      <Banner title="VoteSee Terms of Service" />
      <div className="terms-of-service-page-main">
        <div className="title-wrap">
          <div className="title">Last Updated: 8/5/2024 </div>
          <div className="title">Introduction</div>
          <div className="text">
            PLEASE READ THIS AGREEMENT CAREFULLY. BY ACCESSING OR USING THE SITE OR OTHERWISE AGREEING TO THIS AGREEMENT, YOU UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT AND RECOGNIZE THAT YOU MAY BE WAIVING CERTAIN RIGHTS. 
            Welcome to VoteSee. This Terms of Use Agreement (“Agreement”) is a legally binding agreement between you and VoteSee (“Company,” “We,” “Us,” or “Our”) and governs your access to and use of our website and other online services or applications that link to this Agreement (“Site”). By continuing to access and use the Site, you agree that such use is legally sufficient consideration under this Agreement.
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">User Accounts</div>
          <div className="text">
            <ul>
              <li><strong>Registration:</strong> To access certain features of VoteSee, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process. We may reject, and you may not use, a user ID (or e-mail address) for any reason in our sole discretion.</li>
              <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</li>
              <li><strong>Duplicate Accounts:</strong> You may not create more than one account. Duplicate accounts will be subject to suspension or termination.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Acceptable Use</div>
          <div className="text">
            <ul>
              <li><strong>Non-Commercial Use:</strong> You agree to use VoteSee solely for personal, non-commercial purposes. You may not use our data or services for any commercial purpose without our express written consent.</li>
              <li><strong>Prohibited Activities:</strong> While using the Site, you are required to comply with all applicable statutes, orders, regulations, rules, and other laws. You may not use the Site for any fraudulent or unlawful purpose, and you may not take any action to interfere with the Site or any other party’s use of the Site. In addition, we expect users of the Site to respect the rights and dignity of others. For example, you may not do any of the following without our consent:
                <ul>
                  <li style={{ marginLeft: '20px' }}>Reproduce, duplicate, copy, publicly display, frame, mirror, sell, resell, or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Site;</li>
                  <li style={{ marginLeft: '20px' }}>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the Site, or express or imply that we endorse any statement you make; </li>
                  <li style={{ marginLeft: '20px' }}>Violate, or attempt to violate, the security of the Site; </li>
                  <li style={{ marginLeft: '20px' }}>Disseminate on the Site any viruses, worms, spyware, adware, or other malicious computer code, file, or program that is harmful or invasive or is intended to damage or hijack the operation of, or monitor the use of, any hardware, software, or equipment; </li>
                  <li style={{ marginLeft: '20px' }}>Reverse engineer, disassemble, decompile, or otherwise attempt to derive the method of operation of the Site; </li>
                  <li style={{ marginLeft: '20px' }}>Build a competitive product or service using the Site, build a product or service using similar ideas, features, functions, or graphics as the Site or determine whether the Site is within the scope of any patent;</li>
                  <li style={{ marginLeft: '20px' }}>Interfere in any manner with the operation or hosting of the Site or monitor the availability, performance, or functionality of the Services; </li>
                  <li style={{ marginLeft: '20px' }}>Use any data mining, bots, spiders, automated tools, or similar data gathering and extraction methods, directly or indirectly, on the Services or to collect any information from the Site or any other user of the Site; or </li>
                  <li style={{ marginLeft: '20px' }}>Assist or permit any persons in violating this Agreement or other applicable laws or rules governing the use of the Site. </li>
                  <li style={{ marginLeft: '20px' }}>Linking: You are granted a limited, non-exclusive right to create text hyperlinks to the Site for informational purposes, provided such links do not portray us in a false, misleading, derogatory or otherwise defamatory manner and provided that the linking website or service does not contain any material that is unlawful, harmful, harassing, defamatory, threatening, intimidating, fraudulent, tortious, vulgar, obscene, hateful, pornographic, spam, discriminatory, violative of privacy or publicity rights, infringing of intellectual property or other proprietary rights, or otherwise objectionable in our sole discretion, including unauthorized or unsolicited advertising. Additionally, notwithstanding the foregoing, and subject to compliance with any instructions posted in the robots.txt file located in a website’s root directory, we grant to the operators of public search engines permission to use spiders to copy Content from the Site for the sole purpose of (and solely to the extent necessary for) creating publicly available, searchable indices of such Content, but not caches or archives of such Content, or for any machine-learning or training data purposes. We may revoke these permissions at any time.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Third-Party Content</div>
          <div className="text">
            <ul>
              <li><strong>Third-Party Information:</strong> Any information, statements, opinions, or other information provided by third-parties and made available on the Site are those of the respective author(s) and not us. We do not guarantee the validity, accuracy, completeness or reliability of any opinion, advice, service, offer, statement, or other third-party content on the Site.</li>
              <li><strong>Links:</strong> We may provide on the Site, solely as a convenience to users, links to websites, social media pages, mobile applications, or other services operated by other entities. If you click these links, you will leave the Site. If you decide to visit any external link, you do so at your own risk, and it is your responsibility to take all protective measures to guard against viruses or other destructive elements. We do not make any warranty or representation regarding, or endorse or otherwise sponsor, any linked websites, services, or the information appearing thereon or any of the products or services described thereon. Links do not imply that we are legally authorized to use any trademark, trade name, logo, or copyright symbol displayed in or accessible through the links; or that any linked website or service is authorized to use any of our trademarks, logos, or copyright symbols. </li>            
              <li><strong>Social Media:</strong> We may maintain a presence on and link to social media websites, including Facebook, LinkedIn, Google Plus, X (formerly Twitter), YouTube, Vine, TikTok, Pinterest, and Instagram, and others (collectively, “Social Media Pages”), to provide a place for people to learn more about us. When you visit these Social Media Pages, you are no longer on the Site, but rather a website operated by a third party. All comments, visuals, and other materials posted by visitors to our Social Media Pages do not necessarily reflect our opinions, values, or ideas. All visitors to our Social Media Pages must comply with the respective social media platform’s terms of use. </li>
              YOU AGREE THAT YOUR USE OF THIRD-PARTY WEBSITES, APPLICATIONS, SERVICES AND RESOURCES, INCLUDING WITHOUT LIMITATION YOUR USE OF ANY CONTENT, INFORMATION, DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE THROUGH SUCH THIRD-PARTIES, IS AT YOUR OWN RISK AND IS SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH SITES AND RESOURCES 
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Intellectual Property</div>
          <div className="text">
            <ul>
              <li><strong>Ownership:</strong> All content, features, and functionality on VoteSee, including but not limited to text, graphics, logos, icons, images, and software, are the exclusive property of VoteSee and are protected by copyright, trademark, and other intellectual property laws.</li>
              <li><strong>Limited License:</strong> We grant you a limited, non-exclusive, non-transferable license to access and use VoteSee for personal, non-commercial use. You may not reproduce, distribute, modify, or create derivative works based on our content without our prior written consent.</li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Important Legal Terms</div>
          <div className="text">
            <ul>
              <li><strong>Disclaimer of Warrantys:</strong> WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE, VALIDITY, ACCURACY, OR RELIABILITY OF THE CONTENT AVAILABLE ON THE SERVICES OR ANY OTHER SITES LINKED TO OR FROM THE SITE. DOWNLOADING OR OTHERWISE OBTAINING ANY CONTENT THROUGH THE SERVICES IS DONE AT YOUR OWN RISK. THE SITE AND CONTENT ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT POSSIBLE UNDER APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT. </li>
              <li><strong>Limitation of Liability:</strong> THE COMPANY AND OUR AGENTS, SUPPLIERS, SERVICE PROVIDERS, AND RETAILERS (COLLECTIVELY, THE “RELEASEES”) WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE USE OR THE INABILITY TO USE THE SITE, CONTENT OR EXTERNAL LINKS, INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR RELATED TO ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAY IN OPERATION OR TRANSMISSION, OR ANY COMPUTER VIRUS OR FAILURE. 

RELEASEES WILL ALSO NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF DATA OR PROFITS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. RELEASEES ALSO SHALL NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ACTS, OMISSIONS OR CONDUCT OF ANY USER OR OTHER THIRD-PARTY. 

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.  

REGARDLESS OF THE PREVIOUS SENTENCES, IF WE ARE FOUND TO BE LIABLE, OUR LIABILITY TO YOU OR TO ANY THIRD-PARTY IS LIMITED TO THE GREATER OF THE ACTUAL TOTAL AMOUNT RECEIVED BY US FROM YOU IN THE SIX (6) MONTHS PRECEDING THE CLAIM, OR THE LOWEST LIABILITY LIMITATION ALLOWED BY APPLICABLE LAW. 
              </li>
              <li><strong>Indemnity:</strong> You agree to indemnify, defend, and hold us and the Releasees and all of our directors, officers, employees, agents, shareholders, successors, assigns, and contractors harmless from and against any and all claims, damages, suits, actions, liabilities, judgments, losses, costs (including without limitation reasonable attorneys’ fees), or other expenses that arise directly or indirectly out of or from (i) your breach of any provision of this Agreement; (ii) your activities in connection with the Services; or (iii) User Content or other information you provide to us through the Site. We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it. </li>
              <li><strong>Consent to Communication:</strong> When you use the Site or send communications to us through the Site, you are communicating with us electronically. You consent to receive electronically any communications related to your use of the Site. We may communicate with you by email or by posting notices on the Site. You agree that all agreements, notices, disclosures, and other communications that are provided to you electronically satisfy any legal requirement that such communications be in writing. All notices from us intended for receipt by you shall be deemed delivered and effective when sent to the email address you provide to us. Please note that by creating an Account or otherwise providing us with your email address, postal address or phone number, you are agreeing that we or our agents may contact you at that address or number in a manner consistent with our Privacy Policy. </li>
              <li><strong>Severability:</strong> If any provision of this Agreement is held to be invalid or unenforceable, it shall be replaced in interpretation by a valid and enforceable term that most closely aligns with the intent of the original provision. If that is not possible, the provision shall be removed, and the rest of the Agreement will be enforceable. </li>
              <li><strong>Assignment:</strong> We may assign this Agreement at any time with or without notice to you. You may not assign or sublicense this Agreement or any of your rights or obligations under this Agreement without our prior written consent. </li>
              <li><strong>Government End Uses:</strong> Products or services provided under this Agreement are designed and intended for use by non-government entities and individuals. Use by or on behalf of a Government End User as defined under 15 C.F.R § 772.1 is strictly prohibited and requires prior written authorization from us. If authorization is granted by us, the authorized Government End User must abide by applicable regulations, including but not limited to regulations in 48 C.F.R Chapter 1 and 41 C.F.R §§ 60-1.4(a), 60-300.5(a), and 60-741.5(a). </li>
            </ul>
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Disputes, Arbitration, and Class Action Waiver</div>
          <div className="text">
            PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT OR TO PURSUE CLAIMS IN A CLASS OR REPRESENTATIVE CAPACITY. 

            ARBITRATION USES A NEUTRAL ARBITRATOR INSTEAD OF A JUDGE OR JURY, ALLOWS FOR MORE LIMITED DISCOVERY THAN IN COURT, AND IS SUBJECT TO VERY LIMITED REVIEW BY COURTS. YOU MAY CHOOSE TO BE REPRESENTED BY A LAWYER IN ARBITRATION OR PROCEED WITHOUT ONE. THIS ARBITRATION PROVISION SHALL SURVIVE TERMINATION OF THIS AGREEMENT. IF, HOWEVER, EITHER THE CLASS ACTION WAIVER OR COORDINATED CLAIMS PROVISION BELOW ARE FOUND INVALID, THEN THE SPECIFIC INVALID PROVISION WILL BE UNENFORCEABLE AND WILL BE SEVERED AND THE REMAINDER OF THE ARBITRATION PROVISIONS WILL REMAIN IN FULL FORCE.  

            Agreement to Arbitrate: You and Company agree that any dispute, claim or controversy, including those known or unknown that may be later discovered, arising out of or relating to this Agreement, other agreements on the Site, or the Privacy Policy, or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be either determined by binding arbitration in [STATE] before one arbitrator or submitted to small claims court in [COUNTY, STATE]. If the arbitrator finds this location to be unreasonably burdensome to you, a new location may be selected or arbitration may be conducted over the phone, using video conferencing, or similar. You may be entitled to an in-person hearing near your place of residence. Judgment on the award may be entered in any court having jurisdiction. This clause shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction. Any arbitration arising out of or related to this Agreement shall be conducted in accordance with the expedited procedures set forth in the JAMS Comprehensive Arbitration Rules and Procedures as those Rules exist on the effective date of this Agreement, including Rules 16.1 and 16.2 of those Rules. 

            No Class Actions: YOU AGREE THAT ANY CLAIMS OR ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS; YOU AND COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ARBITRATION OR CLASS ACTION. Further, unless both you and Company agree otherwise, the arbitrator may not consolidate more than one person’s claims with your claims and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void. The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim.  

            Seeking Arbitration: If you elect to seek arbitration or file a small claim court action, you must first send to us, by certified mail, a written notice of your claim (“Notice”). The Notice to us must be addressed to: [INSERT ADDRESS]. If we initiate arbitration, we will send a written Notice to an email address you have previously provided to us, if available. We may also use any other means to contact you, including a message in your Account. A Notice, whether sent by you or by us, must (i) describe the nature and basis of the claim or dispute; and (ii) set forth the specific relief sought (“Demand”). If you and we do not reach an agreement to resolve the claim within thirty (30) days after the Notice is received, you or we may commence an arbitration proceeding or file a claim in small claims court. Arbitration forms can be downloaded from www.jamsadr.com. If you are required to pay a filing fee, after we receive Notice that you have commenced arbitration, we will promptly reimburse you for your payment of the filing fee, unless your claim is for greater than US $10,000 or the arbitrator determines the claims are frivolous, in which event you will be responsible for filing fees. 

            Hearing: If your claim is for US $10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic or video hearing, or by an in-person hearing as established by the JAMS Rules. If your claim exceeds US $10,000, the right to a hearing will be determined by the JAMS Rules. In the event that the arbitration will be conducted solely on the basis of submitted documents, the arbitrator’s decision and award will be made and delivered within six (6) months of the selection of the arbitrator, unless extended by the arbitrator. Except as expressly set forth herein, the payment of all filing, administration, and arbitrator fees will be governed by the JAMS Rules. 

            Award: In the event arbitration awards you damages of an amount at least US $100 greater than our last documented settlement offer, we will pay your awarded damages or US $2,500, whichever is greater. 

            Injunctive Relief: Notwithstanding the foregoing, you and Company both agree that you or Company may sue in court to enjoin infringement or other misuse of intellectual property rights or in other scenarios where injunctive relief is appropriate. In the event a court or arbitrator having jurisdiction finds any portion of this Agreement unenforceable, that portion shall not be effective, and the remainder of the Agreement shall remain effective. No waiver, express or implied, by either party of any breach of or default under this Agreement will constitute a continuing waiver of such breach or default or be deemed to be a waiver of any preceding or subsequent breach or default. 

            Confidentiality: You and Company shall maintain the confidential nature of the arbitration proceeding and the Award, including the hearing, except as may be necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in connection with a court application for a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision. 

            Coordinated Proceedings: If twenty-five (25) or more individuals initiate Notices of dispute with us raising similar claims, and counsel for the individuals bringing the claims are the same or are coordinated for these individuals (“Coordinated Claims”), the claims shall proceed in arbitration in a coordinated proceeding. Counsel for the individuals and our counsel shall each select five (5) cases to proceed first in arbitration in a bellwether proceeding (“Test Cases”). The remaining cases shall not be filed in arbitration until the first ten (10) have been resolved. If the parties are unable to resolve the remaining cases after the conclusion of the Test Cases, each side may select another five (5) cases to proceed to arbitration for a second bellwether proceeding. This process may continue until the parties have determined an objective methodology to make an offer to resolve each and every outstanding claim. A court will have authority to enforce this clause and, if necessary, to enjoin the mass filing of arbitration demands against us. Individuals bringing Coordinated Claims shall be responsible for up to US $250 of their filing fees or the maximum permissible under the applicable arbitration rules. All applicable statutes of limitations and defenses based upon the passage of time will be tolled while the Coordinated Proceedings specified in this Section are pending. We will take such action, if any, required to effectuate such tolling. 

            Governing Law and Rules: This Agreement and the rights of the parties hereunder shall be governed by and construed in accordance with the laws of the State of [INSERT DESIRED STATE LAW], exclusive of conflict or choice of law rules. You and we acknowledge that this Agreement evidences a transaction involving interstate commerce. Notwithstanding the provision in the preceding paragraph with respect to applicable substantive law, any arbitration conducted pursuant to the terms of this Agreement shall be governed by the Federal Arbitration Act (9 U.S.C., Secs. 1-16). In any arbitration arising out of or related to this Agreement, the arbitrator is not empowered to award punitive or exemplary damages, except where permitted by statute, and the parties waive any right to recover any such damages. In any arbitration arising out of or related to this Agreement, the arbitrator may not award any incidental, indirect, or consequential damages, including damages for lost profits. The parties adopt and agree to implement the JAMS Optional Arbitration Appeal Procedure (as it exists on the effective date of this Agreement) with respect to any final award in an arbitration arising out of or related to this Agreement. 

            Severance of Arbitration Agreement: If the clauses concerning and describing the procedures and obligations related to Coordinated Claims and Test Case procedures is or becomes invalid or unenforceable, then the remaining entire arbitration agreement and any clauses concerning, relating to, specifying, or otherwise describing the arbitration agreement shall be severed from this Agreement. However, any duty of confidentiality whether or not such duty is connected with arbitration shall survive such severance.  
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Termination</div>
          <div className="text">
            We reserve the right to suspend or terminate your account and access to the Site at our sole discretion, without notice, for conduct that we believe violates these Terms of Use or is harmful to other users, us, or third parties, or for any other reason.
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Changes to These Terms</div>
          <div className="text">
            We may update these Terms of Use from time to time. We will use reasonable efforts to notify you of such changes. However, please check the “Last Updated” legend at the top of this page to see when this Agreement was last revised. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms of Use.
          </div>
        </div>
        <div className="title-wrap">
          <div className="title">Contact Us</div>
          <div className="text">
            If you have any questions or concerns about these Terms of Use, please contact us at:
            <br />
            <strong>VoteSee Support Team</strong>
            <br />
            votesee.team@gmail.com
          </div>
        </div>
        <div className="title-wrap">
          <div className="text">
            By using VoteSee, you agree to be bound by these Terms of Use.
            <br />
            Thank you for using VoteSee!
          </div>
        </div>
      </div>
    </div>
  );
};
