import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { RepresentativeBanner } from '../../components/RepresentativeBanner/RepresentativeBanner';
import { IssueGallery } from '../../components/IssueGallery/IssueGallery';
import { fetchWikipediaImage } from '../../utils/utils';
import { RepGallery } from '../../components/RepGallery/RepGallery';
import { Helmet } from 'react-helmet';
import { getAuth } from "firebase/auth";
import './style.css';

export const RepPage = ({ email }) => {
  const [issuesData, setIssuesData] = useState([]);
  const [repImageUrl, setRepImageUrl] = useState('');
  const [repDetails, setRepDetails] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rep, setRep] = useState(null);
  const { politicianId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const pageRef = useRef(null);
  const [allIssues, setAllIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const auth = getAuth();

  useEffect(() => {
    const fetchRepData = async () => {
      if (!politicianId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(`${window.apiUrl}/politicians?politician_id=${politicianId}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const repData = await response.json();
        const politician = repData.length > 0 ? repData[0] : null;

        if (politician) {
          setRep(politician);
          setRepDetails(politician);
          const imageUrl = await fetchWikipediaImage(politician.id_wikipedia) || 
                          await fetchWikipediaImage(politician.display_name) || 
                          'https://picsum.photos/1920/609';
          setRepImageUrl(imageUrl);

          // Fetch issues data
          const issuesResponse = await fetch(`${window.apiUrl}/politicianscore?politician_id=${politicianId}`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });

          if (!issuesResponse.ok) {
            throw new Error(`HTTP error! status: ${issuesResponse.status}`);
          }

          const issues = await issuesResponse.json();

          // Transform the data to match the working structure
          const nestedIssuesData = [{
            politician_id: politicianId,
            display_name: politician.display_name,
            party: politician.party,
            image: imageUrl,
            scores: issues.map(score => ({
              topic_name: score.group_name,  // Use the existing group_name
              ...score,
              average_score: score.average_score,
              bill_count: score.bill_count,
              year: score.year
            }))
          }];

          setIssuesData(nestedIssuesData);
        } else {
          setError('Representative not found');
        }
      } catch (error) {
        setError(error.message || 'Failed to fetch representative data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRepData();
  }, [politicianId]);

  useEffect(() => {
    // Get the current user's email
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail('');
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchUserIssues = async () => {
      if (!email) return;

      try {
        const response = await fetch(`${window.apiUrl}/userscores?email=${email}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        
        // Sort the issues by rank
        const sortedIssues = data.sort((a, b) => a.rank - b.rank);
        setAllIssues(sortedIssues);

        // Filter favorite issues
        const favoriteIssues = sortedIssues
          .filter(issue => issue.favorite && issue.favorite.data && issue.favorite.data[0] === 1)
          .map(issue => issue.group_name);
        setSelectedIssues(favoriteIssues);
      } catch (error) {
        // Handle error
      }
    };

    fetchUserIssues();
  }, [email]); // Changed dependency from [] to [email]

  const handleCardSelect = useCallback((selectedRep) => {
    history.replace(`/rep/${selectedRep.politician_id}`);
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history]);

  const toggleIssue = async (issue) => {
    // Get email from localStorage instead of userEmail state
    const email = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).email : null;
    if (!email) return;

    const updatedSelectedIssues = selectedIssues.includes(issue)
      ? selectedIssues.filter(item => item !== issue)
      : [...selectedIssues, issue];

    setSelectedIssues(updatedSelectedIssues);

    try {
      const response = await fetch(`${window.apiUrl}/userscores`, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          group_name: issue,
          favorite: updatedSelectedIssues.includes(issue) ? 1 : 0,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update favorite status');
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  if (loading) {
    return <div className="loading-icon" aria-live="polite" aria-busy="true"><div className="loading-spinner"></div></div>;
  }

  if (error) {
    return <div className="error" role="alert">{error}</div>;
  }

  if (!rep) {
    return <div className="error" role="alert">Representative not found</div>;
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": rep.display_name,
    "jobTitle": repDetails.district ? "Representative" : "Senator",
    "affiliation": repDetails.party,
    "memberOf": {
      "@type": "Organization",
      "name": "United States Congress"
    },
    "image": repImageUrl,
    "url": `https://votesee.org/rep/${politicianId}`
  };

  const pageTitle = `${rep.display_name} - Political Stance and Voting Record | VoteSee`;
  const pageDescription = `Explore ${rep.display_name}'s political stance, voting record, and effectiveness as a ${repDetails.district ? "Representative" : "Senator"} for ${rep.state}. Get informed about key issues and policy positions on VoteSee.`;

  return (
    <div className="rep-page" ref={pageRef}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={repImageUrl} />
        <meta property="og:url" content={`https://votesee.org/rep/${politicianId}`} />
        <meta property="og:type" content="profile" />
        <link rel="canonical" href={`https://votesee.org/rep/${politicianId}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={repImageUrl} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <RepresentativeBanner rep={rep} imageUrl={repImageUrl} details={repDetails} />
      <div className="rep-page-wrap">
        <div className="title-wrap">
          <h2 className="title">Issues</h2>
          <p className="text">{`See where ${rep.display_name} stands on the issues.`}</p>
        </div>
        <IssueGallery 
          className="issue-gallery" 
          issuesData={issuesData} 
          party={repDetails.party} 
          repImageUrl={repImageUrl}
          isCompareView={false}
          showFilter={true}
          selectedIssues={selectedIssues}
          toggleIssue={toggleIssue}
          allIssues={allIssues}
        />
        <div className="similarreps">
          <RepGallery 
            title={`Representatives Similar to ${rep.display_name}`}
            description={`See representatives that align most with ${rep.display_name}`}
            dataType="similar-rep" 
            item={politicianId} 
            refreshGallery="" 
            onCardSelect={handleCardSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default RepPage;
