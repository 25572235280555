// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header {
    background-color: var(--neutral-0);
    border-bottom: 1px solid var(--neutral-2); /* Ensure the border is visible */
    height: 54px;
    width: 243px; /* Full width of the overlay */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
}

.close-icon {
    cursor: pointer;
    height: 20px; /* Ensure the height matches the intended size */
    width: 20px; /* Ensure the width matches the intended size */
    align-self: center; /* Centering vertically */
}
`, "",{"version":3,"sources":["webpack://./src/components/MobileHeader/style.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,yCAAyC,EAAE,iCAAiC;IAC5E,YAAY;IACZ,YAAY,EAAE,8BAA8B;IAC5C,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY,EAAE,gDAAgD;IAC9D,WAAW,EAAE,+CAA+C;IAC5D,kBAAkB,EAAE,yBAAyB;AACjD","sourcesContent":[".mobile-header {\n    background-color: var(--neutral-0);\n    border-bottom: 1px solid var(--neutral-2); /* Ensure the border is visible */\n    height: 54px;\n    width: 243px; /* Full width of the overlay */\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    padding-right: 16px;\n}\n\n.close-icon {\n    cursor: pointer;\n    height: 20px; /* Ensure the height matches the intended size */\n    width: 20px; /* Ensure the width matches the intended size */\n    align-self: center; /* Centering vertically */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
