import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { NavBar } from './components/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';
import './index.css';
import { FindARep } from './pages/FindARep/FindARep';
import { CompareReps } from './pages/CompareReps/CompareReps';
import { HomePage } from './pages/HomePage/HomePage';
import { Dev } from './pages/Dev/Dev';
import { Login } from './pages/Login/Login';
import { Signup } from './pages/Signup/Signup';
import { Profile } from './pages/Profile/Profile';
import { PickYourViews } from './pages/PickYourViews/PickYourViews';
import { RepPage } from './pages/RepPage/RepPage';
import { About } from './pages/About/About';
import { Quiz } from './pages/Quiz/Quiz';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService/TermsOfService';
import './styleguide.css';
import './utils/firebase'; // Ensure this import is present to initialize Firebase
import { Analytics } from './pages/Analytics/Analytics';
import { Blog } from './pages/Blog/Blog';
import { useDynamicMetaTags } from './hooks/useDynamicMetaTags';
import { createOrGetUser } from './utils/auth';

const AppContent = () => {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    const initUser = async () => {
      try {
        const user = await createOrGetUser();
        setEmail(user.email);
      } catch (error) {
        console.error('Error initializing user:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initUser();
  }, []);

  const hideHeaderFooterRoutes = ['/landing-page'];
  const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {!shouldHideHeaderFooter && <NavBar />}
      <main className="main-content">
        <Switch>
          <Route path="/rep/:politicianId" render={(props) => <RepPage {...props} email={email} />} />
          <Route path="/compare-reps" render={(props) => <CompareReps {...props} email={email} />} />
          <Route path="/find-a-rep" exact render={() => <FindARep email={email} />} />
          <Route path="/pick-your-views" exact render={() => <PickYourViews email={email} />} />
          <Route path="/dev" exact component={Dev} />
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/quiz" exact component={Quiz} />
          <Route path="/analytics" exact component={Analytics} />
          <Route path="/analytics/:chartType" component={Analytics} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/" exact component={HomePage} />
          <Route path="/about" exact component={About} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-of-service" exact component={TermsOfService} />
        </Switch>
      </main>
      {!shouldHideHeaderFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
