// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signin-container {
  width: 100%;
  max-width: 1512px;
  height: 800px;


  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Arrange content vertically */
  overflow: hidden; /* Hide any overflowing content */
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;;;EAGb,uBAAuB,EAAE,gCAAgC;EACzD,mBAAmB,EAAE,8BAA8B;EACnD,sBAAsB,EAAE,+BAA+B;EACvD,gBAAgB,EAAE,iCAAiC;AACrD","sourcesContent":[".signin-container {\n  width: 100%;\n  max-width: 1512px;\n  height: 800px;\n\n\n  justify-content: center; /* Center content horizontally */\n  align-items: center; /* Center content vertically */\n  flex-direction: column; /* Arrange content vertically */\n  overflow: hidden; /* Hide any overflowing content */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
