import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import { fetchWikipediaImage } from '../../utils/utils';
import './style.css';

export const MajorityAlignment = () => {
  const [data, setData] = useState([]);
  const [topFifty, setTopFifty] = useState([]);
  const [selectedParty, setSelectedParty] = useState('All');
  const [selectedType, setSelectedType] = useState('sen');  // Changed default to 'sen'
  const [sortOrder, setSortOrder] = useState('mostAligned');
  const topTenChartRef = useRef(null);
  const histogramRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [displayCount, setDisplayCount] = useState(100);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const resizeTimeout = useRef(null);

  const handleResize = useCallback(() => {
    if (resizeTimeout.current) {
      clearTimeout(resizeTimeout.current);
    }
    resizeTimeout.current = setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 300);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, [handleResize]);

  useEffect(() => {
    fetchData();
    setDisplayCount(100);  // Reset display count when filters change
  }, [selectedParty, selectedType, sortOrder]);  // Added sortOrder to dependencies

  const fetchData = async () => {
    try {
      const params = new URLSearchParams();
      if (selectedParty !== 'All') params.append('party', selectedParty);
      if (selectedType !== 'All') params.append('type', selectedType);

      const response = await fetch(`${window.apiUrl}/majoritydirectionalvote?${params}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch data');
      const fetchedData = await response.json();
      
      setData(fetchedData);  // Remove the filter
      updateTopFifty(fetchedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateTopFifty = (allData) => {
    let sorted;
    switch (sortOrder) {
      case 'mostAligned':
        sorted = allData.sort((a, b) => b.withparty_rate - a.withparty_rate);
        break;
      case 'leastAligned':
        sorted = allData.sort((a, b) => a.withparty_rate - b.withparty_rate);
        break;
      default:
        sorted = allData.sort((a, b) => b.withparty_rate - a.withparty_rate);
    }
    setTopFifty(sorted.slice(0, 550));
  };

  useEffect(() => {
    updateTopFifty(data);
  }, [sortOrder]);

  useEffect(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      setDimensions({
        width: width,
        height: Math.max(400, width * 0.5)
      });
    }
  }, [windowWidth]);

  useEffect(() => {
    if (topFifty.length > 0 && dimensions.width > 0) {
      drawTopFiftyChart();
    }
  }, [topFifty, dimensions, displayCount]);

  useEffect(() => {
    if (data.length > 0 && dimensions.width > 0) {
      drawHistogram();
    }
  }, [data, dimensions]);

  const drawTopFiftyChart = async () => {
    const { width, height } = dimensions;
    const chartHeight = Math.max(height * 2, displayCount * 30);
    const margin = { top: 60, right: 100, bottom: 10, left: 100 }; // Increased top margin

    d3.select(topTenChartRef.current).selectAll("*").remove();

    const svg = d3.select(topTenChartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", chartHeight)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const chartWidth = width - margin.left - margin.right;
    const adjustedChartHeight = chartHeight - margin.top - margin.bottom;

    const buffer = 0.1; // Increased buffer to make bars skinnier

    const x = d3.scaleLinear()
      .domain([0, 1])
      .range([chartWidth / 2, buffer * chartWidth]);

    const xRight = d3.scaleLinear()
      .domain([0, 1])
      .range([chartWidth / 2, chartWidth - buffer * chartWidth]);

    const y = d3.scaleBand()
      .domain(topFifty.slice(0, displayCount).map(d => d.display_name))
      .range([0, adjustedChartHeight])
      .padding(0.2);  // Increased padding slightly to create some space between bars

    // Draw bars for Democrats and Independents (left side)
    svg.selectAll(".bar-left")
      .data(topFifty.slice(0, displayCount).filter(d => d.party === 'Democrat' || d.party === 'Independent'))
      .enter()
      .append("rect")
      .attr("class", "bar-left")
      .attr("x", d => x(Number(d.withparty_rate) || 0))
      .attr("y", d => y(d.display_name) + (y.bandwidth() - 15) / 2)
      .attr("width", d => chartWidth / 2 - x(Number(d.withparty_rate) || 0))
      .attr("height", 15)
      .attr("fill", d => d.party === 'Democrat' ? '#99ccff' : '#cccccc')  // Gray for Independents
      .attr("rx", 3)
      .attr("ry", 3);

    // Draw bars for Republicans (right side)
    svg.selectAll(".bar-right")
      .data(topFifty.slice(0, displayCount).filter(d => d.party === 'Republican'))
      .enter()
      .append("rect")
      .attr("class", "bar-right")
      .attr("x", chartWidth / 2)
      .attr("y", d => y(d.display_name) + (y.bandwidth() - 15) / 2)  // Center the 15px tall bar
      .attr("width", d => xRight(Number(d.withparty_rate) || 0) - chartWidth / 2)
      .attr("height", 15)  // Set height to 15px
      .attr("fill", '#ff9999')
      .attr("rx", 3)
      .attr("ry", 3);

    // Add center line
    svg.append("line")
      .attr("x1", chartWidth / 2)
      .attr("x2", chartWidth / 2)
      .attr("y1", 0)
      .attr("y2", adjustedChartHeight)
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Reduce the image size further
    const imageSize = 21; // Reduced to 21px to account for 2px border on each side
    const borderWidth = 2;
    const totalImageSize = imageSize + (borderWidth * 2); // This will be 25px
    const labelOffset = 5; // Reduced offset for closer positioning

    const isMobile = width < 768; // Define a breakpoint for mobile devices
    const desktopLabelMargin = 12; // Increased margin for desktop view

    topFifty.slice(0, displayCount).forEach(async (d, i) => {
      const rateValue = Number(d.withparty_rate);
      const label = isNaN(rateValue) ? 'N/A' : Math.round(rateValue * 100) + '%';
      
      const barEnd = d.party === 'Republican' ? xRight(rateValue) : x(rateValue);
      
      // Add representative image
      const imageUrl = await fetchWikipediaImage(d.id_wikipedia);
      if (imageUrl) {
        const imageX = d.party === 'Republican' ? barEnd + totalImageSize / 2 : barEnd - totalImageSize / 2;
        const imageGroup = svg.append("g")
          .attr("transform", `translate(${imageX}, ${y(d.display_name) + y.bandwidth() / 2})`)
          .on("click", () => window.open(`/rep/${d.politician_id}`, "_blank"));

        imageGroup.append("circle")
          .attr("r", totalImageSize / 2)
          .attr("fill", d.party === 'Republican' ? 'rgb(123, 6, 30)' : (d.party === 'Democrat' ? 'rgb(69, 139, 234)' : 'rgb(128, 128, 128)'));  // Gray for Independents

        imageGroup.append("clipPath")
          .attr("id", `clip-${d.politician_id}`)
          .append("circle")
          .attr("r", imageSize / 2);

        imageGroup.append("image")
          .attr("xlink:href", imageUrl)
          .attr("x", -imageSize * 1.5 / 2)  // Center horizontally
          .attr("y", -imageSize * 1.5 / 2)  // Start from the top
          .attr("width", imageSize * 1.5)   // Zoom in by 1.5x
          .attr("height", imageSize * 1.5)  // Zoom in by 1.5x
          .attr("clip-path", `url(#clip-${d.politician_id})`)
          .attr("preserveAspectRatio", "xMidYMin slice"); // This will crop from the bottom
      }

      // Add percentage label
      const percentageGroup = svg.append("g")
        .attr("class", "percentage-group")
        .attr("transform", `translate(
          ${d.party === 'Republican' ? barEnd + totalImageSize + labelOffset : barEnd - totalImageSize - labelOffset}, 
          ${y(d.display_name) + y.bandwidth() / 2}
        )`);

      const pillWidth = 36;
      const pillHeight = 14;
      const pillRadius = 3;

      percentageGroup.append("rect")
        .attr("x", d.party === 'Republican' ? 0 : -pillWidth)
        .attr("y", -pillHeight / 2)
        .attr("width", pillWidth)
        .attr("height", pillHeight)
        .attr("fill", "white")
        .attr("stroke", d.party === 'Republican' ? '#ff9999' : (d.party === 'Democrat' ? '#99ccff' : '#cccccc'))
        .attr("stroke-width", 2)
        .attr("rx", pillRadius)
        .attr("ry", pillRadius);

      percentageGroup.append("text")
        .attr("class", "percentage-label")
        .attr("x", d.party === 'Republican' ? pillWidth / 2 : -pillWidth / 2)
        .attr("y", 0)
        .attr("dy", "0.32em")
        .text(label)
        .attr("fill", "black")
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("font-weight", "bold");

      // Update name label position
      const nameLabel = svg.append("g")
        .attr("class", "name-label-group")
        .attr("transform", `translate(
          ${isMobile 
            ? (d.party === 'Republican' ? chartWidth / 2 - 5 : chartWidth / 2 + 5)
            : (d.party === 'Republican' ? barEnd + totalImageSize + labelOffset * 8 + desktopLabelMargin : barEnd - totalImageSize - labelOffset * 8 - desktopLabelMargin)
          }, 
          ${y(d.display_name) + y.bandwidth() / 2}
        )`);

      nameLabel.append("text")
        .attr("class", "name-label")
        .attr("x", 0)
        .attr("dy", "0.35em")
        .text(d.display_name)
        .attr("fill", "black")
        .attr("text-anchor", isMobile 
          ? (d.party === 'Republican' ? "end" : "start")
          : (d.party === 'Republican' ? "start" : "end")
        )
        .style("font-size", isMobile ? "10px" : "12px")
        .style("font-weight", "bold");
    });

    // Add Democrat label on center-left
    svg.append("text")
      .attr("x", isMobile ? chartWidth * 0.2 : chartWidth * 0.25)  // Adjusted for mobile
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .text("Democrat")
      .attr("fill", "#99ccff")
      .style("font-size", isMobile ? "14px" : "16px")
      .style("font-weight", "bold");

    // Add Republican label on center-right
    svg.append("text")
      .attr("x", isMobile ? chartWidth * 0.8 : chartWidth * 0.75)  // Adjusted for mobile
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .text("Republican")
      .attr("fill", "#ff9999")
      .style("font-size", isMobile ? "14px" : "16px")
      .style("font-weight", "bold");

    // Add title for the top chart
    svg.append("text")
      .attr("x", chartWidth / 2)  // Centered
      .attr("y", -45)
      .attr("text-anchor", "middle")
      .text("Percent of Votes with the Party Line")
      .style("font-size", "18px")
      .style("font-weight", "bold");
  };

  const drawHistogram = () => {
    if (data.length === 0) {
      console.warn('No data available for histogram');
      return;
    }

    const { width, height } = dimensions;
    const margin = { top: 40, right: 120, bottom: 80, left: 80 }; // Increased bottom margin

    const svg = d3.select(histogramRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom) // Increased SVG height
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const x = d3.scaleLinear()
      .domain([0, 1])
      .range([0, chartWidth]);

    const histogram = d3.histogram()
      .value(d => Number(d.withparty_rate) || 0)
      .domain(x.domain())
      .thresholds(x.ticks(20));

    const bins = histogram(data);

    // Calculate percentages and find max percentage
    const partyCounts = { 'Republican': 0, 'Democrat': 0 };
    data.forEach(d => {
      if (d.party in partyCounts) {
        partyCounts[d.party]++;
      }
    });

    let maxPercentage = 0;
    bins.forEach(bin => {
      bin.percentages = { 'Republican': 0, 'Democrat': 0 };
      ['Republican', 'Democrat'].forEach(party => {
        const count = bin.filter(d => d.party === party).length;
        const percentage = partyCounts[party] > 0 ? (count / partyCounts[party]) * 100 : 0;
        bin.percentages[party] = percentage;
        maxPercentage = Math.max(maxPercentage, percentage);
      });
    });

    const y = d3.scaleLinear()
      .domain([0, maxPercentage])
      .range([chartHeight, 0]);

    const colorScale = d3.scaleOrdinal()
      .domain(['Republican', 'Democrat'])
      .range(['#ff9999', '#99ccff']);

    // Adjust x-axis position
    svg.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).ticks(10).tickFormat(d3.format(".0%")));

    // Adjust x-axis label position
    svg.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40) // Adjusted y-position
      .attr("text-anchor", "middle")
      .text("Majority Alignment Score");

    svg.append("g")
      .attr("transform", `translate(0,0)`)
      .call(d3.axisLeft(y).ticks(5).tickFormat(d => d + "%"));

    const parties = ['Republican', 'Democrat'];
    const barWidth = ((x(bins[0].x1) - x(bins[0].x0)) / parties.length) * 0.9; // Made bars slightly thinner

    parties.forEach((party, i) => {
      svg.selectAll(`.bar-${party}`)
        .data(bins)
        .enter()
        .append("rect")
        .attr("class", `bar-${party}`)
        .attr("x", d => x(d.x0) + i * barWidth + (barWidth * 0.1) / 2) // Centered the thinner bars
        .attr("width", barWidth)
        .attr("y", d => y(d.percentages[party]))
        .attr("height", d => y(0) - y(d.percentages[party]))
        .attr("fill", colorScale(party))
        .attr("rx", 3)
        .attr("ry", 3);
    });

    // Add title for the histogram
    svg.append("text")
      .attr("x", 0)  // Changed from chartWidth / 2
      .attr("y", -20)
      .attr("text-anchor", "start")  // Changed from "middle"
      .text("Histogram of Party Alignment")
      .style("font-size", "18px")
      .style("font-weight", "bold");

    // Update the bars to have rounded corners
    parties.forEach((party, i) => {
      svg.selectAll(`.bar-${party}`)
        .data(bins)
        .enter()
        .append("rect")
        .attr("class", `bar-${party}`)
        .attr("x", d => x(d.x0) + i * barWidth)
        .attr("width", barWidth - 1)
        .attr("y", d => y(d.percentages[party]))
        .attr("height", d => y(0) - y(d.percentages[party]))
        .attr("fill", colorScale(party))
        .attr("rx", 3)  // Add rounded corners
        .attr("ry", 3); // Add rounded corners
    });

    // Update axis styles
    svg.selectAll(".axis")
      .selectAll("line")
      .style("stroke", "#ccc");

    svg.selectAll(".axis")
      .selectAll("path")
      .style("stroke", "#ccc");

    svg.selectAll(".axis")
      .selectAll("text")
      .style("fill", "#666")
      .style("font-size", "12px");

    // Add labels
    svg.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .attr("text-anchor", "middle")
      .text("Majority Alignment Score");

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .attr("text-anchor", "middle")
      .text("Percentage within Party");

    // Add legend
    const legend = svg.append("g")
      .attr("transform", `translate(10, 10)`);  // Position at top-left

    parties.forEach((party, i) => {
      const legendRow = legend.append("g")
        .attr("transform", `translate(0, ${i * 20})`);
      
      legendRow.append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", colorScale(party))
        .attr("rx", 2)
        .attr("ry", 2);
      
      legendRow.append("text")
        .attr("x", 20)
        .attr("y", 10)
        .attr("text-anchor", "start")
        .style("text-transform", "capitalize")
        .text(party);
    });
  };

  const handleShowAll = () => {
    setDisplayCount(topFifty.length);
  };

  return (
    <div className="majority-alignment" ref={containerRef}>
      <h2>Majority Alignment</h2>
      <div className="chart-description">
        <div className="text">
          Explore how closely members of Congress align with their party's majority voting patterns. 
          This visualization shows the percentage of votes where each representative or senator voted 
          with their party's majority, offering a measure of party loyalty.
        </div>
      </div>
      <div className="filters">
        <select value={selectedParty} onChange={(e) => setSelectedParty(e.target.value)}>
          <option value="All">All Parties</option>
          <option value="Democrat">Democrat</option>
          <option value="Republican">Republican</option>
        </select>
        <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
          <option value="All">All Types</option>
          <option value="sen">Senator</option>
          <option value="rep">Representative</option>
        </select>
        <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
          <option value="mostAligned">Most Aligned</option>
          <option value="leastAligned">Least Aligned</option>
        </select>
      </div>
      <div className="top-fifty-chart" ref={topTenChartRef}></div>
      {displayCount < topFifty.length && (
        <button className="show-all-button" onClick={handleShowAll}>Show All</button>
      )}
      {data.length > 0 ? (
        <div className="histogram" ref={histogramRef}></div>
      ) : (
        <p>No data available for histogram</p>
      )}
    </div>
  );
};
