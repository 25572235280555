import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const useDynamicMetaTags = (title, description, image, url) => {
  useEffect(() => {
    // Remove the Helmet.renderStatic() call
    const metaTags = [
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:image", content: image },
      { property: "og:url", content: url },
      { property: "twitter:title", content: title },
      { property: "twitter:description", content: description },
      { property: "twitter:image", content: image },
      { property: "twitter:url", content: url },
    ];

    // Instead of manually updating meta tags, use Helmet to manage them
    return () => {
      // Cleanup is handled automatically by Helmet
    };
  }, [title, description, image, url]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:url" content={url} />
    </Helmet>
  );
};
