import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Add this import
import { Banner } from '../../components/Banner/Banner';
import ReactQuill from 'react-quill'; // You'll need to install this package
import 'react-quill/dist/quill.snow.css'; // Import styles
import './Blog.css';

export const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: '', content: '' });
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState(null);
  const [editingPost, setEditingPost] = useState(null);

  useEffect(() => {
    fetchPosts();
    checkAdminStatus();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch(`${window.apiUrl}/blogposts`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPosts(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError(`Error fetching posts: ${error.message}`);
      setPosts([]);
    }
  };

  const checkAdminStatus = async () => {
    try {
      const email = localStorage.getItem('userEmail');
      setUserEmail(email);
      if (email) {
        const response = await fetch(`${window.apiUrl}/users?email=${email}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const userData = await response.json();
        
        setIsAdmin(userData.admin === 1);
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      setError(`Error checking admin status: ${error.message}`);
      setIsAdmin(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPost(prevPost => ({ ...prevPost, [name]: value }));
  };

  const handleContentChange = (content) => {
    setNewPost(prevPost => ({ ...prevPost, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPost.title && newPost.content) {
      try {
        const response = await fetch(`${window.apiUrl}/blogposts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY_1,
          },
          body: JSON.stringify({ ...newPost, email: userEmail }),
        });
        if (response.ok) {
          setNewPost({ title: '', content: '' });
          fetchPosts();
        } else {
          const errorText = await response.text();
          console.error('Failed to create post:', errorText);
        }
      } catch (error) {
        console.error('Error creating post:', error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${window.apiUrl}/blogposts/${id}?email=${userEmail}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
        },
      });
      if (response.ok) {
        fetchPosts();
      } else {
        const errorText = await response.text();
        console.error('Failed to delete post:', errorText);
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setNewPost({ title: post.title, content: post.content });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (newPost.title && newPost.content) {
      try {
        const response = await fetch(`${window.apiUrl}/blogposts/${editingPost.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY_1,
          },
          body: JSON.stringify({ ...newPost, email: userEmail }),
        });
        if (response.ok) {
          setNewPost({ title: '', content: '' });
          setEditingPost(null);
          fetchPosts();
        } else {
          const errorText = await response.text();
          console.error('Failed to update post:', errorText);
        }
      } catch (error) {
        console.error('Error updating post:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="blog-page-wrapper">
      <Helmet>
        <title>Political Insights Blog | VoteSee</title>
        <meta name="description" content="Stay informed with the latest political insights, analysis, and updates from VoteSee's expert team." />
        <meta name="keywords" content="political blog, political insights, congressional updates, VoteSee" />
      </Helmet>
      <Banner title="VoteSee Blog" />
      <div className="blog-page-main">
        <h1>Political Insights and Updates</h1>
        <p className="blog-intro">Stay informed with our latest political insights and updates.</p>
        {error && <div className="error-message">{error}</div>}
        <div className="blog-container">
          {isAdmin && (
            <form onSubmit={editingPost ? handleUpdate : handleSubmit} className="blog-form">
              <input
                type="text"
                name="title"
                value={newPost.title}
                onChange={handleInputChange}
                placeholder="Enter post title"
                required
              />
              <ReactQuill
                value={newPost.content}
                onChange={handleContentChange}
                placeholder="Enter post content"
              />
              <button type="submit">{editingPost ? 'Update Post' : 'Add Post'}</button>
              {editingPost && (
                <button type="button" onClick={() => setEditingPost(null)} className="cancel-btn">
                  Cancel
                </button>
              )}
            </form>
          )}
          <div className="blog-posts">
            {posts.map(post => (
              <div key={post.id} className="blog-post">
                <h2>{post.title}</h2>
                <p className="post-meta">
                  By {post.author_name} on {formatDate(post.date_created)}
                </p>
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                {isAdmin && (
                  <div className="admin-controls">
                    <button onClick={() => handleEdit(post)} className="edit-btn">
                      Edit
                    </button>
                    <button onClick={() => handleDelete(post.id)} className="delete-btn">
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
