import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { Line } from 'react-chartjs-2';
import userScoreImage from './flag.webp';
import { Slider, Typography, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import RepThumb from '../RepThumb/RepThumb';

ChartJS.register(ArcElement, Tooltip, Legend);

const BillCard = ({ bill, politicians }) => {
  const [showFullSummary, setShowFullSummary] = useState(false);

  if (!bill || bill.relation < 0.4) return null;

  // Group votes by vote_id
  const votes = bill.votes.map(vote => ({
    vote_id: vote.vote_id,
    yea_aye_count: vote.yea_aye_count,
    no_nay_count: vote.no_nay_count,
    not_voting_count: vote.not_voting_count,
    total_votes: vote.total_votes,
    politician_votes: vote.politician_votes,
    most_recent_date: vote.most_recent_date
  }));

  // Parse numeric values
  const score = parseFloat(bill.score) || 0;
  const groupAvgScore = parseFloat(bill.group_avg_score) || 0;
  const groupStdDev = parseFloat(bill.group_std_dev) || 1;

  // Calculate percentile using the error function (erf)
  const erf = (x) => {
    const sign = x >= 0 ? 1 : -1;
    x = Math.abs(x);
    const a1 =  0.254829592;
    const a2 = -0.284496736;
    const a3 =  1.421413741;
    const a4 = -1.453152027;
    const a5 =  1.061405429;
    const p  =  0.3275911;
    const t = 1.0 / (1.0 + p * x);
    const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
    return sign * y;
  };

  // Calculate percentile
  const zScore = (score - groupAvgScore) / groupStdDev;
  const percentile = 100 - (0.5 * (1 + erf(zScore / Math.sqrt(2)))) * 100;

  const doughnutData = {
    labels: ['Yes', 'No', 'Not Voting'],
    datasets: [
      {
        data: [
          parseInt(bill.yea_aye_count) || 0,  // Ensure we're using numbers
          parseInt(bill.no_nay_count) || 0,
          parseInt(bill.not_voting_count) || 0
        ],
        backgroundColor: ['rgba(66, 133, 244, 0.7)', 'rgba(244, 67, 54, 0.7)', 'rgba(158, 158, 158, 0.7)'],
        hoverBackgroundColor: ['rgba(66, 133, 244, 0.9)', 'rgba(244, 67, 54, 0.9)', 'rgba(158, 158, 158, 0.9)'],
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 10,
          font: { size: 10 }
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const count = context.raw;
            const total = bill.total_votes;
            const percentage = ((count / total) * 100).toFixed(1);
            return `${context.label}: ${count} (${percentage}%)`;
          }
        }
      }
    },
    cutout: '70%'
  };

  return (
    <div className="bill-card">
      <div className="bill-header">
        <div className="bill-info">
          <h4>{bill.bill_id}</h4>
        </div>
      </div>

      <div className="bill-content">
        <div className="bill-details">
          <p className="topic-name">{bill.topic_name}</p>
          <div className="title-wrapper">
            <p className="title">{bill.popular_title || bill.official_title}</p>
          </div>
          <div className={`summary ${showFullSummary ? 'expanded' : ''}`}>
            <p>{bill.summary_text}</p>
          </div>
          {bill.summary_text?.length > 300 && (
            <button className="show-more-btn" onClick={() => setShowFullSummary(!showFullSummary)}>
              {showFullSummary ? 'Show less' : 'Show more'}
            </button>
          )}
          
          {/* Add the liberal/conservative meter */}
          <div className="ideology-meter">
            <div className="percentile">
              <div className="percentile-bar">
                <div 
                  className="percentile-marker" 
                  style={{ left: `${percentile}%` }}
                  title={`Score: ${score.toFixed(1)}`}
                ></div>
              </div>
              <div className="percentile-labels">
                <span className="liberal">Liberal</span>
                <span className="conservative">Conservative</span>
              </div>
            </div>
          </div>
        </div>

        <div className="vote-summary">
          {/* Render each vote */}
          {votes.map((vote, index) => (
            <div key={vote.vote_id} className="vote-container">
              <h4>Vote {index + 1} - {new Date(vote.most_recent_date).toLocaleDateString()}</h4>
              <div className="vote-bars">
                <div className="vote-bar-container">
                  <div className="vote-label">
                    <span>Yes</span>
                    <span>{vote.yea_aye_count} ({((parseInt(vote.yea_aye_count) / vote.total_votes) * 100).toFixed(1)}%)</span>
                  </div>
                  <div className="vote-bar-wrapper">
                    <div 
                      className="vote-bar yes" 
                      style={{ width: `${(parseInt(vote.yea_aye_count) / vote.total_votes) * 100}%` }}
                    />
                  </div>
                </div>
                
                <div className="vote-bar-container">
                  <div className="vote-label">
                    <span>No</span>
                    <span>{vote.no_nay_count} ({((parseInt(vote.no_nay_count) / vote.total_votes) * 100).toFixed(1)}%)</span>
                  </div>
                  <div className="vote-bar-wrapper">
                    <div 
                      className="vote-bar no" 
                      style={{ width: `${(parseInt(vote.no_nay_count) / vote.total_votes) * 100}%` }}
                    />
                  </div>
                </div>
                
                <div className="vote-bar-container">
                  <div className="vote-label">
                    <span>Not Voting</span>
                    <span>{vote.not_voting_count} ({((parseInt(vote.not_voting_count) / vote.total_votes) * 100).toFixed(1)}%)</span>
                  </div>
                  <div className="vote-bar-wrapper">
                    <div 
                      className="vote-bar not-voting" 
                      style={{ width: `${(parseInt(vote.not_voting_count) / vote.total_votes) * 100}%` }}
                    />
                  </div>
                </div>
              </div>
              
              {/* Individual politician votes for this specific vote */}
              <div className="politician-votes">
                {Object.entries(vote.politician_votes).map(([politicianId, voteType]) => {
                  const politician = politicians.find(p => p.politician_id === politicianId);
                  if (!politician) return null;

                  return (
                    <div key={politicianId} className="politician-vote">
                      <div className={`vote-indicator ${voteType.toLowerCase()}`}>
                        <img 
                          src={politician.image} 
                          alt={politician.display_name}
                          className="politician-photo"
                        />
                        <span>{politician.display_name}: {voteType}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const IssueBar = ({ 
  head, 
  issues, 
  onPositionChange, 
  onRefreshGallery, 
  isUserScore = false, 
  party,
  defaultExpanded = false // Add this prop
}) => {
  const [expanded, setExpanded] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [subTopics, setSubTopics] = useState([]);
  const [importantBills, setImportantBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [yearRange, setYearRange] = useState([2014, new Date().getFullYear()]);
  const [yearPairs, setYearPairs] = useState([]);
  const [lastAvailableYearPair, setLastAvailableYearPair] = useState(null);
  const [partyAverages, setPartyAverages] = useState({});
  const [hasGrayedOutRep, setHasGrayedOutRep] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({});
  const [callouts, setCallouts] = useState([]);
  const [averagePositions, setAveragePositions] = useState({});
  const [processedData, setProcessedData] = useState({});

  const sliderRef = useRef(null);
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  // Add useEffect to handle initial expansion
  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(true);
      setActiveSection('stats');
      if (yearRange) {
        fetchGraphData(yearRange);
        fetchAverageScores(yearRange);
      }
    }
  }, [defaultExpanded, yearRange]); // Add yearRange to dependencies

  useEffect(() => {
    if (!isUserScore) {
      const calculateAveragePositions = () => {
        const averages = {};
        
        // Guard against non-array issues
        if (!Array.isArray(issues)) {
          console.warn('Issues prop is not an array:', issues);
          return;
        }

        // Filter out any non-object items
        const validIssues = issues.filter(issue => issue && typeof issue === 'object');
        
        validIssues.forEach(issue => {
          if (!averages[issue.politician_id]) {
            averages[issue.politician_id] = {
              totalScore: 0,
              count: 0,
            };
          }
          if (issue.bill_count >= 1) {
            averages[issue.politician_id].totalScore += issue.average_score;
            averages[issue.politician_id].count += 1;
          }
        });

        Object.keys(averages).forEach(id => {
          if (averages[id].count > 0) {
            averages[id] = averages[id].totalScore / averages[id].count;
          } else {
            averages[id] = null;
          }
        });

        setAveragePositions(averages);
      };

      calculateAveragePositions();
    }
  }, [issues, isUserScore]);

  useEffect(() => {
    if (!isUserScore && issues.length > 0) {
      processIssueData(issues);
    }
  }, [issues, isUserScore]);

  const processIssueData = (issuesData) => {
    const politicianData = {};

    // Group data by politician
    issuesData.forEach(issue => {
      if (!politicianData[issue.politician_id]) {
        politicianData[issue.politician_id] = {};
      }
      
      // Store data by individual year
      if (issue.year) {
        const year = `${issue.year}`;
        if (!politicianData[issue.politician_id][year]) {
          politicianData[issue.politician_id][year] = {
            totalScore: 0,
            totalBillCount: 0,
            isValid: false
          };
        }
        
        const billCount = parseFloat(issue.bill_count) || 0;
        politicianData[issue.politician_id][year].totalBillCount += billCount;
        politicianData[issue.politician_id][year].totalScore += (issue.average_score || 0) * billCount;
        politicianData[issue.politician_id][year].isValid = politicianData[issue.politician_id][year].totalBillCount >= 1;
      }
    });

    // Calculate final averages
    Object.keys(politicianData).forEach(politicianId => {
      Object.keys(politicianData[politicianId]).forEach(year => {
        const yearData = politicianData[politicianId][year];
        yearData.averageScore = yearData.totalBillCount > 0 ? 
          yearData.totalScore / yearData.totalBillCount : 
          null;
        yearData.billCount = yearData.totalBillCount;
      });
    });

    setProcessedData(politicianData);
  };

  const findNearestValidScore = (politicianYears, targetYear) => {
    let leftYear = targetYear - 1;
    let rightYear = targetYear + 1;
    const maxYear = new Date().getFullYear();

    while (leftYear >= 2014 || rightYear <= maxYear) {
      if (leftYear >= 2014) {
        if (politicianYears[leftYear] && politicianYears[leftYear].isValid) {
          return politicianYears[leftYear].averageScore;
        }
        leftYear--;
      }
      if (rightYear <= maxYear) {
        if (politicianYears[rightYear] && politicianYears[rightYear].isValid) {
          return politicianYears[rightYear].averageScore;
        }
        rightYear++;
      }
    }
    return 50; // Default score if no valid scores found
  };

  // Add this function to calculate overall average for a politician
  const calculateOverallAverage = (politicianId) => {
    if (!processedData || !processedData[politicianId]) return null;
    
    const politician = processedData[politicianId];
    let totalScore = 0;
    let totalWeight = 0;

    // Calculate weighted average across ALL years (2014 to current)
    for (let year = 2014; year <= currentYear; year++) {
      const yearData = politician[year];
      if (yearData && yearData.isValid) {
        const weight = yearData.billCount || 1;
        totalScore += yearData.averageScore * weight;
        totalWeight += weight;
      }
    }

    return totalWeight > 0 ? totalScore / totalWeight : null;
  };

  // Update getCurrentIssues function
  const getCurrentIssues = useCallback((yearRange) => {
    if (isUserScore) return issues;
    if (!yearRange || !processedData) return [];
    
    return Object.keys(processedData).map(politicianId => {
      let totalScore = 0;
      let totalBillCount = 0;
      let totalWeight = 0;
      
      // First pass: calculate total bill count and weighted scores
      for (let year = yearRange[0]; year <= yearRange[1]; year++) {
        const yearData = processedData[politicianId][year];
        if (yearData) {
          // Remove log statement here
          
          // Add to total bill count regardless of validity
          const billCount = parseFloat(yearData.billCount) || 0;
          totalBillCount += billCount;
          
          // Only add to weighted average if valid
          if (yearData.isValid) {
            totalScore += yearData.averageScore * billCount;
            totalWeight += billCount;
          }
        }
      }

      const baseIssue = issues.find(issue => issue.politician_id === politicianId);
      if (!baseIssue) return null;

      // Determine status based on total bill count
      let status = 'active';
      if (totalBillCount === 0) {
        status = 'not_in_office';
      } else if (totalBillCount < 1) {
        status = 'insufficient_votes';
      }

      const rangeAverageScore = totalWeight > 0 ? totalScore / totalWeight : null;
      const overallAverage = status === 'not_in_office' ? calculateOverallAverage(politicianId) : null;

      return {
        ...baseIssue,
        average_score: status === 'not_in_office' ? overallAverage : rangeAverageScore,
        position: status === 'not_in_office' ? 
          (overallAverage || 50) : 
          (rangeAverageScore || 50),
        isGrayedOut: status !== 'active',
        isOutOfOffice: status === 'not_in_office',
        insufficientVotes: status === 'insufficient_votes',
        year: `${yearRange[0]}-${yearRange[1]}`,
        status,
        debug: { totalBillCount, totalWeight, rangeAverageScore, overallAverage }
      };
    }).filter(Boolean);
  }, [processedData, issues, isUserScore, currentYear]);

  useEffect(() => {
    if (!isUserScore) {
      // Generate all possible years from 2014 to current year
      const generateYears = () => {
        const years = [];
        for (let year = 2014; year <= currentYear; year++) {
          years.push(year);
        }
        return years;
      };

      const allYears = generateYears();
      
      if (issues.length > 0) {
        const availableYears = issues.map(issue => issue.year);
        const latestAvailableYear = Math.max(...availableYears);
        const earliestAvailableYear = Math.max(2014, Math.min(...availableYears));
        setYearRange([earliestAvailableYear, latestAvailableYear]);
      }
    }
  }, [issues, currentYear, isUserScore]);

  useEffect(() => {
    if (yearRange && activeSection === 'stats' && !isUserScore) {
      fetchGraphData(yearRange);
      fetchAverageScores(yearRange);
    }
  }, [yearRange, activeSection, isUserScore]);

  useEffect(() => {
    if (!isUserScore) {
      const currentIssues = getCurrentIssues(yearRange);
      setHasGrayedOutRep(currentIssues.length === 0);
    }
  }, [yearRange, isUserScore, getCurrentIssues]);

  const updateCallouts = useCallback((yearRange, currentIssues) => {
    const newCallouts = new Set();

    currentIssues.forEach(issue => {
      if (issue.display_name !== 'You') {
        if (issue.status === 'not_in_office') {
          newCallouts.add(`*${issue.display_name} was not in office during ${yearRange[0]}-${yearRange[1]}. Showing overall average.`);
        } else if (issue.status === 'insufficient_votes') {
          newCallouts.add(`*${issue.display_name} has not sponsored/voted on enough bills in this area during ${yearRange[0]}-${yearRange[1]}.`);
        }
      }
    });

    setCallouts(Array.from(newCallouts));
  }, []);

  useEffect(() => {
    if (yearRange && !isUserScore) {
      const currentIssues = getCurrentIssues(yearRange);
      updateCallouts(yearRange, currentIssues);
    }
  }, [yearRange, isUserScore, getCurrentIssues, updateCallouts]);

  const handleButtonClick = (section) => {
    if (activeSection === section) {
      setActiveSection(null);
      setExpanded(false);
    } else {
      setActiveSection(section);
      setExpanded(true);
      if (section === 'stats' && yearRange) {
        fetchGraphData(yearRange);
        fetchAverageScores(yearRange);
      } else if (section === 'subtopics') {
        fetchSubTopics();
      } else if (section === 'bills') {
        fetchImportantBills();
      }
    }
  };

  const handleYearRangeChange = (event, newValue) => {
    if (!isUserScore) {
      // Prevent same year selection
      if (newValue[0] === newValue[1]) {
        // If trying to set same year, adjust one of the values
        if (newValue[0] === currentYear) {
          newValue[0] = newValue[0] - 1;
        } else {
          newValue[1] = newValue[1] + 1;
        }
      }
      setYearRange(newValue);
      const currentIssues = getCurrentIssues(newValue);
      updateCallouts(newValue, currentIssues);
    }
  };

  const fetchGraphData = async (yearRange) => {
    try {
      // Create an array of all years in the range
      const years = [];
      for (let year = yearRange[0]; year <= yearRange[1]; year++) {
        years.push(year);
      }

      // Fetch data for all years in range
      const responses = await Promise.all(years.map(year => 
        axios.get(`${window.apiUrl}/userscoresummary?group_name=${head}&year=${year}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        })
      ));
      
      const data = responses.flatMap(response => response.data);
      // Remove these console.log statements
      // console.log('Full data from userscoresummary:', data);
      // console.log('User party data:', data.filter(entry => entry.party === 'User'));

      if (!Array.isArray(data) || data.length === 0) {
        setGraphData(null);
        return;
      }

      const buckets = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

      const calculatePercentages = (counts) => {
        const total = counts.reduce((acc, count) => acc + count, 0);
        return counts.map(count => (count / total) * 100);
      };

      // Remove this debug log
      // console.log('Available parties in data:', [...new Set(data.map(entry => entry.party))]);

      const getCounts = (party) => {
        return buckets.map(bucket => {
          const entries = data.filter(entry => {
            // Special handling for User party - ignore year filter
            if (party === 'User') {
              return entry.party === party && entry.bucket === bucket;
            }
            // For other parties, keep the year filter
            return entry.party === party && 
                   entry.bucket === bucket &&
                   entry.year >= yearRange[0] && 
                   entry.year <= yearRange[1];
          });
          
          // Remove this console.log
          // if (party === 'User') {
          //   console.log(`User entries for bucket ${bucket}:`, entries);
          // }
          
          return entries.reduce((sum, entry) => sum + (entry.count * (entry.bill_count || 1)), 0);
        });
      };

      const republicanCounts = getCounts('Republican');
      const democratCounts = getCounts('Democrat');
      const userCounts = isUserScore ? getCounts('User') : null;

      const republicanPercentages = calculatePercentages(republicanCounts);
      const democratPercentages = calculatePercentages(democratCounts);
      const userPercentages = userCounts ? calculatePercentages(userCounts) : null;

      const datasets = [
        {
          label: 'Republican Scores',
          data: republicanPercentages,
          fill: true,
          backgroundColor: 'rgba(240, 106, 106, 0.4)',
          borderColor: 'rgba(240, 106, 106, 1)',
          tension: 0.4,
          pointRadius: 0,
        },
        {
          label: 'Democrat Scores',
          data: democratPercentages,
          fill: true,
          backgroundColor: 'rgba(66, 133, 244, 0.4)',
          borderColor: 'rgba(66, 133, 244, 1)',
          tension: 0.4,
          pointRadius: 0,
        }
      ];

      // Add user dataset if this is the user score view
      if (isUserScore && userPercentages) {
        datasets.push({
          label: 'User Scores',
          data: userPercentages,
          fill: true,
          backgroundColor: 'rgba(158, 158, 158, 0.4)',  // Changed to gray
          borderColor: 'rgba(158, 158, 158, 1)',        // Changed to gray
          tension: 0.4,
          pointRadius: 0,
        });
      }

      setGraphData({
        labels: buckets.reverse(),
        datasets: datasets,
      });
    } catch (error) {
      console.error('Error fetching graph data:', error);
      setGraphData(null);
    }
  };

  const fetchAverageScores = async (yearRange) => {
    if (!yearRange || isUserScore) return;
    
    try {
      const years = [];
      for (let year = yearRange[0]; year <= yearRange[1]; year++) {
        years.push(year);
      }

      const responses = await Promise.all(years.map(year => 
        axios.get(`${window.apiUrl}/partyscoresummary?group_name=${head}&year=${year}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        })
      ));
      
      const data = responses.flatMap(response => response.data);

      const averages = {};
      ['Democrat', 'Republican'].forEach(party => {
        const partyData = data.filter(item => 
          item.party === party && 
          item.year >= yearRange[0] && 
          item.year <= yearRange[1]  // Include all years in range
        );
        
        if (partyData.length > 0) {
          // Calculate weighted average based on bill_count
          const totalWeightedScore = partyData.reduce((acc, item) => 
            acc + (parseFloat(item.average_score) || 0) * (item.bill_count || 1), 0
          );
          const totalWeight = partyData.reduce((acc, item) => 
            acc + (item.bill_count || 1), 0
          );
          averages[party] = (totalWeightedScore / totalWeight).toFixed(2);
        } else {
          averages[party] = 'N/A';
        }
      });

      setPartyAverages(averages);
    } catch (error) {
      console.error('Error fetching average scores:', error);
    }
  };

  const fetchSubTopics = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/grouptopics?group_name=${head}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      setSubTopics(response.data);
    } catch (error) {
    }
  };

  const fetchImportantBills = async () => {
    setIsLoading(true);
    try {
        const uniquePoliticianIds = [...new Set(
            issues
                .filter(issue => issue.politician_id !== 'user')
                .map(issue => issue.politician_id)
        )].join(',');

        if (!uniquePoliticianIds) {
            setImportantBills([]);
            return;
        }

        const response = await axios.get(
            `${window.apiUrl}/politicianbills`, {
                params: {
                    politician_ids: uniquePoliticianIds,
                    group_name: head
                },
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.data && Array.isArray(response.data)) {
            // Group bills by bill_id
            const groupedBills = response.data.reduce((acc, bill) => {
                if (!acc[bill.bill_id]) {
                    acc[bill.bill_id] = {
                        ...bill,
                        votes: [{
                            vote_id: bill.vote_id,
                            yea_aye_count: bill.yea_aye_count,
                            no_nay_count: bill.no_nay_count,
                            not_voting_count: bill.not_voting_count,
                            total_votes: bill.total_votes,
                            politician_votes: bill.politician_votes,
                            most_recent_date: bill.most_recent_date
                        }]
                    };
                } else {
                    acc[bill.bill_id].votes.push({
                        vote_id: bill.vote_id,
                        yea_aye_count: bill.yea_aye_count,
                        no_nay_count: bill.no_nay_count,
                        not_voting_count: bill.not_voting_count,
                        total_votes: bill.total_votes,
                        politician_votes: bill.politician_votes,
                        most_recent_date: bill.most_recent_date
                    });
                }
                return acc;
            }, {});

            setImportantBills(Object.values(groupedBills));
        } else {
            setImportantBills([]);
        }
    } catch (error) {
        setImportantBills([]);
    } finally {
        setIsLoading(false);
    }
  };

  const handleThumbMouseDown = (event, index) => {
    if (!isUserScore) return;

    setDraggingIndex(index);

    const thumb = event.target;
    const track = thumb.parentElement;
    const trackRect = track.getBoundingClientRect();
    const initialClientX = event.touches ? event.touches[0].clientX : event.clientX;
    const initialLeft = thumb.getBoundingClientRect().left - trackRect.left;

    let isFirstMove = true;
    let latestPosition = currentPosition[index] !== undefined ? currentPosition[index] : issues[index]?.position || 0;

    const onMove = (moveEvent) => {
      const clientX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;

      if (isFirstMove) {
        isFirstMove = false;
        return;
      }

      const clientDeltaX = clientX - initialClientX;
      const newLeft = initialLeft + clientDeltaX;
      const newPosition = Math.max(0, Math.min(trackRect.width, newLeft));
      latestPosition = (newPosition / trackRect.width) * 100;

      const ItemWidth = track.parentElement.getBoundingClientRect().width;
      const x0 = thumb.parentElement.parentElement.getBoundingClientRect().x;
      let currentPosition = clientX;
      latestPosition = 100 - (currentPosition - x0) / ItemWidth * 100;

      latestPosition = Math.max(0, latestPosition);
      latestPosition = Math.min(100, latestPosition);

      setCurrentPosition((prevState) => ({
        ...prevState,
        [index]: latestPosition,
      }));
    };

    const onEnd = () => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      document.removeEventListener('touchmove', onMove);
      document.removeEventListener('touchend', onEnd);
      setDraggingIndex(null);
      console.log('IssueBar: Calling onPositionChange with:', {
        topic: issues[index]?.topic_name,  // Make sure we're using topic_name here
        index,
        latestPosition
      });
      onPositionChange(issues[index]?.topic_name, index, latestPosition);
      if (onRefreshGallery) {
        onRefreshGallery();
      }
    };

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
    document.addEventListener('touchmove', onMove);
    document.addEventListener('touchend', onEnd);

    event.preventDefault();
  };

  const renderVerticalLine = (xPosition, color, label) => {
    if (xPosition === 'N/A' || isNaN(xPosition)) {
      return null;
    }
    const position = parseFloat(xPosition);
    return (
      <div
        key={`${label}-${position}`}
        className="vertical-line"
        style={{ left: `${100 - position}%`, borderColor: color }}
      >
        <div className="vertical-line-label" style={{ backgroundColor: color }}>
          {label}
        </div>
      </div>
    );
  };

  const sliderTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#4285f4',
            height: 8,
          },
          thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
          },
          valueLabel: {
            left: 'calc(-50% + 4px)',
          },
        },
      },
    },
  });

  const averageScore = useMemo(() => {
    const currentIssues = getCurrentIssues(yearRange);
    if (currentIssues.length === 0) return 'N/A';
    const sum = currentIssues.reduce((acc, issue) => acc + (issue.average_score || issue.score || 0), 0);
    return (sum / currentIssues.length).toFixed(2);
  }, [getCurrentIssues, yearRange]);

  const getBorderColor = (issueParty) => {
    const partyToUse = issueParty || party;
    switch (partyToUse) {
      case 'Republican':
        return 'rgba(123, 6, 30, 0.66)';
      case 'Democrat':
        return '#458BEA';
      case 'Independent':
        return '#808080';
      default:
        return '#000000';
    }
  };

  const getGlowColor = (party) => {
    switch (party) {
      case 'Republican':
        return 'rgba(240, 106, 106, 0.66)';
      case 'Democrat':
        return 'rgba(66, 133, 244, 0.66)';
      case 'Independent':
        return 'rgba(128, 128, 128, 0.5)';
      default:
        return 'rgba(0, 0, 0, 0.5)';
    }
  };

  const renderYearSlider = () => {
    if (isUserScore) return null;

    return (
      <div className="year-slider-wrapper">
        <Typography id="year-range-slider" className="year-label">
          Selected Years: {yearRange[0]}-{yearRange[1]}
        </Typography>
        <Box sx={{ width: '100%', padding: '0 20px' }}>
          <ThemeProvider theme={sliderTheme}>
            <Slider
              className="year-slider"
              value={yearRange}
              min={2014}
              max={currentYear}
              onChange={handleYearRangeChange}
              valueLabelDisplay="auto"
              aria-labelledby="year-range-slider"
            />
          </ThemeProvider>
        </Box>
      </div>
    );
  };

  // Keep the renderLegendLabel function
  const renderLegendLabel = () => {
    if (isUserScore) {
      return (
        <div className="legend-label">
          Average of <span className="republicans">republicans</span>, <span className="democrats">democrats</span>, and <span className="users">users</span>
        </div>
      );
    }
    return (
      <div className="legend-label">
        Average of <span className="republicans">republicans</span> and <span className="democrats">democrats</span>
      </div>
    );
  };

  return (
    <div className={`parent-container ${expanded ? 'expanded' : ''}`}>
      <div className="title-container">
        <h2 className="issue-title">{head}</h2>
        <div className="button-group">
          <button className={`minimalist-button stats ${activeSection === 'stats' ? 'active' : ''}`} onClick={() => handleButtonClick('stats')}>
            Stats
            <span className={`button-caret ${activeSection === 'stats' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button definition ${activeSection === 'definition' ? 'active' : ''}`} onClick={() => handleButtonClick('definition')}>
            Definition
            <span className={`button-caret ${activeSection === 'definition' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button bills ${activeSection === 'bills' ? 'active' : ''}`} onClick={() => handleButtonClick('bills')}>
            Recent Bills
            <span className={`button-caret ${activeSection === 'bills' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button subtopics ${activeSection === 'subtopics' ? 'active' : ''}`} onClick={() => handleButtonClick('subtopics')}>
            Sub-topics
            <span className={`button-caret ${activeSection === 'subtopics' ? 'expanded' : ''}`}></span>
          </button>
        </div>
      </div>
      <div className="issue-bar-container">
        <div className="issue-bar">
          <div className="issue-track">
            {getCurrentIssues(yearRange).map((item, index) => (
              <RepThumb
                key={index}
                position={currentPosition[index] !== undefined ? currentPosition[index] : (item.position || item.average_score || 0)}
                party={item.party || party}
                imageUrl={item.imageUrl}
                displayName={item.display_name}
                isGrayedOut={item.isGrayedOut}
                isOutOfOffice={item.isOutOfOffice}
                year={item.year}
                isUserScore={isUserScore}
                onMouseDown={(event) => isUserScore && handleThumbMouseDown(event, index)}
                onTouchStart={(event) => isUserScore && handleThumbMouseDown(event, index)}
                isDragging={isUserScore && draggingIndex === index}
                politicianId={item.politician_id}
                state={item.state}
                district={item.district}
              />
            ))}
            {activeSection === 'stats' && expanded && !isUserScore && (
              <>
                {renderVerticalLine(partyAverages['Democrat'], '#4285f4', 'D')}
                {renderVerticalLine(partyAverages['Republican'], '#f06a6a', 'R')}
              </>
            )}
          </div>
          {activeSection === 'stats' && expanded && (
            <div className="expanded-section">
              {graphData && (
                <>
                  <Line
                    data={graphData}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: { display: false, reverse: true },
                        y: { display: false, reverse: true },
                      },
                      plugins: { 
                        legend: { 
                          display: false  // Hide the graph boxes
                        } 
                      },
                      elements: { line: { tension: 0.4 } },
                    }}
                    style={{ width: '100%', height: '150px' }}
                    className="issue-graph"
                  />
                  {renderLegendLabel()}  {/* Keep the text legend */}
                </>
              )}
              {!isUserScore && renderYearSlider()}
              <div className="callouts">
                {callouts.map((callout, index) => (
                  <p key={index} className="callout">{callout}</p>
                ))}
              </div>
            </div>
          )}
          {activeSection === 'definition' && expanded && (
            <div className="expanded-section definition-section">
              <h3>Definition</h3>
              <p>{getCurrentIssues(yearRange)[0]?.description || 'No description available'}</p>
              <div className="definition-labels">
                <div className="definition-label">
                  <h4>{getCurrentIssues(yearRange)[0]?.p2 || ''}</h4>
                  <p>{getCurrentIssues(yearRange)[0]?.p2_def || ''}</p>
                </div>
                <div className="definition-label">
                  <h4>{getCurrentIssues(yearRange)[0]?.p1 || ''}</h4>
                  <p>{getCurrentIssues(yearRange)[0]?.p1_def || ''}</p>
                </div>
              </div>
            </div>
          )}
          {activeSection === 'subtopics' && expanded && (
            <div className="expanded-section subtopics-section">
              <h3>Sub-topics</h3>
              <div className="subtopics-bubbles">
                {subTopics.map((topic, index) => (
                  <div key={index} className="subtopic-bubble">
                    {topic.topic_name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeSection === 'bills' && expanded && (
            <div className="expanded-section bills-section">
              <h3>Recent Bills</h3>
              {isLoading ? (
                <div className="loading-spinner"></div>
              ) : importantBills.length > 0 ? (
                <div className="bills-list">
                  {importantBills.map((bill, index) => (
                    <BillCard 
                      key={`${bill.bill_id}-${index}`} 
                      bill={bill}
                      politicians={issues}
                    />
                  ))}
                </div>
              ) : (
                <p>No relevant bills found.</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="issue-labels">
        <span title={getCurrentIssues(yearRange)[0]?.p1_def || ''}>{getCurrentIssues(yearRange)[0]?.p1 || ''}</span>
        <span title={getCurrentIssues(yearRange)[0]?.p2_def || ''}>{getCurrentIssues(yearRange)[0]?.p2 || ''}</span>
      </div>
    </div>
  );
};

const getBorderColor = (party) => {
  switch (party) {
    case 'Republican':
      return 'rgba(123, 6, 30, 0.66)';
    case 'Democrat':
      return '#458BEA';
    case 'Independent':
      return '#808080';
    default:
      return '#000000';
  }
};

const getGlowColor = (party) => {
  switch (party) {
    case 'Republican':
      return 'rgba(240, 106, 106, 0.66)';
    case 'Democrat':
      return 'rgba(66, 133, 244, 0.66)';
    case 'Independent':
      return 'rgba(128, 128, 128, 0.5)';
    default:
      return 'rgba(0, 0, 0, 0.5)';
  }
};

