import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "./style.css";
import "../../styleguide.css";
import { Banner } from '../../components/Banner/Banner';
import { IssueGallery } from '../../components/IssueGallery/IssueGallery';
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import { RepGallery } from '../../components/RepGallery/RepGallery';
import { usePoliticianData } from '../../hooks/usePoliticianData';
import { createOrGetUser } from '../../utils/auth';

const genericUserIconUrl = 'https://via.placeholder.com/150?text=User';

export const PickYourViews = ({ email }) => {
  const [issues, setIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [scores, setScores] = useState([]);
  const [refreshGallery, setRefreshGallery] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (email) {
      fetchIssues(email);
      fetchScores(email);
    }
  }, [email]);

  const { politicians, error, loading, refetch } = usePoliticianData('similar-me', email, refreshGallery);

  const fetchIssues = async (email) => {
    if (!email) return;
    
    try {
      const response = await fetch(`${window.apiUrl}/userscores?email=${email}`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();

      const sortedIssues = data.sort((a, b) => a.rank - b.rank);
      setIssues(sortedIssues);

      const favoriteIssues = sortedIssues
        .filter(issue => issue.favorite && issue.favorite.data && issue.favorite.data[0] === 1)
        .map(issue => issue.group_name);

      setSelectedIssues(favoriteIssues);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setIsLoading(false);
    }
  };

  const fetchScores = async (email) => {
    if (!email) {
      return;
    }
    try {
      const userResponse = await fetch(`${window.apiUrl}/userscores?email=${email}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const userScores = await userResponse.json();

      const sortedScores = userScores.sort((a, b) => a.rank - b.rank);

      const userScoreData = {
        rep: 'You',
        politician_id: 'user',
        display_name: 'You',
        scores: sortedScores.map(score => ({
          topic_name: score.group_name,
          average_score: score.score,
          description: score.description,
          p1: score.p1,
          p2: score.p2,
          p1_def: score.p1_def,
          p2_def: score.p2_def,
          rank: score.rank
        })),
        image: genericUserIconUrl,
        party: 'Your Party'
      };

      setScores([userScoreData]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching scores:", error);
    }
  };

  const toggleIssue = async (issue) => {
    const updatedSelectedIssues = selectedIssues.includes(issue)
      ? selectedIssues.filter(item => item !== issue)
      : [...selectedIssues, issue];

    setSelectedIssues(updatedSelectedIssues);

    try {
      const response = await fetch(`${window.apiUrl}/userscores`, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          group_name: issue,
          favorite: updatedSelectedIssues.includes(issue) ? 1 : 0,
        }),
      });

      if (!response.ok) {
        console.error("Failed to update favorite status");
      } else {
        setRefreshGallery(prev => !prev);
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  const handlePositionChange = async (topic, index, newPosition) => {
    console.log('PickYourViews: handlePositionChange called with:', { topic, index, newPosition });
    
    const updatedScores = [...scores];
    const scoreIndex = updatedScores[0].scores.findIndex(score => score.topic_name === topic);
    console.log('Found score index:', scoreIndex, 'for topic:', topic);
    
    if (scoreIndex !== -1) {
      updatedScores[0].scores[scoreIndex].average_score = newPosition;
      setScores(updatedScores);

      try {
        const body = {
          email: email,
          group_name: topic,
          score: newPosition
        };
        console.log('Making API call with body:', body);

        const response = await fetch(`${window.apiUrl}/userscores`, {
          method: 'PUT',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body),
        });

        console.log('API response status:', response.status);
        const responseText = await response.text();
        console.log('API response:', responseText);

        if (!response.ok) {
          throw new Error(`Failed to update score: ${responseText}`);
        }

        setRefreshGallery(prev => !prev);
        refetch();
      } catch (error) {
        console.error("Error updating score:", error);
      }
    } else {
      console.error('Could not find score for topic:', topic);
    }
  };

  const handleRefreshGallery = () => {
    setRefreshGallery(prevState => !prevState);
  };

  const filteredScores = scores.map(scoreData => ({
    ...scoreData,
    scores: scoreData.scores.filter(score => selectedIssues.includes(score.topic_name))
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="pickyourviews">
        <Helmet>
          <title>Pick Your Political Views | VoteSee</title>
          <meta name="description" content="Select the political issues that matter most to you and see how your views align with representatives. Customize your political profile with VoteSee." />
          <meta name="keywords" content="political views, issue selection, representative alignment, VoteSee" />
        </Helmet>
        <Banner
          title="Pick Your Views"
          description="Get side by side comparison for the issues you care about"
        />

        <div className="pickyourviews-main">
          {isLoading ? (
            <p className="loading-message">Loading, please wait...</p>
          ) : (
            <>
              <p className="intro">Below, you'll find a list of political topics. Select the issues that matter most to you. Don't worry if you're unsure about some topics — you can always modify your selections later in your profile settings.
              Use the slider to show where you fall on the political spectrum of each issue.</p>
              <p className="care">I care about...</p>
              <div className="issuegallery">
                <IssueGallery 
                  issuesData={scores} 
                  onPositionChange={handlePositionChange} 
                  onRefreshGallery={handleRefreshGallery}
                  isUserScore={true}
                  isCompareView={true}
                  showFilter={true}
                  selectedIssues={selectedIssues}
                  toggleIssue={toggleIssue}
                  allIssues={issues}
                />
              </div>
              <div className="line"></div>

              <div className="similarreps">
                <RepGallery 
                  title="Similar Reps" 
                  description="See representatives that align with the views you've selected above." 
                  dataType="similar-me" 
                  item={email} 
                  refreshGallery={refreshGallery} 
                  politicians={politicians}
                  error={error}
                  loading={loading}
                />
              </div>
              <div className="line"></div>
            </>
          )}
        </div>
      </div>
    </DndProvider>
  );
};
