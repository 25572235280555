import React, { useState } from "react";
import { Button } from "../Button/Button";
import "./style.css";
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { ReactComponent as MailIcon } from './mail.svg';
import { ReactComponent as LockIcon } from './lock.svg';

export const Setup1 = ({ nextStep, setUserDetails }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const auth = getAuth();

    const handleNext = async () => {
        if (!email || !password) {
            setErrorMessage("Email and password are required.");
            return;
        }

        try {
            // Create user in Firebase
            await createUserWithEmailAndPassword(auth, email, password);
            
            // Create user in backend
            const response = await fetch(`${window.apiUrl}/users`, {
                method: 'POST',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Failed to create user in backend');
            }

            setUserDetails({ email, password });
            nextStep();
        } catch (error) {
            console.error("Error creating user:", error);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="base-signup1">
            <div className="left-section">
                <div className="box">
                    <h1 className="title">Base Setup</h1>
                    <p className="desc">Sign Up to Get Started</p>

                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <div className="ea">
                        <MailIcon className="email" />
                        <input
                            type="email"
                            className="input"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="pw">
                        <LockIcon className="lock" />
                        <input
                            type="password"
                            className="input"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="button-next-div">
                        <Button 
                            className="button-next" 
                            property1="primary" 
                            text="Next" 
                            onClick={handleNext} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
