import { useState, useEffect, useCallback } from 'react';
import { fetchWikipediaImage } from '../utils/utils';

export const usePoliticianData = (dataType, item, refreshTrigger) => {
    const [politicians, setPoliticians] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchPoliticians = useCallback(async () => {
        setLoading(true);
        setError(null);
        if (!item && dataType !== 'top') {
            setError('Please login or sign up to see this data.');
            setLoading(false);
            return;
        }

        const endpoints = {
            'local': `${window.apiUrl}/localpoliticians?email=${item}`,
            'similar-me': `${window.apiUrl}/similarpoliticians?email=${item}`,
            'similar-rep': `${window.apiUrl}/similarpoliticians?politician_id=${item}`,
            'top': `${window.apiUrl}/toppoliticians`,
            'custom': `${window.apiUrl}/custompoliticians?politician_ids=${item}`
        };

        const endpoint = endpoints[dataType] || endpoints.top;

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.length === 0) {
                throw new Error("No politicians found.");
            }

            const politicianIds = data.map(p => p.politician_id);

            const queryParams = new URLSearchParams();
            politicianIds.forEach(id => queryParams.append('politician_id', id));

            const [detailedData, topicsData] = await Promise.all([
                fetch(`${window.apiUrl}/politicians?${queryParams.toString()}`, {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY_1 }
                }).then(res => res.json()),
                fetch(`${window.apiUrl}/topissues?${queryParams.toString()}`, {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY_1 }
                }).then(res => res.json())
            ]);

            const detailedPoliticians = await Promise.all(
                data.map(async (politician) => {
                    const details = detailedData.find(d => d.politician_id === politician.politician_id) || {};
                    const image = await fetchWikipediaImage(details.id_wikipedia) || await fetchWikipediaImage(details.display_name);
                    const topics = topicsData
                        .filter(topic => topic.politician_id === politician.politician_id)
                        .map(topic => topic.topic_name);

                    return {
                        politician_id: politician.politician_id,
                        hits: politician.hits,
                        name: details.name || 'Unknown',
                        display_name: politician.display_name || 'Unknown',
                        title: details.party || 'Unknown',
                        type: details.type || 'Unknown',
                        state: details.state || 'Unknown',
                        maskGroup: image,
                        nextElection: calculatePreviousYear(details.end),
                        topics: topics,
                        description: details.description || 'Description not available'
                    };
                })
            );

            setPoliticians(detailedPoliticians);
        } catch (error) {
            setError(`Failed to fetch politicians. ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [dataType, item]);

    useEffect(() => {
        let isMounted = true;
        fetchPoliticians().then(() => {
            if (isMounted) setLoading(false);
        });
        return () => {
            isMounted = false;
        };
    }, [fetchPoliticians, refreshTrigger]);

    return { politicians, error, loading, refetch: fetchPoliticians };
};

function calculatePreviousYear(endDate) {
    if (!endDate) return 'Unknown';
    return new Date(endDate).getFullYear() - 1;
}
