// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global styles */
/* Example CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB,sBAAsB;AACtB;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;;AAExB;;;AAGA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;;EAEZ,sBAAsB;;IAEpB,kCAAkC;IAClC,mCAAmC;IACnC,kCAAkC;;AAEtC","sourcesContent":["/* Global styles */\n/* Example CSS Reset */\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  \n}\n\n\nhtml, body {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  height: 100%;\n\n  box-sizing: border-box;\n\n    text-rendering: optimizeLegibility;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
