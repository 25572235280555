import React, { useState, useRef, useEffect } from "react";
import shape from "./shape.svg"; // Correctly import the SVG
import "./style.css";
import { useHistory } from "react-router-dom";

const apiUrl =  'https://votesee.org/api';

export const Search = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const history = useHistory();
    const searchContainerRef = useRef(null);

    const toggleSearch = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = async (e) => {
        const value = e.target.value;
        setQuery(value);
        setSelectedIndex(-1);
        if (value.length > 2) {
            try {
                const response = await fetch(`${window.apiUrl}/politiciansearch?search=${value}`, {
                    method: 'GET',
                    headers: {
                      'x-api-key': process.env.REACT_APP_API_KEY_1, // Include the API key in the request headers
                      'Content-Type': 'application/json'
                    }
                  });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error("Error fetching suggestions:", error);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.name);
        setSuggestions([]);
        setIsOpen(false);
        history.push("/find-a-rep", { rep: suggestion });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown' && suggestions.length > 0) {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        } else if (e.key === 'ArrowUp' && suggestions.length > 0) {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length);
        } else if (e.key === 'Enter' && selectedIndex >= 0) {
            handleSuggestionClick(suggestions[selectedIndex]);
        }
    };

    const handleClickOutside = (e) => {
        if (searchContainerRef.current && !searchContainerRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="search-container" ref={searchContainerRef}>
            <img className="shape" alt="Shape" src={shape} onClick={toggleSearch} />
            <div className={`search-dropdown ${isOpen ? 'open' : ''}`}>
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search..."
                    value={query}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    autoFocus={isOpen}
                />
                {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion)}
                                className={index === selectedIndex ? 'selected' : ''}
                            >
                                {suggestion.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
