import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Button = ({ property1, text = "Get Started", onClick }) => {
    return (
        <button className={`button ${property1}`} onClick={onClick}>
            <span className="text-wrapper">{text}</span>
        </button>
    );
};

Button.propTypes = {
    property1: PropTypes.oneOf(["primary", "secondary"]),
    text: PropTypes.string,
    onClick: PropTypes.func,
};
