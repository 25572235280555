// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.representative-effectiveness {
  max-width: 1250px;
  width: 100%;
  margin: 0 0;
  /* ... (rest of the styles) */
}

.representative-effectiveness h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.filters select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  flex: 1 1;
  min-width: 150px;
  max-width: 200px;
}

.top-ten-chart,
.histogram {
  max-width: 1250px;
  margin: 0 auto;
  /* ... (rest of the styles) */
}

@media (max-width: 768px) {
  .representative-effectiveness h2 {
    font-size: 20px;
  }

  .filters {
    flex-direction: column;
  }

  .filters select {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .representative-effectiveness {
    padding: 10px;
  }

  .top-ten-chart,
  .histogram {
    padding: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/RepresentativeEffectiveness/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,SAAO;EACP,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;;EAEE,iBAAiB;EACjB,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;;IAEE,aAAa;EACf;AACF","sourcesContent":[".representative-effectiveness {\n  max-width: 1250px;\n  width: 100%;\n  margin: 0 0;\n  /* ... (rest of the styles) */\n}\n\n.representative-effectiveness h2 {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 20px;\n}\n\n.filters {\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 20px;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n\n.filters select {\n  padding: 8px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  margin-bottom: 10px;\n  flex: 1;\n  min-width: 150px;\n  max-width: 200px;\n}\n\n.top-ten-chart,\n.histogram {\n  max-width: 1250px;\n  margin: 0 auto;\n  /* ... (rest of the styles) */\n}\n\n@media (max-width: 768px) {\n  .representative-effectiveness h2 {\n    font-size: 20px;\n  }\n\n  .filters {\n    flex-direction: column;\n  }\n\n  .filters select {\n    width: 100%;\n    margin-right: 0;\n  }\n}\n\n@media (max-width: 480px) {\n  .representative-effectiveness {\n    padding: 10px;\n  }\n\n  .top-ten-chart,\n  .histogram {\n    padding: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
