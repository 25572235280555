import React from "react";
import { RepGallery } from "../RepGallery/RepGallery";
import "./style.css";

export const TopReps = () => {
    return (
        <div className="top-reps-wrapper">
            <div className="top-reps-background"></div>
            <div className="top-reps-container">
                <div className="section-title">
                    <div className="heading">Most Searched Reps</div>
                    <p>
                       See which Congressional Representatives are searched most often by the VoteSee community.
                    </p>
                </div>
                <RepGallery 
                    className="gallery"
                    dataType="top"
                    showHeader={false} // Hide the header in RepGallery
                    title="Most Searched Reps" // This won't be displayed due to showHeader={false}
                    description="See which Congressional Representatives are searched most often by the VoteSee community." // This won't be displayed due to showHeader={false}
                />
            </div>
        </div>
    );
};
