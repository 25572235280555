import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import "./style.css";
import Image1 from "./8846-1.webp";
import Image2 from "./image-43.webp";
import Image3 from "./Rectangle-6.webp";
import Image5 from "./Rectangle-5.webp";
import Image4 from "./Rectangle-7.webp";
import { previewImages } from "./previews/preview-images";
import { Link } from "react-router-dom";

export const Header = () => {
    const [showGetStarted, setShowGetStarted] = useState(true);
    const [activePreview, setActivePreview] = useState('findRep'); // Start with findRep flipped
    const [cardColors, setCardColors] = useState({});
    const [isHovering, setIsHovering] = useState(false);

    const cardIds = ['findRep', 'compareReps', 'quiz', 'pickViews'];

    useEffect(() => {
        // Randomly assign initial colors to each card
        setCardColors({
            findRep: Math.random() < 0.5 ? 'blue' : 'red',
            compareReps: Math.random() < 0.5 ? 'blue' : 'red',
            quiz: Math.random() < 0.5 ? 'blue' : 'red',
            pickViews: Math.random() < 0.5 ? 'blue' : 'red'
        });
    }, []);

    useEffect(() => {
        const userEmail = localStorage.getItem('userEmail');
        const isTempAccount = userEmail && userEmail.includes('@votesee.temp.org');
        setShowGetStarted(!userEmail || isTempAccount);
    }, []);

    // Auto-flip animation effect
    useEffect(() => {
        if (isHovering) return; // Don't auto-flip if user is hovering

        const interval = setInterval(() => {
            setActivePreview(current => {
                // Get all card IDs except the current one and 'findRep' (which stays flipped)
                const availableCards = cardIds.filter(id => id !== current && id !== 'findRep');
                // Pick a random card from the available ones
                const randomIndex = Math.floor(Math.random() * availableCards.length);
                return availableCards[randomIndex];
            });
        }, 3000);

        return () => clearInterval(interval);
    }, [isHovering]);

    const handleMouseEnter = (cardId) => {
        setIsHovering(true);
        setActivePreview(cardId);
    };

    const handleMouseLeave = (cardId) => {
        setIsHovering(false);
        if (cardId !== 'findRep') {
            setActivePreview('findRep');
        }
    };

    const previewContent = {
        findRep: {
            title: "Find Your Representative",
            description: "Search for your congressional representatives and see how they vote on issues that matter to you.",
            preview: previewImages.findRep
        },
        compareReps: {
            title: "Compare Representatives",
            description: "Compare voting records and stances between different representatives side by side.",
            preview: previewImages.compare
        },
        quiz: {
            title: "Take the Quiz",
            description: "Test your knowledge about Congress and learn more about the political process.",
            preview: previewImages.quiz
        },
        pickViews: {
            title: "Pick Your Views",
            description: "Tell us what matters to you and we'll show how your representatives align with your values.",
            preview: previewImages.pickViews
        }
    };

    return (
        <div className="header-container">
            <div className="header-left-section">
                <div className="box">
                    <h1>Informed <span className="highlight">Voters</span> Drive Change.</h1>
                    <p className="text">At VoteSee, we believe that informed voters are the key to driving meaningful change in our democracy. Our platform helps you discover how your Congressional Representatives have voted on the issues that matter most to you.</p>
                    <img src={Image1} className="image" alt="Informed Voters" />
                    
                    {showGetStarted && (
                        <div className="button-container">
                            <Link to="/signup">
                                <Button className="button-instance" property1="primary" text="Get Started" />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <div className="mobile-image">
                <img src={Image2} className="image-content" alt="Block 1" />
            </div>
 
            <div className="right-section">
                <Link 
                    to="/find-a-rep" 
                    className={`image-block image-block1 ${cardColors.findRep} ${activePreview === 'findRep' ? 'flipped' : ''}`}
                    onMouseEnter={() => handleMouseEnter('findRep')}
                    onMouseLeave={() => handleMouseLeave('findRep')}
                >
                    <div className="card-inner">
                        <div className="card-front">
                            <img src={Image2} className="image-content" alt="Find a Representative" />
                        </div>
                        <div className="card-back">
                            <div className="preview-content">
                                <img src={previewContent.findRep.preview} className="preview-image" alt="Find a Rep Preview" />
                                <div className="preview-text">
                                    <h2>{previewContent.findRep.title}</h2>
                                    <p>{previewContent.findRep.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link 
                    to="/compare-reps" 
                    className={`image-block image-block2 ${cardColors.compareReps} ${activePreview === 'compareReps' ? 'flipped' : ''}`}
                    onMouseEnter={() => handleMouseEnter('compareReps')}
                    onMouseLeave={() => handleMouseLeave('compareReps')}
                >
                    <div className="card-inner">
                        <div className="card-front">
                            <img src={Image5} className="image-content" alt="Compare Representatives" />
                        </div>
                        <div className="card-back">
                            <div className="preview-content">
                                <img src={previewContent.compareReps.preview} className="preview-image" alt="Compare Reps Preview" />
                                <div className="preview-text">
                                    <h2>{previewContent.compareReps.title}</h2>
                                    <p>{previewContent.compareReps.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link 
                    to="/quiz" 
                    className={`image-block image-block3 ${cardColors.quiz} ${activePreview === 'quiz' ? 'flipped' : ''}`}
                    onMouseEnter={() => handleMouseEnter('quiz')}
                    onMouseLeave={() => handleMouseLeave('quiz')}
                >
                    <div className="card-inner">
                        <div className="card-front">
                            <img src={Image3} className="image-content" alt="Take the Quiz" />
                        </div>
                        <div className="card-back">
                            <div className="preview-content">
                                <img src={previewContent.quiz.preview} className="preview-image" alt="Quiz Preview" />
                                <div className="preview-text">
                                    <h2>{previewContent.quiz.title}</h2>
                                    <p>{previewContent.quiz.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link 
                    to="/pick-your-views" 
                    className={`image-block image-block4 ${cardColors.pickViews} ${activePreview === 'pickViews' ? 'flipped' : ''}`}
                    onMouseEnter={() => handleMouseEnter('pickViews')}
                    onMouseLeave={() => handleMouseLeave('pickViews')}
                >
                    <div className="card-inner">
                        <div className="card-front">
                            <img src={Image4} className="image-content" alt="Pick Your Views" />
                        </div>
                        <div className="card-back">
                            <div className="preview-content">
                                <img src={previewContent.pickViews.preview} className="preview-image" alt="Pick Views Preview" />
                                <div className="preview-text">
                                    <h2>{previewContent.pickViews.title}</h2>
                                    <p>{previewContent.pickViews.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};
