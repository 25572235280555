import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as d3 from 'd3';
import { useHistory } from 'react-router-dom';
import { fetchWikipediaImage, isSafari, isFirefox } from '../../utils/utils';
import './style.css';
import '../../styles/commonChartStyles.css';

export const ChangeOverTimeChart = ({ initialData, fetchChangeOverTimeData, topics }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const currentYear = new Date().getFullYear();
  const [startYearGroup, setStartYearGroup] = useState('2013-2014');
  const [endYearGroup, setEndYearGroup] = useState(`${currentYear - 1}-${currentYear}`);
  const [minBillCount, setMinBillCount] = useState(3); // Changed default to 3
  const history = useHistory();
  const chartRef = useRef(null);
  const [error, setError] = useState(null);
  const [additionalReps, setAdditionalReps] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedParty, setSelectedParty] = useState('All');
  const [selectedType, setSelectedType] = useState('All');
  const [parties, setParties] = useState(['All']);
  const [types, setTypes] = useState(['All']);

  // Generate year group options
  const yearGroups = [];
  for (let year = currentYear; year >= 2001; year -= 2) {
    yearGroups.push(`${year - 1}-${year}`);
  }

  useEffect(() => {

    if (initialData && initialData.length > 0) {
      console.time('processAndSetChartData');
      processAndSetChartData(initialData);
      console.timeEnd('processAndSetChartData');
      updateFilters(initialData);
    }
    // Set the default topic to the first one in the list (excluding 'All')
    if (topics.length > 0) {
      const filteredTopics = topics.filter(topic => topic !== 'All');
      setSelectedTopic(filteredTopics[0] || '');
    }
  }, [initialData, topics]);

  useEffect(() => {
    if (selectedTopic) {
      fetchChartData();
    }
  }, [selectedTopic, startYearGroup, endYearGroup, selectedParty, selectedType]); // Removed minBillCount from dependencies

  const updateFilters = (data) => {
    const uniqueParties = ['All', ...new Set(data.map(item => item.party).filter(Boolean))];
    const uniqueTypes = ['All', ...new Set(data.map(item => item.type).filter(Boolean))];
    setParties(uniqueParties);
    setTypes(uniqueTypes);
  };

  const processAndSetChartData = async (data, isAdditional = false) => {

    const processedData = await Promise.all(data.map(async item => {

      let imageUrl;
      if (item.id_wikipedia) {

        imageUrl = await fetchWikipediaImage(item.id_wikipedia);

      } else {

      }
      return {
        ...item,
        start_score: parseFloat(item.start_score),
        end_score: parseFloat(item.end_score),
        difference_score: parseFloat(item.difference_score),
        imageUrl: imageUrl,
        party: item.party || 'Unknown',
        display_name: item.id_wikipedia || item.politician_id
      };
    }));
    console.timeEnd('processData');
    
    
    if (isAdditional) {
      setAdditionalReps(prev => [...prev, ...processedData]);
    } else {
      setChartData(processedData.slice(0, 20)); // Show up to 20 reps
      setAdditionalReps([]); // Clear additional reps when new data is fetched
    }
    
    return processedData;
  };

  useEffect(() => {
    if (chartData.length > 0 || additionalReps.length > 0) {
      
      console.time('drawCharts');
      drawCharts();
      console.timeEnd('drawCharts');
    }
  }, [chartData, additionalReps]);

  const fetchChartData = useCallback(async () => {
    const [startYear1, startYear2] = startYearGroup.split('-');
    const [endYear1, endYear2] = endYearGroup.split('-');

    const params = {
      topic_name: selectedTopic,
      start_year1: startYear1,
      start_year2: startYear2,
      end_year1: endYear1,
      end_year2: endYear2,
      start_bill_count: minBillCount.toString(), // Always use the fixed minBillCount
      end_bill_count: minBillCount.toString(),
    };

    if (selectedParty !== 'All') {
      params.party = selectedParty;
    }

    if (selectedType !== 'All') {
      params.type = selectedType;
    }

    try {
      
      console.time('fetchChangeOverTimeData');
      const data = await fetchChangeOverTimeData(params);
      console.timeEnd('fetchChangeOverTimeData');
      
      if (data.length === 0) {
        setError('No data available for this filter selection. Please select different options.');
        setChartData([]);
        setAdditionalReps([]);
      } else {
        setError(null);
        console.time('processAndSetChartData');
        await processAndSetChartData(data);
        console.timeEnd('processAndSetChartData');
        updateFilters(data);
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setError(`Failed to fetch chart data. Error: ${error.message}`);
      setChartData([]);
      setAdditionalReps([]);
    }
  }, [selectedTopic, startYearGroup, endYearGroup, selectedParty, selectedType, minBillCount]);

  const handleStartYearGroupChange = (newStartYearGroup) => {
    setStartYearGroup(newStartYearGroup);
    // Ensure end year group is always greater than start year group
    const [, startYear] = newStartYearGroup.split('-').map(Number);
    const [endYear1, endYear2] = endYearGroup.split('-').map(Number);
    if (startYear >= endYear1) {
      const newEndYear1 = startYear + 1;
      const newEndYear2 = startYear + 2;
      setEndYearGroup(`${newEndYear1}-${newEndYear2}`);
    }
  };

  const handleEndYearGroupChange = (newEndYearGroup) => {
    const [startYear1, startYear2] = startYearGroup.split('-').map(Number);
    const [endYear1, ] = newEndYearGroup.split('-').map(Number);
    if (endYear1 > startYear2) {
      setEndYearGroup(newEndYearGroup);
    } else {
      // If invalid selection, set end year group to next valid option
      const newEndYear1 = startYear2 + 1;
      const newEndYear2 = startYear2 + 2;
      setEndYearGroup(`${newEndYear1}-${newEndYear2}`);
    }
  };

  const drawCharts = () => {
    
    if (chartData.length === 0 && additionalReps.length === 0) {
      console.warn('No data to draw charts');
      return;
    }

    const allData = [...chartData, ...additionalReps];

    const margin = { top: 20, right: 35, bottom: 40, left: 10 }; // Increased bottom margin
    const containerWidth = chartRef.current.clientWidth;
    const totalWidth = containerWidth - margin.left - margin.right;
    const isMobile = window.innerWidth <= 768;
    const leftChartWidth = isMobile ? totalWidth : totalWidth * 0.84;
    const rightChartWidth = isMobile ? 0 : totalWidth * 0.13;
    const chartGap = isMobile ? 0 : totalWidth * 0.03 + 20;
    const rowHeight = 50;
    const totalHeight = Math.max(allData.length, 20) * rowHeight; // Ensure minimum height for 20 rows

    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", containerWidth)
      .attr("height", totalHeight + margin.top + margin.bottom);

    const chartGroup = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define scales
    const x1 = d3.scaleLinear().domain([100, 0]).range([0, leftChartWidth]);
    const y = d3.scaleBand()
      .range([0, totalHeight])
      .domain(allData.map(d => d.display_name || d.politician_id))
      .padding(0.3);

    // Add lighter horizontal gridlines in the background
    const gridlines = chartGroup.append("g")
      .attr("class", "gridlines");

    y.domain().forEach(d => {
      gridlines.append("line")
        .attr("x1", 0)
        .attr("x2", leftChartWidth + chartGap + rightChartWidth)
        .attr("y1", y(d) + y.bandwidth() / 2)
        .attr("y2", y(d) + y.bandwidth() / 2)
        .attr("stroke", "#e8e8e8")
        .attr("stroke-width", 1);
    });

    // Left Chart
    // Add gradient background
    const gradient = chartGroup.append("defs")
      .append("linearGradient")
      .attr("id", "score-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#4285f4")
      .attr("stop-opacity", 0.1);

    gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#f06a6a")
      .attr("stop-opacity", 0.1);

    chartGroup.append("rect")
      .attr("width", leftChartWidth)
      .attr("height", totalHeight)
      .style("fill", "url(#score-gradient)");

    // Add vertical lines
    const verticalLines = [50, 60, 70, 80, 90, 100];
    verticalLines.forEach(score => {
      chartGroup.append("line")
        .attr("x1", x1(score))
        .attr("x2", x1(score))
        .attr("y1", 0)
        .attr("y2", totalHeight)
        .attr("stroke", score === 50 ? "black" : "#ddd")
        .attr("stroke-width", score === 50 ? 2 : 1)
        .attr("stroke-dasharray", score === 50 ? "none" : "4");
    });

    chartGroup.append("g")
      .attr("transform", `translate(0,${totalHeight})`)
      .call(d3.axisBottom(x1));

    // Lines
    chartGroup.selectAll("myline")
      .data(allData)
      .enter()
      .append("line")
        .attr("x1", d => x1(d.start_score))
        .attr("x2", d => x1(d.end_score))
        .attr("y1", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
        .attr("y2", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
        .attr("stroke", "grey")
        .attr("stroke-width", "2px");

    // Function to create rep image
    const createRepImage = (selection, score, isStart, otherScore) => {
      const imageSize = 36;
      const imageBorderWidth = 3;
      const labelWidth = 100; // Adjust this value based on your needs

      const imageContainer = selection
        .append("foreignObject")
        .attr("class", "image-container")
        .attr("width", imageSize + 20)
        .attr("height", imageSize + 20)
        .attr("x", d => x1(score(d)) - (imageSize + 20) / 2)
        .attr("y", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2 - (imageSize + 20) / 2);

      const imageGroup = imageContainer
        .append("xhtml:div")
        .attr("class", "image-group")
        .style("width", "100%")
        .style("height", "100%")
        .style("display", "flex")
        .style("align-items", "center")
        .style("justify-content", "center");

      const imageBorder = imageGroup.append("div")
        .attr("class", "image-border")
        .style("width", `${imageSize}px`)
        .style("height", `${imageSize}px`)
        .style("border-radius", "50%")
        .style("border", d => `${imageBorderWidth}px solid ${getBorderColor(d.party)}`)
        .style("overflow", "hidden")
        .style("flex-shrink", "0")
        .style("background-color", d => {
          const color = getBorderColor(d.party);
          if (isStart && (isSafari() || isFirefox())) {
            return addOpacityToColor(color, 0.5); // 50% opacity for Safari and Firefox on start year
          } else {
            return "transparent";
          }
        });

      imageBorder.append("img")
        .attr("src", d => d.imageUrl || "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7") // Transparent 1x1 pixel GIF
        .style("width", "100%")
        .style("height", "100%")
        .style("object-fit", "cover")
        .style("opacity", d => {
          if (!d.imageUrl) return 0;
          if (isStart) {
            if (isSafari() || isFirefox()) {
              return 0; // Invisible for Safari and Firefox on start year
            } else {
              return 0.5; // 50% opacity for Chrome on start year
            }
          }
          return 1; // Full opacity for end year or non-start year
        })
        .on("click", (event, d) => handlePoliticianClick(d.politician_id));

      // Add label only for the image with higher score
      if (score(selection.datum()) >= otherScore(selection.datum())) {
        selection.append("text")
          .attr("class", "image-label")
          .attr("x", d => x1(score(d)) - (imageSize + 20) / 2 - 10) // Position to the left of the image
          .attr("y", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
          .attr("text-anchor", "end")
          .attr("dominant-baseline", "middle")
          .text(d => d.display_name || d.politician_id);
      }
    };

    // Helper function to add opacity to a color
    const addOpacityToColor = (color, opacity) => {
      // Check if the color is in hex format
      if (color.startsWith('#')) {
        // Convert hex to RGB
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }
      // If it's already in RGB format, just add the alpha
      if (color.startsWith('rgb')) {
        return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
      }
      // If it's a named color, return it with opacity
      return `${color}${Math.round(opacity * 255).toString(16).padStart(2, '0')}`;
    };

    // Create images and labels
    allData.forEach((d, i) => {
      const startScore = d.start_score;
      const endScore = d.end_score;

      // Create start score image
      createRepImage(
        chartGroup.append("g").datum(d),
        () => startScore,
        true,
        () => endScore
      );

      // Create end score image
      createRepImage(
        chartGroup.append("g").datum(d),
        () => endScore,
        false,
        () => startScore
      );

      // Add arrow in the middle of the line
      const arrowSize = 6;
      const midX = (x1(startScore) + x1(endScore)) / 2;
      const midY = y(d.display_name || d.politician_id) + y.bandwidth() / 2;

      chartGroup.append("path")
        .attr("d", d3.symbol().type(d3.symbolTriangle).size(arrowSize * arrowSize))
        .attr("transform", `translate(${midX}, ${midY}) rotate(${startScore <= endScore ? 270 : 90})`)
        .attr("fill", "grey");
    });

    // Right Chart (only for non-mobile view)
    if (!isMobile) {
      const maxDifference = d3.max(allData, d => Math.abs(d.difference_score));
      const x2 = d3.scaleLinear().domain([maxDifference, -maxDifference]).range([0, rightChartWidth]);

      const colorScale = d3.scaleLinear()
        .domain([-maxDifference, 0, maxDifference])
        .range(["#f06a6a", "#FFFFFF", "#4285f4"]);

      const secondChartG = chartGroup.append("g")
        .attr("transform", `translate(${leftChartWidth + chartGap}, 0)`);

      // Reduce the number of ticks and remove decimals
      const tickValues = [-maxDifference, -maxDifference/2, 0, maxDifference/2, maxDifference];
      secondChartG.append("g")
        .attr("transform", `translate(0,${totalHeight})`)
        .call(d3.axisBottom(x2).tickValues(tickValues).tickFormat(d3.format("d"))); // Changed to "d" format

      // Center line
      const centerX = x2(0);
      secondChartG.append("line")
        .attr("x1", centerX)
        .attr("x2", centerX)
        .attr("y1", 0)
        .attr("y2", totalHeight)
        .attr("stroke", "black")
        .attr("stroke-width", "2px");

      // Bars for difference score
      secondChartG.selectAll(".diff-bar")
        .data(allData)
        .enter()
        .append("rect")
          .attr("class", "diff-bar")
          .attr("x", d => d.difference_score > 0 ? x2(d.difference_score) : x2(0))
          .attr("y", d => y(d.display_name || d.politician_id))
          .attr("width", d => Math.abs(x2(d.difference_score) - x2(0)))
          .attr("height", y.bandwidth())
          .attr("fill", d => colorScale(d.difference_score));

      // Difference score labels
      secondChartG.selectAll(".diff-label")
        .data(allData)
        .enter()
        .append("text")
          .attr("class", "diff-label")
          .attr("x", d => d.difference_score >= 0 ? x2(d.difference_score) - 5 : x2(d.difference_score) + 5)
          .attr("y", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
          .attr("text-anchor", d => d.difference_score >= 0 ? "end" : "start")
          .attr("dominant-baseline", "middle")
          .text(d => d.difference_score >= 0 ? `+${Math.round(d.difference_score)}` : `${Math.round(d.difference_score)}`) // Rounded the difference score
          .style("font-size", "10px")
          .style("fill", "black");

      // Add title for the second chart
      secondChartG.append("text")
        .attr("x", rightChartWidth / 2)
        .attr("y", -10)
        .attr("text-anchor", "middle")
        .style("font-size", "14px")
        .style("font-weight", "bold")
        .text("(Relative)");
    }



    // Add titles to the charts
    chartGroup.append("text")
      .attr("x", leftChartWidth / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text("Rep voting behavior shift over time (Absolute)");

    // Add labels below the left chart
    chartGroup.append("text")
      .attr("x", 0)
      .attr("y", totalHeight + 30) // Adjusted y-position
      .attr("text-anchor", "start")
      .style("font-size", "12px")
      .text("More Liberal");

    chartGroup.append("text")
      .attr("x", leftChartWidth)
      .attr("y", totalHeight + 30) // Adjusted y-position
      .attr("text-anchor", "end")
      .style("font-size", "12px")
      .text("More Conservative");

    
  };

  const handlePoliticianClick = (politicianId) => {
    history.push(`/rep/${politicianId}`);
  };

  const getBorderColor = (party) => {
    switch (party) {
      case 'Republican':
        return 'rgb(123, 6, 30)';
      case 'Democrat':
        return 'rgb(69, 139, 234)';
      case 'Independent':
        return 'rgb(128, 128, 128)';
      default:
        return 'rgb(0, 0, 0)';
    }
  };

  const handleSearch = useCallback(async () => {
    if (searchTerm.length < 3) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(`${window.apiUrl}/politiciansearch?search=${searchTerm}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching for politicians:', error);
      setError('Failed to search for politicians. Please try again.');
    }
  }, [searchTerm]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, handleSearch]);

  const isRepSelectable = (politician) => {
    
    const politicianFullStart = new Date(politician.full_start).getFullYear();
    const politicianFullEnd = new Date(politician.full_end).getFullYear();
    const isSelectable = politicianFullEnd >= endYearGroup.split('-')[1] && politicianFullStart <= startYearGroup.split('-')[0];
    
    
    return isSelectable;
  };

  const addRepresentative = async (politician) => {
    try {
      const params = {
        topic_name: selectedTopic,
        start_year1: startYearGroup.split('-')[0],
        start_year2: startYearGroup.split('-')[1],
        end_year1: endYearGroup.split('-')[0],
        end_year2: endYearGroup.split('-')[1],
        start_bill_count: minBillCount.toString(),
        end_bill_count: minBillCount.toString(),
        politician_id: politician.politician_id
      };
      const data = await fetchChangeOverTimeData(params);
      if (data.length > 0) {
        await processAndSetChartData(data, true);
      }
    } catch (error) {
      console.error('Error adding representative:', error);
      setError('Failed to add representative. Please try again.');
    }
  };

  return (
    <div className="change-over-time-chart">
      <div className="chart-description">
        <div className="text">Analyze how politicians' voting patterns have shifted on various topics over different congressional terms.</div>
      </div>
      <div className="chart-controls">
        <div className="control-group">
          <label htmlFor="topic-select">Topic:</label>
          <select id="topic-select" value={selectedTopic} onChange={(e) => setSelectedTopic(e.target.value)}>
            {topics.filter(topic => topic !== 'All').map((topic) => (
              <option key={topic} value={topic}>{topic}</option>
            ))}
          </select>
        </div>
        <div className="control-group">
          <label htmlFor="start-year-group">Start Year Group:</label>
          <select
            id="start-year-group"
            value={startYearGroup}
            onChange={(e) => handleStartYearGroupChange(e.target.value)}
          >
            {yearGroups.map((group) => (
              <option key={group} value={group}>{group}</option>
            ))}
          </select>
        </div>
        <div className="control-group">
          <label htmlFor="end-year-group">End Year Group:</label>
          <select
            id="end-year-group"
            value={endYearGroup}
            onChange={(e) => handleEndYearGroupChange(e.target.value)}
          >
            {yearGroups.map((group) => (
              <option key={group} value={group} disabled={group <= startYearGroup}>{group}</option>
            ))}
          </select>
        </div>
        <div className="control-group">
          <label htmlFor="party-select">Party:</label>
          <select id="party-select" value={selectedParty} onChange={(e) => setSelectedParty(e.target.value)}>
            {parties.map((party) => (
              <option key={party} value={party}>{party}</option>
            ))}
          </select>
        </div>
        <div className="control-group">
          <label htmlFor="type-select">Type:</label>
          <select id="type-select" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
            {types.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      {(chartData.length > 0 || additionalReps.length > 0) ? (
        <div className="chart-container" ref={chartRef}></div>
      ) : (
        <div className="no-data-message">No data available. Please adjust your filter criteria.</div>
      )}
    </div>
  );
};