import React from 'react';
import { useHistory } from 'react-router-dom';
import './RepThumb.css';
import defaultFlag from './flag.webp';

const RepThumb = ({ 
  position, 
  party, 
  imageUrl, 
  displayName, 
  isGrayedOut, 
  isOutOfOffice, 
  year,
  isUserScore,
  onMouseDown,
  onTouchStart,
  isDragging,
  politicianId,
  state,
  district
}) => {
  const history = useHistory();

  const getBorderColor = (party) => {
    switch (party) {
      case 'Republican':
        return 'rgba(123, 6, 30, 0.66)';
      case 'Democrat':
        return '#458BEA';
      case 'Independent':
        return '#808080';
      default:
        return '#000000';
    }
  };

  const getGlowColor = (party) => {
    switch (party) {
      case 'Republican':
        return 'rgba(240, 106, 106, 0.66)';
      case 'Democrat':
        return 'rgba(66, 133, 244, 0.66)';
      case 'Independent':
        return 'rgba(128, 128, 128, 0.5)';
      default:
        return 'rgba(0, 0, 0, 0.5)';
    }
  };

  const handleClick = (event) => {
    if (!isUserScore && politicianId) {
      event.preventDefault();
      event.stopPropagation();
      history.push(`/rep/${politicianId}`);
    }
  };

  return (
    <div
      className={`rep-thumb ${isUserScore && isDragging ? 'dragging' : ''} ${isGrayedOut ? 'grayed-out' : ''} ${isOutOfOffice ? 'out-of-office' : ''}`}
      style={{
        left: `${100 - position}%`,
        borderColor: getBorderColor(party),
        '--glow-color': getGlowColor(party),
        opacity: isGrayedOut || isOutOfOffice ? 0.5 : 1,
        transition: displayName === 'You' ? 'none' : 'left 0.3s ease-in-out',
        cursor: isUserScore ? 'grab' : 'pointer'
      }}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onClick={handleClick}
    >
      <img 
        src={isUserScore ? defaultFlag : (imageUrl || defaultFlag)} 
        alt={`${displayName || 'User'} Score Thumb`} 
      />
      <div className="tooltip">
        <strong>{displayName || 'User'}</strong>
        <p>Party: {party}</p>
        <p>{state} {district ? `District ${district}` : ''}</p>
        <p>Score: {typeof position === 'number' ? position.toFixed(2) : 'N/A'}</p>
        {isGrayedOut && <p>Insufficient data</p>}
        {isOutOfOffice && <p>Out of office, showing {year}</p>}
      </div>
    </div>
  );
};

export default RepThumb;
