import React from "react";
import "./style.css";
import Frame from "./Frame.svg"; // Import the SVG file

export const MobileHeader = ({ toggleMenu }) => {
    return (
        <div className="mobile-header">
            <img className="close-icon" alt="Close" src={Frame} onClick={toggleMenu} />
        </div>
    );
};
