// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
    display: flex;
    max-height: 982px;
    width: 100%;
    max-width: 1512px;
}

.signup-content {
    flex: 1 1;

    justify-content: center;
    align-items: center;

}

@media (max-width: 768px) {
    .signup-container {
        flex-direction: column;
    }

    .signup-content {
        flex: none;
        width: 100%;
    }

    .signup-progress {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Signup/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,SAAO;;IAEP,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,UAAU;QACV,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".signup-container {\n    display: flex;\n    max-height: 982px;\n    width: 100%;\n    max-width: 1512px;\n}\n\n.signup-content {\n    flex: 1;\n\n    justify-content: center;\n    align-items: center;\n\n}\n\n@media (max-width: 768px) {\n    .signup-container {\n        flex-direction: column;\n    }\n\n    .signup-content {\n        flex: none;\n        width: 100%;\n    }\n\n    .signup-progress {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
