// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HomePage.css */
.quiz-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.quiz-page-main {
  position: relative;
  padding-bottom: 50px;
  max-width: 1512px;
  width: 100%;
}

.quiz-page-main .title-wrap {
  padding: 45px 131px;
}

.quiz-page-main .title {
  color: #000;
  font-family: Georgia;
  font-size: 50.4px;
  font-weight: 700;
  line-height: 1.2;
}

.quiz-page-main .text {
  color: #000;
  font-family: Inter;
  font-size: 18.9px;
  font-weight: 400;
  line-height: 1.5;
}

.quizzes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 0px 131px;
  gap: 20px;
}

@media (max-width: 1200px) {
  .quizzes-container {
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }

  .quiz-page-main .title-wrap {
    padding: 45px 20px;
  }

  .quiz-page-main .title {
    font-size: 36px;
  }

  .quiz-page-main .text {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Quiz/style.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,WAAW;EACX,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["/* HomePage.css */\n.quiz-page-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.quiz-page-main {\n  position: relative;\n  padding-bottom: 50px;\n  max-width: 1512px;\n  width: 100%;\n}\n\n.quiz-page-main .title-wrap {\n  padding: 45px 131px;\n}\n\n.quiz-page-main .title {\n  color: #000;\n  font-family: Georgia;\n  font-size: 50.4px;\n  font-weight: 700;\n  line-height: 1.2;\n}\n\n.quiz-page-main .text {\n  color: #000;\n  font-family: Inter;\n  font-size: 18.9px;\n  font-weight: 400;\n  line-height: 1.5;\n}\n\n.quizzes-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  width: 100%;\n  padding: 0px 131px;\n  gap: 20px;\n}\n\n@media (max-width: 1200px) {\n  .quizzes-container {\n    flex-direction: column;\n    align-items: center;\n    padding: 0px 20px;\n  }\n\n  .quiz-page-main .title-wrap {\n    padding: 45px 20px;\n  }\n\n  .quiz-page-main .title {\n    font-size: 36px;\n  }\n\n  .quiz-page-main .text {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
