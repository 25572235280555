import React from 'react';
import './style.css';

const QuizCard = ({ quiz, onClick }) => {
  const getDesignClass = (designNumber) => {
    switch (designNumber) {
      case 1:
        return 'quiz-card design-1';
      case 2:
        return 'quiz-card design-2';
      case 3:
        return 'quiz-card design-3';
      default:
        return 'quiz-card design-1';
    }
  };

  return (
    <div className={getDesignClass(quiz.QuizDesign)} onClick={onClick}>
      <h2>{quiz.QuizName}</h2>
      <p>{quiz.QuizDescription}</p>
      <button className="quiz-button" onClick={onClick}>
        Take The Quiz
      </button>
    </div>
  );
};

export default QuizCard;
