import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Banner } from '../../components/Banner/Banner';
import { ChangeOverTimeChart } from '../../components/ChangeOverTimeChart/ChangeOverTimeChart';
import { RepresentativeEffectiveness } from '../../components/RepresentativeEffectiveness/RepresentativeEffectiveness';
import { PartyChangeOverTime } from '../../components/PartyChangeOverTime/PartyChangeOverTime';
import { MajorityAlignment } from '../../components/MajorityAlignment/MajorityAlignment';
import { ChartSelector } from '../../components/ChartSelector/ChartSelector';
import { useDynamicMetaTags } from '../../hooks/useDynamicMetaTags';
import { CongressBillSplit } from '../../components/CongressBillSplit/CongressBillSplit';
import './style.css';

export const Analytics = () => {
  const { chartType = 'congress-bill-splits' } = useParams(); // Changed to plural
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  const [changeOverTimeData, setChangeOverTimeData] = useState([]);

  const title = `${chartType === 'majority-alignment' ? 'Majority Alignment' : 'Political Analytics'} | VoteSee`;
  const description = chartType === 'majority-alignment' 
    ? "Explore how closely members of Congress align with their party's majority voting patterns." 
    : "Explore in-depth political analytics, including voting trends, representative effectiveness, and party changes over time.";
  const image = 'https://www.votesee.org/og-image.png'; // Use the same URL as in index.html
  const url = 'https://www.votesee.org/analytics/congress-bill-splits'; // Changed to plural

  // Add a console log to verify the image URL
  console.log('OG Image URL:', image);

  const metaTags = useDynamicMetaTags(title, description, image, url);

  useEffect(() => {
    fetchTopics();
  }, []);

  useEffect(() => {
    if (topics.length > 0) {
      fetchInitialChangeOverTimeData(topics[0]);
    }
  }, [topics]);

  const fetchTopics = async () => {
    try {
      const response = await fetch(`${window.apiUrl}/groupdefinitions`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch topics');
      const data = await response.json();
      const filteredTopics = data.map(group => group.group_name).filter(topic => topic !== 'All');
      setTopics(filteredTopics);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchInitialChangeOverTimeData = async (firstTopic) => {
    const currentYear = new Date().getFullYear();
    try {
      const params = new URLSearchParams({
        topic_name: firstTopic,
        start_year1: '2013',
        start_year2: '2014',
        end_year1: (currentYear - 1).toString(),
        end_year2: currentYear.toString(),
        start_bill_count: '15',
        end_bill_count: '15'
      });
      const response = await fetch(`${window.apiUrl}/changeovertime?${params}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch initial change over time data');
      const data = await response.json();
      setChangeOverTimeData(data);
    } catch (error) {
      console.error('Error fetching initial change over time data:', error);
    }
  };

  const fetchChangeOverTimeData = async (params) => {
    try {
      const response = await fetch(`${window.apiUrl}/changeovertime?${new URLSearchParams(params)}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching change over time data:', error);
      throw error;
    }
  };

  const renderSelectedChart = () => {
    switch (chartType) {
      case 'change-over-time':
        return <ChangeOverTimeChart 
          initialData={changeOverTimeData} 
          fetchChangeOverTimeData={fetchChangeOverTimeData}
          topics={topics}
        />;
      case 'representative-effectiveness':
        return <RepresentativeEffectiveness />;
      case 'majority-alignment':
        return <MajorityAlignment />;
      case 'party-change-over-time':
        return <PartyChangeOverTime />;
      case 'congress-bill-splits': // Changed to plural
        return <CongressBillSplit />;
      default:
        return <ChangeOverTimeChart 
          initialData={changeOverTimeData} 
          fetchChangeOverTimeData={fetchChangeOverTimeData}
          topics={topics}
        />;
    }
  };

  const handleChartChange = (newChartType) => {
    history.push(`/analytics/${newChartType}`);
  };

  return (
    <div className="analytics-page-wrapper">
      <Banner title="Analytics" />
      <div className="analytics-page-main">
        <div className="title-wrap">
          <div className="title">Political Analytics</div>
          <div className="text">Explore various political analytics and insights.</div>
        </div>
        <div className="chart-selector-container">
          <ChartSelector selectedChart={chartType} setSelectedChart={handleChartChange} />
        </div>
        <div className="graphs-container" style={{ width: '100%', maxWidth: 'none', padding: 0 }}>
          {renderSelectedChart()}
        </div>
      </div>
    </div>
  );
};
