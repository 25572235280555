import React from "react";
import "./style.css";
import Image from "./info.svg";

export const HowItWorks = () => {
  return (
    
    <div className="how-it-works-wrapper">
      <div className="how-it-works-background"></div>
      <div className="how-it-works">
        <div className="section-title">
          <div className="content">
            <div className="frame">
              <div className="heading">How it Works</div>
              <div className="rectangle" />
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          <div className="row">
            <div className="column">
              <div className="text-wrapper">
                <img src={Image} className="image-content" />
              </div>
              <div className="div">
                <div className="heading-2">Find Your Representatives</div>
                <p className="p">
                  Search for Representatives by name or location to get started.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="text-wrapper">
                <img src={Image} className="image-content" />
              </div>
              <div className="div">
                <div className="heading-3">Select Your Issues</div>
                <p className="p">
                  Choose the political issues that matter most to you.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="text-wrapper">
                <img src={Image} className="image-content" />
              </div>
              <div className="div">
                <div className="heading-3">Compare Stances</div>
                <p className="p">
                 Use our tool to compare the voting history of various Representatives and see how their positions align with your own.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
