import axios from 'axios';
import '../globals'; // Import globals to ensure apiUrl is available

const dbLogEvent = async (event, detail = null) => {
    try {
        const email = localStorage.getItem('userEmail');
        const datetime = new Date().toISOString();



        const response = await axios.post(`${window.apiUrl}/log`, {
            email,
            event,
            detail,
            datetime
        }, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY_1,
                'Content-Type': 'application/json'
            }
        });


    } catch (error) {
        console.error('Error logging event to database:', error);
        if (error.response) {

        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
    }
};

const localLogEvent = (event, detail = null) => {
    if (process.env.REACT_APP_NODE_ENV === 'local') {
        const email = localStorage.getItem('userEmail');
        const datetime = new Date().toISOString();
        console.log('Local Log Event:', { email, event, detail, datetime });
    }
};
export { dbLogEvent, localLogEvent };
