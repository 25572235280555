import React, { useState } from 'react';
import './style.css';
import { Setup1 } from '../../components/Setup1/Setup1';
import { Setup2 } from '../../components/Setup2/Setup2';
import { Setup3 } from '../../components/Setup3/Setup3';
import { SignupProgress } from '../../components/SignupProgress/SignupProgress';
import { useHistory } from 'react-router-dom';
import { getAuth, updateEmail } from "firebase/auth";

export const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userDetails, setUserDetails] = useState({});
  const history = useHistory();

  const nextStep = async () => {
    if (currentStep === 3) {
      // Update Firebase auth email
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        try {
          await updateEmail(user, userDetails.email);
          // Update localStorage
          localStorage.setItem('userEmail', userDetails.email);
          // Redirect to pick-your-views page
          window.location.href = '/find-a-rep';
        } catch (error) {
          console.error("Error updating email:", error);
          // Handle error (e.g., show error message to user)
        }
      } else {
        console.error("No authenticated user found");
        // Handle error (e.g., show error message to user)
      }
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const updateUserDetails = (details) => {
    setUserDetails(prevDetails => ({ ...prevDetails, ...details }));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Setup1 nextStep={nextStep} setUserDetails={updateUserDetails} />;
      case 2:
        return <Setup2 nextStep={nextStep} setUserDetails={updateUserDetails} userDetails={userDetails} />;
      case 3:
        return <Setup3 nextStep={nextStep} userDetails={userDetails} />;
      default:
        return <Setup1 nextStep={nextStep} setUserDetails={updateUserDetails} />;
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-content">
        {renderStep()}
      </div>
      <SignupProgress currentStep={currentStep} />
    </div>
  );
};
