// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.shape {
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.search-dropdown {
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    display: flex;
    align-items: center;
    background: transparent; /* Clear background */
}

.search-dropdown.open {
    width: 200px;
    opacity: 1;
}

.search-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: block;
    background: transparent; /* Clear background */
}

.suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
}

.suggestions-list li {
    padding: 8px;
    cursor: pointer;
}

.suggestions-list li:hover, .suggestions-list li.selected {
    background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/components/test/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,oCAAoC;AACxC;;AAEA;IACI,QAAQ;IACR,gBAAgB;IAChB,4DAA4D;IAC5D,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAE,qBAAqB;AAClD;;AAEA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,cAAc;IACd,uBAAuB,EAAE,qBAAqB;AAClD;;AAEA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".search-container {\n    position: relative;\n    display: flex;\n    align-items: center;\n}\n\n.shape {\n    cursor: pointer;\n    transition: opacity 0.3s ease-in-out;\n}\n\n.search-dropdown {\n    width: 0;\n    overflow: hidden;\n    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;\n    opacity: 0;\n    display: flex;\n    align-items: center;\n    background: transparent; /* Clear background */\n}\n\n.search-dropdown.open {\n    width: 200px;\n    opacity: 1;\n}\n\n.search-input {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    box-sizing: border-box;\n    display: block;\n    background: transparent; /* Clear background */\n}\n\n.suggestions-list {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    max-height: 200px;\n    overflow-y: auto;\n}\n\n.suggestions-list li {\n    padding: 8px;\n    cursor: pointer;\n}\n\n.suggestions-list li:hover, .suggestions-list li.selected {\n    background-color: #f0f0f0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
