import React from 'react';
import { Search } from '../../components/test/test'; // Adjust the import path as necessary

export const Dev = () => {
    return (
        <div>
            <Search />
        </div>
    );
};

export default Dev;